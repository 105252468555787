import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { LaConFileUpload } from 'links/LaConFetch';
import React from 'react';
import swal from 'sweetalert';

export default function DialogChangeAvatar({ open, onClose, link, ...props }) {
    const [file, setFile] = React.useState(null)
    const [sending, setSending] = React.useState(false)

    const submitFile = () => {
        setSending(true)
        let formFile = [{
            name: 'anexo',
            file: file,
        }]
        let formStuff = [];
        LaConFileUpload(link, formFile, formStuff, (r) => {
            if (r.success) {
                swal('Foto adiconada com sucesso!', '', 'success')
                onClose(true)
            } else {
                swal('Erro ao adicionar foto!', '', 'error')
            }
            setSending(false)
        }, () => {
            setSending(false)
        })
    }

    return <>
        <Backdrop open={sending} style={{ zIndex: 9999 }}>
            <Box color="white">
                <CircularProgress />
            </Box>
        </Backdrop>
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>
                Adicionar foto
            </DialogTitle>
            <DialogContent>
                <TextField
                    type='file' fullWidth margin='normal' variant='outlined' label="Arquivo" inputProps={{ accept: 'image/*' }}
                    onChange={(e) => { setFile(e.target.files[0]) }} InputLabelProps={{ shrink: true }}
                />
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button variant="contained" color="secondary" onClick={() => onClose()}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" onClick={submitFile}>
                        Enviar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </>
}