import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop, Modal, Box, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';
import YesNoRadio from 'components/FormInputs/YesNoRadio';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function CreasViolencia({ familia_id, filter }) {

    const classes = useStyles();

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // CreasViolencia
    const [id, setId] = useState("");
    const [cd_situacao, setCd_situacao] = useState("");
    const [nm_situacao, setNm_situacao] = useState("");
    const [st_situacao, setSt_situacao] = useState("");
    const [dt_registro, setDt_registro] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");

    const [nm_situacao_outro, setNm_situacao_outro] = useState("");
    const [outroSituacaoOff, setOutroSituacaoOff] = useState(true);
    const [loading, setLoading] = useState(false);

    /* Set CreasViolencia */
    const setCreasViolencia = (codigo) => {

        setCd_situacao(codigo);

        if (codigo === '1') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Violência Física');
        }
        else if (codigo === '2') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Violência Psicológica');
        }
        else if (codigo === '3') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Exploração Sexual');
        }
        else if (codigo === '4') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Abuso/Violência Sexual');
        }
        else if (codigo === '5') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Negligência ou Abandono');
        }
        else if (codigo === '6') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Trabalho Infantil');
        }
        else if (codigo === '7') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Trajetória de Rua');
        }
        else if (codigo === '8') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Tráfico de Pessoas');
        }
        else if (codigo === '9') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Discriminação por orientação sexual');
        }
        else if (codigo === '10') {
            setNm_situacao_outro(null);
            setOutroSituacaoOff(true);
            setNm_situacao('Violência Patrimonial contra Idoso ou PCD');
        }
        else if (codigo === '99') {
            setOutroSituacaoOff(false);
        }

    }

    const setCreasViolenciaOutro = (texto) => {

        setNm_situacao_outro(texto);
        setNm_situacao(texto);
    }

    const [sending, setSending] = useState(false);

    /* Cadastrar CreasViolencia */
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (cd_situacao === "")
            swal("Código da situação é obrigatório!", "", "error")

        else if (dt_registro === "")
            swal("Data de registro é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                cd_situacao,
                nm_situacao,
                st_situacao,
                dt_registro,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarCreasViolencia`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e CreasViolencia */
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);

        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

        LaConFetch(`/api/resultCreasViolencia/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosCreasViolencia */
    const setDadosCreasViolencia = (dados) => {

        /* Dados */
        setId(dados.id);
        setCreasViolencia(dados.cd_situacao);
        if (dados.cd_situacao === '99') {
            setCreasViolenciaOutro(dados.nm_situacao);
        }
        setSt_situacao(dados.st_situacao);
        setDt_registro(dados.dt_registro);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setCd_situacao("");
        setNm_situacao("")
        setSt_situacao("");
        setDt_registro("");
        setDs_observacao("");
        setNm_situacao_outro("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosCreasViolencia(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar CreasViolencia */
    const editar = () => {

        const formDados = {
            cd_situacao,
            nm_situacao,
            st_situacao,
            dt_registro,
            ds_observacao
        };

        setSending(true);

        LaConFetch(`/api/editarCreasViolencia/${id}`,
            result => {
                load();
                setSending(false);
                swal("Enviado com sucesso!", "", "success")
                setOpen(false)
            }, () => { }, formDados)

    };

    /* Remover CreasViolencia */
    const remover = (dados) => {

        const id = dados.id;

        setSending(true);

        LaConFetch(`/api/removerCreasViolencia/${id}`,
            result => {
                setSending(false)
            }, () => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>QUADRO 3 - EXCLUSIVO PARA UTILIZAÇÃO PELO CREAS -
                Registro obrigatório de situações de violência e violações de direitos identificadas no
                grupo familiar</Typography>
            <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                Adicionar CREAS Violência
            </Button>

        </Box>

        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Cód. Situação', field: 'cd_situacao', headerStyle: { fontWeight: 'bold' } },
                { title: 'Nome Situação', field: 'nm_situacao', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data Registro', field: 'dt_registroTab', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            options={{
                actionsColumnIndex: -1
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_materialTableLocalization} icons={_materialTableIcons}

            title="CREAS Violência"
        />

        <Dialog
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="dialog-title">CREAS Violência</DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            disabled={editing}
                            id="pessoa_id"
                            value={pessoa_id}
                            label="Nome da pessoa*"
                            onChange={(e) => setPessoa_id(e.target.value)}
                            select
                        >
                            {dadosPessoa.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nm_pessoa}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            id="cd_situacao"
                            fullWidth
                            value={cd_situacao}
                            label="Código da Situação*"
                            onChange={(e) => setCreasViolencia(e.target.value)}
                            select
                        >
                            <MenuItem value="1">1 - Violência Física</MenuItem>
                            <MenuItem value="2">2 - Violência Psicológica</MenuItem>
                            <MenuItem value="3">3 - Exploração Sexual</MenuItem>
                            <MenuItem value="4">4 - Abuso/Violência Sexual</MenuItem>
                            <MenuItem value="5">5 - Negligência ou Abandono</MenuItem>
                            <MenuItem value="6">6 - Trabalho Infantil</MenuItem>
                            <MenuItem value="7">7 - Trajetória de rua</MenuItem>
                            <MenuItem value="8">8 - Tráfico de pessoas</MenuItem>
                            <MenuItem value="9">9 - Discriminação por orientação sexual</MenuItem>
                            <MenuItem value="10">10 - Violência Patrimonial contra Idoso ou PCD</MenuItem>
                            <MenuItem value="99">99 - Outro</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            disabled={outroSituacaoOff}
                            margin="normal"
                            fullWidth
                            value={nm_situacao_outro}
                            id="nm_situacao_outro"
                            label="Caso outro, descreva a situação"
                            variant="outlined"
                            onChange={(e) => setNm_situacao_outro(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                    <Grid item xs={12}>

                        <YesNoRadio
                            label="Indício ou Confirmada?"
                            value={st_situacao}
                            onChange={(value) => setSt_situacao(value)}
                            name="st_situacao"
                            formProps={{ fullWidth: true }}
                        />

                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="dt_registro"
                            label="Data de Registro*"
                            type="date"
                            value={dt_registro}
                            onChange={(e) => setDt_registro(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="ds_observacao"
                            label="Observação"
                            variant="outlined"
                            value={ds_observacao}
                            onChange={(e) => setDs_observacao(e.target.value)}
                            multiline
                            rows={4}
                        />

                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>

                <Button onClick={(editing === false) ? enviar : editar} variant="contained" color="primary">
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>


    </div>
}