import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import Deficiencia from './Deficiencia';
import Cuidados from './Cuidados';
import Gestacao from './Gestacao';
import DescumprimentoSaude from './DescumprimentoSaude';

export default function SaudeGroup({ familia_id, filter, ...props }) {
    const [tab, setTab] = React.useState(0);

    const onTabChange = (event, newValue) => {
        setTab(newValue);
    }

    return <div>
        <Tabs
            value={tab}
            indicatorColor="secondary"
            textColor="primary"
            onChange={onTabChange}
            variant='fullWidth'
        >
            <Tab
                label="Deficiências"
                style={{
                    backgroundColor: tab === 0 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 0 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={0}
            />
            <Tab
                label="Cuidados"
                style={{
                    backgroundColor: tab === 1 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 1 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={1}
            />
            <Tab
                label="Gestação"
                style={{
                    backgroundColor: tab === 2 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 2 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={2}
            />
            <Tab
                label="Descumprimentos de Saúde"
                style={{
                    backgroundColor: tab === 3 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 3 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={3}
            />
        </Tabs>
        <div role="tabpanel" hidden={tab !== 0}>
            <Deficiencia familia_id={familia_id} filter={filter} />
        </div>
        <div role="tabpanel" hidden={tab !== 1}>
            <Cuidados familia_id={familia_id} filter={filter} />
        </div>
        <div role="tabpanel" hidden={tab !== 2}>
            <Gestacao familia_id={familia_id} filter={filter} />
        </div>
        <div role="tabpanel" hidden={tab !== 3}>
            <DescumprimentoSaude familia_id={familia_id} filter={filter} />
        </div>
    </div>
}