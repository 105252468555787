import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import Violencia from './Violencia';
import CreasAcompanhamento from './CreasAcompanhamento';
import CreasViolencia from './CreasViolencia';

export default function ViolenciaGroup({ familia_id, filter, ...props }) {
    const [tab, setTab] = React.useState(0);

    const onTabChange = (event, newValue) => {
        setTab(newValue);
    }

    return <div>
        <Tabs
            value={tab}
            indicatorColor="secondary"
            textColor="primary"
            onChange={onTabChange}
            variant='fullWidth'
        >
            <Tab
                label="Violência"
                style={{
                    backgroundColor: tab === 0 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 0 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={0}
            />
            <Tab
                label="Acompanhamento Creas"
                style={{
                    backgroundColor: tab === 1 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 1 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={1}
            />
            <Tab
                label="Violência Creas"
                style={{
                    backgroundColor: tab === 2 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 2 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={2}
            />
        </Tabs>
        <div role="tabpanel" hidden={tab !== 0}>
            <Violencia familia_id={familia_id} filter={filter} />
        </div>
        <div role="tabpanel" hidden={tab !== 1}>
            <CreasAcompanhamento familia_id={familia_id} filter={filter} />
        </div>
        <div role="tabpanel" hidden={tab !== 2}>
            <CreasViolencia familia_id={familia_id} filter={filter} />
        </div>
    </div>
}