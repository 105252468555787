import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { _MaterialTableLocalization } from 'links/TableConstant'
import { Button, CircularProgress, Backdrop, Box, Dialog, DialogContent, DialogActions, DialogTitle, Grid, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LaConFetch } from 'links/LaConFetch';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="R$ "
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function Encaminhamento({ familia_id, filter }) {
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [encaminhados, setEncaminha] = useState([]);
    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);
    const [open, setOpen] = React.useState(false);
    const [tipos, setTipos] = useState([])
    const [registro, setRegistro] = useState({
        id: '',
        pessoa_id: '',
        destino_id: '',
        assunto: '',
        feito_em: '',
        tipo_id: '',
        descricao: '',
    });
    const [destinos, setDestinos] = useState([])
    const classes = useStyles();

    /* Cadastrar CondTrabalho*/
    const enviar = () => {
        setSending(true);
        let url = (registro.id) ? `edit/${registro.id}` : `new`
        LaConFetch(`/api/encaminhamento/${url}`,
            result => {
                setSending(false);
                swal("Enviado com sucesso!", "", "success")
                loadEncaminhamentos()
                setOpen(false)
            }, {
        }, registro)
    };

    const loadEncaminhamentos = () => {
        setLoading(true)
        LaConFetch(`/api/encaminhamento/index/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.items !== null) {
                    setEncaminha(result.items)
                }
                setLoading(false)
            }, () => setLoading(false))
    }

    const loadAux = () => {
        setSending(true)
        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setSending(false)
            }, () => setSending(false))
        LaConFetch(`/api/encaminhamento/tipo`,
            result => {
                if (result.items !== null) {
                    setTipos(result.items)
                }
                setSending(false)
            }, () => setSending(false))
        LaConFetch(`/api/creas`,
            result => {
                if (result.items !== null) {
                    setDestinos(result.items)
                }
                setSending(false)
            }, () => setSending(false))
    }

    useEffect(() => {
        loadEncaminhamentos()
    }, [filter])
    useEffect(() => {
        loadAux()
    }, [])

    const handleRegistro = (field, value) => {
        let hold = { ...registro }
        hold[field] = value
        setRegistro(hold)
    }

    const handleOpen = () => {
        setRegistro({
            id: '',
            pessoa_id: '',
            destino_id: '',
            assunto: '',
            feito_em: '',
            tipo_id: '',
            descricao: '',

        })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <div className={classes.root}>

        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>

        <Box style={{ backgroundColor: 'rgb(37, 85, 39)' }} p={2} display="flex" justifyContent="space-between">
            <h4 style={{ margin: '3px 0', color: 'white' }}>Encaminhamentos</h4>
            <Button onClick={handleOpen} variant="contained" color="primary">
                Novo Encaminhamento
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Destinatário', field: 'destino.nome', headerStyle: { fontWeight: 'bold' } },
                { title: 'Assunto', field: 'assunto', headerStyle: { fontWeight: 'bold' } },
                { title: 'Beneficiário', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data da Ação', field: 'feito_em', render: rowData => moment(rowData.feito_em, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm'), headerStyle: { fontWeight: 'bold' } },
                { title: 'Situação', field: 'status', headerStyle: { fontWeight: 'bold' } },
            ]}
            data={encaminhados}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => {
                        setRegistro({
                            id: data.id,
                            pessoa_id: data.pessoa_id,
                            destino_id: data.destino_id,
                            assunto: data.assunto,
                            feito_em: data.feito_em,
                            tipo_id: data.tipo_id,
                            descricao: data.descricao,
                            status: data.status,
                        })
                        setOpen(true)
                    }
                },
            ]}
            options={{
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                })
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_MaterialTableLocalization}
            icons={_materialTableIcons}
            title="Lista de Encaminhamentos"
        />
        <Dialog
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullWidth maxWidth="lg"
        >
            <DialogTitle>Encaminhamento</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="pessoa_id"
                            value={registro.pessoa_id}
                            label="Beneficiário*"
                            onChange={(e) => handleRegistro('pessoa_id', e.target.value)}
                            select
                        >
                            {dadosPessoa.map(item =>
                                <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="destino_id"
                            value={registro.destino_id}
                            label="Destino*"
                            onChange={(e) => handleRegistro('destino_id', e.target.value)}
                            select
                        >
                            {destinos.map(item =>
                                <MenuItem value={item.id}>{item.nome}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            fullWidth
                            id="assunto"
                            label="Assunto*"
                            variant="outlined"
                            value={registro.assunto}
                            onChange={(e) => handleRegistro('assunto', e.target.value)}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="feito_em"
                            label="Data da Ação*"
                            type="date"
                            value={registro.feito_em}
                            onChange={(e) => handleRegistro('feito_em', e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="status"
                            value={registro.status}
                            label="Situação*"
                            onChange={(e) => handleRegistro('status', e.target.value)}
                            select
                        >
                            <MenuItem value="Aguardando Captura">Aguardando Captura</MenuItem>
                            <MenuItem value="Capturados">Capturados</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="tipo_id"
                            value={registro.tipo_id}
                            label="Tipo de Encaminhamento*"
                            onChange={(e) => handleRegistro('tipo_id', e.target.value)}
                            select>
                            {tipos.map(item =>
                                <MenuItem value={item.id}>{item.nome}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            id="descricao"
                            label="Descrição da Ação*"
                            variant="outlined"
                            value={registro.descricao}
                            onChange={(e) => handleRegistro('descricao', e.target.value)}
                            multiline='true'
                            rows='4'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button onClick={handleClose} style={{ marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                        Fechar
                    </Button>

                    <Button onClick={enviar} variant="contained" color="primary">
                        Enviar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </div>

}