import { _MaterialTableLocalization } from 'links/TableConstant';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardContent, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LaConFetch } from 'links/LaConFetch';
import moment from 'moment';
import { link } from '../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function AlmoxBeneficios() {
    const classes = useStyles();

    const [selectedRow, setSelectedRow] = useState(null);
    const [beneficios, setBeneficios] = useState([]);
    const [produtos, setProdutos] = useState([
        { produto_id: 1, produto: "Cesta Básica", qtd: 2 },
        { produto_id: 2, produto: "Kit Higiene", qtd: 1 },
    ]);

    const loadData = () => {
        LaConFetch(`/api/almoxarifado/beneficio`,
            result => {
                setBeneficios(result.items)
            }, () => { })
    }

    useEffect(() => {
        loadData()
    }, [])

    const [open, setOpen] = useState(false);

    const [registro, setRegistro] = useState({
        dtbaixa: '',
        anexo: '',
        tipo: '',
        obsalmox: ''
    });
    const handleRegistro = (field, value) => {
        let hold = { ...registro }
        hold[field] = value
        setRegistro(hold)
    }

    const enviar = () => {

    }

    const onClose = () => {
        setOpen(false)
        setSelectedRow(null)
    }

    return (<>
        <div className="justify-content-center ml-5">
            <div className="bg-primary" style={{ backgroundColor: 'rgb(37, 85, 39)', padding: '10px' }}>
                <div className="clearfix">
                    <h4 style={{ float: 'left', margin: '3px 0' }}>Solicitação de Benefícios</h4>
                    {open ? <Button onClick={onClose} style={{ float: 'right' }} variant="contained" color="primary">
                        Voltar
                    </Button> : null}
                </div>
            </div>

            {(!open) ? (
                <div>

                    <MaterialTable
                        columns={[
                            { title: 'Protocolo', field: 'protocolo', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Beneficiado', field: 'beneficiado.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Encaminhado por', field: 'funcionario.name', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Tipo', field: 'tipo.nome', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Data de Início', field: 'inicio', headerStyle: { fontWeight: 'bold' }, render: rowData => (rowData.inicio) ? moment(rowData.inicio, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--' },
                            { title: 'Data Liberação', field: 'created_at', headerStyle: { fontWeight: 'bold' }, render: rowData => moment(rowData.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY') },
                            { title: 'Situação', field: 'status.nome', headerStyle: { fontWeight: 'bold' } }
                        ]}
                        data={beneficios}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Editar',
                                onClick: (e, data) => {
                                    setOpen(true)
                                    setSelectedRow(data)
                                }
                            },
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        localization={_materialTableLocalization}
                        icons={_materialTableIcons}
                        title="Lista de Solicitações"
                    />
                </div>) : null}

            {(open) ? (<div>
                <Card style={{ margin: '5px' }}>
                    <CardHeader title="Dados do Beneficiado">
                    </CardHeader>
                    <CardContent>
                        <table width="100%" cellspacing="0" cellpadding="3" className="table">
                            <tr>
                                <td colspan="4" style={{ backgroundColor: '#f5f5f5' }}><strong>Dados de Identificação</strong></td>
                            </tr>
                            <tr>
                                <td width="25%" style={{ textAlign: 'right' }}><strong>Nome:</strong></td>
                                <td colspan="3">{selectedRow.beneficiado.nm_pessoa}</td>
                            </tr>
                            <tr>
                                <td width="25%" style={{ textAlign: 'right' }}><strong>Data de Nascimento:</strong></td>
                                <td colspan="3">{moment(selectedRow.beneficiado.dt_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                            </tr>
                            <tr style={{ backgroundColor: '#f5f5f5' }}>
                                <td colspan="4"><strong>Dados de Contato / Localização</strong></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'right' }}><strong>Endereço:</strong></td>
                                <td colspan="2">{selectedRow.beneficiado.endereco && selectedRow.beneficiado.endereco.nm_logradouro}</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'right' }}><strong>Nº</strong></td>
                                <td>{selectedRow.beneficiado.endereco && selectedRow.beneficiado.endereco.nr_residencia}</td>
                                <td style={{ textAlign: 'right' }}><strong>Complemento:</strong></td>
                                <td>{selectedRow.beneficiado.endereco && selectedRow.beneficiado.endereco.nr_residencia}</td>
                            </tr>
                            <tr>
                                <td width="25%" style={{ textAlign: 'right' }}><strong>CEP:</strong></td>
                                <td width="25%">{selectedRow.beneficiado.endereco && selectedRow.beneficiado.endereco.nr_cep}</td>
                                <td width="25%" style={{ textAlign: 'right' }}><strong>Bairro:</strong></td>
                                <td width="25%">{selectedRow.beneficiado.endereco && selectedRow.beneficiado.endereco.nm_bairro}</td>
                            </tr>
                            <tr>
                                <td width="25%" style={{ textAlign: 'right' }}><strong>Ponto de Referência:</strong></td>
                                <td colspan="3">{selectedRow.beneficiado.endereco && selectedRow.beneficiado.endereco.ds_referencia}</td>
                            </tr>
                        </table>
                    </CardContent>
                </Card>

                <Card style={{ margin: '5px' }}>
                    <CardHeader title="Solicitação">
                    </CardHeader>
                    <CardContent>
                        <Grid item xs={12}>
                            <MaterialTable
                                columns={[
                                    { title: 'Produto', field: 'produto', headerStyle: { fontWeight: 'bold' } },
                                    { title: 'Quantidade', field: 'qtd', headerStyle: { fontWeight: 'bold' } },
                                ]}
                                data={produtos}
                                components={{
                                    Container: 'div',
                                    Pagination: PatchedPagination,
                                }}
                                options={{
                                    actionsColumnIndex: -1,
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                    }),
                                    search: false,
                                    toolbar: false
                                }}
                                localization={_materialTableLocalization}
                                icons={_materialTableIcons}
                                title="Produtos a serem disponibilizados"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                            <label>Período do Benefício</label>
                            <p><strong>{moment(selectedRow.inicio, 'YYYY-MM-DD').format('DD/MM/YYYY')}</strong> até <strong>{moment(selectedRow.termino, 'YYYY-MM-DD').format('DD/MM/YYYY')}</strong></p>
                        </Grid>
                        {(selectedRow.obs) ? (
                            <Grid item xs={12}>
                                <label>Observações do Gestor</label>
                                {selectedRow.obs}
                                <p></p>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="dtbaixa"
                                label="Data de Baixa*"
                                type="date"
                                value={registro.data}
                                onChange={(e) => handleRegistro('dtbaixa', e.target.value)}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Arquivos Anexos</label>
                            <p>****</p>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="obsalmox"
                                label="Observação"
                                variant="outlined"
                                value={registro.obsalmox}
                                onChange={(e) => handleRegistro('obsalmox', e.target.value)}
                                multiline='true'
                                rows='4'
                            />
                        </Grid>

                        <br></br><br></br>
                        <Button onClick={enviar} style={{ float: 'right' }} variant="contained" color="primary">
                            Enviar
                        </Button>
                        <br></br><br></br>
                    </CardContent>
                </Card>
            </div>) : null}

        </div>

    </>)
}