import { Box, Button, ButtonGroup, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import PatchedPagination from 'components/PatchedPagination';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import { LaConFetch } from 'links/LaConFetch';
import MaterialTable from 'material-table';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function BuscaAvancada({ ...props }) {
    /* Filters */
    const [filterType, setFilterType] = useState('nome');
    const [searchType, setSearchType] = useState('responsavel');
    const [selectedRow, setSelectedRow] = useState(null);
    const [question, setQuestion] = useState('')
    const [operatorOptions, setOperatorOptions] = useState([])
    const [operator, setOperator] = useState('')
    const [searchValue, setSearchValue] = useState('')

    const history = useHistory()

    const tableRef = useRef(null);

    const selectRow = (data) => {
        history.push(`/admin/prontuario/${data.familia_id}`)
    }

    let actions = [
        {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (e, data) => { selectRow(data) }
        },
    ]
    let _type = localStorage.getItem('type');
    if (_type == 'REC') {
        actions = []
    }

    useEffect(() => {
        fetchOperator()
    }, [question]);

    const fetchOperator = () => {
        if (question !== '') {
            LaConFetch(`/api/getOperator?question=${question}`, (r) => {
                if (r.success) {
                    setOperatorOptions(r.operator)
                }
            })
        }
    }

    const handleSearch = () => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    return <Paper>
        <Box
            sx={{
                width: '100%',
                p: 2
            }}
        >
            <Typography variant="h6" gutterBottom>
                Busca Avançada
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    p: 1,
                    gap: '2rem'
                }}
            >
                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-acao-native-simple">Ação</InputLabel>
                    <Select
                        fullWidth
                        label="Ação"
                        variant="outlined"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        inputProps={{
                            name: 'Ação',
                            id: 'outlined-acao-native-simple',
                        }}
                    >
                        <MenuItem value={'familia_moradia'}>Famílias que moram</MenuItem>
                        <MenuItem value={'cond_habitacionais'}>Famílias com habitação</MenuItem>
                        <MenuItem value={'cond_educacionais'}>Membro da família que em relação à educação,</MenuItem>
                        <MenuItem value={'cond_trabalho'}>Membro da família que em relação ao trabalho,</MenuItem>
                        <MenuItem value={'deficiencia'}>Família que em relação à deficiência</MenuItem>
                        <MenuItem value={'gestacao'}>Famílias com membros que estão em gestação</MenuItem>
                        <MenuItem value={'rendimentos'}>Famílias com rendimento</MenuItem>
                    </Select>
                </FormControl>

                {
                    operatorOptions && operatorOptions.length > 0 && (<>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-comp-native-simple">Comparador</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                value={operator}
                                onChange={(e) => setOperator(e.target.value)}
                                label="Comparador"
                                inputProps={{
                                    name: 'Comparador',
                                    id: 'outlined-comp-native-simple',
                                }}
                            >
                                {
                                    operatorOptions.map((o, i) => (
                                        <MenuItem key={i} value={o}>{o.text}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        {
                            operator.values ? (
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="outlined-search-native-simple">Valor</InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        label="Valor"
                                        inputProps={{
                                            name: 'Valor',
                                            id: 'outlined-search-native-simple',
                                        }}
                                    >
                                        {
                                            operator.values.map((o, i) => (
                                                <MenuItem key={i} value={o}>{o}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            ) : <FormControl>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Valor"
                                    variant="outlined"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </FormControl>
                        }

                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleSearch}
                        >
                            Buscar
                        </Button>
                    </>)
                }
                <Button
                    variant='contained'
                    color='inherit'
                    onClick={() => {
                        setQuestion('')
                        setOperator('')
                        setSearchValue('')
                        handleSearch()
                    }}
                >
                    Limpar Filtro
                </Button>
            </Box>
        </Box>
        <MaterialTable
            tableRef={tableRef}
            columns={[
                { title: 'Cód. Família', field: 'familia_id', headerStyle: { fontWeight: 'bold' } },
                { title: 'Responsável', field: 'nomeResponsavel', headerStyle: { fontWeight: 'bold' } },
                { title: 'Apelido', field: 'apelido', headerStyle: { fontWeight: 'bold' } },
                { title: 'CPF', field: 'cpf', headerStyle: { fontWeight: 'bold' } },
                { title: 'RG', field: 'rg', headerStyle: { fontWeight: 'bold' } },
                { title: 'NIS', field: 'nis', headerStyle: { fontWeight: 'bold' } },
                { title: 'Endereço', field: 'endereco', headerStyle: { fontWeight: 'bold' } },
                { title: 'Último atendimento', field: 'ultimoAtendimento', headerStyle: { fontWeight: 'bold' } },
                { title: 'Qtd. Dependentes', field: 'n_dependentes', headerStyle: { fontWeight: 'bold' } },
                { title: 'Dependentes', field: 'dependentes', headerStyle: { fontWeight: 'bold' } },
            ]}
            data={query =>
                new Promise((resolve, reject) => {
                    let url = `/api/resultBuscaAvancada?`
                    url += 'page_size=' + query.pageSize
                    url += '&page=' + (query.page + 1)
                    url += '&searchType=' + searchType
                    url += '&filterType=' + filterType
                    url += '&question=' + question
                    url += '&operator=' + operator.text
                    url += '&searchValue=' + searchValue
                    if (query.search) {
                        url += '&search=' + query.search
                    }
                    LaConFetch(url, (r) => {
                        if (r.success) {
                            resolve({
                                data: r.dados,
                                page: r.page - 1,
                                totalCount: r.total
                            });
                        } else {
                            reject()
                        }
                    }, () => {
                        reject()
                    })
                })}
            actions={actions}
            onRowClick={(event, rowData) => {
                selectRow(rowData)
            }}
            components={{
                Container: props => <div {...props} />,
                Pagination: PatchedPagination,
            }}
            options={{
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                })
            }}
            icons={_materialTableIcons}
            localization={_materialTableLocalization}
            title={<ButtonGroup>
                <Button
                    variant={filterType === 'nome' ? 'contained' : 'outlined'}
                    onClick={() => setFilterType('nome')}
                    color="primary"
                >
                    Nome
                </Button>
                <Button
                    variant={filterType === 'cpf' ? 'contained' : 'outlined'}
                    onClick={() => setFilterType('cpf')}
                    color="primary"
                >
                    CPF
                </Button>
                <Button
                    variant={filterType === 'nis' ? 'contained' : 'outlined'}
                    onClick={() => setFilterType('nis')}
                    color="primary"
                >
                    NIS
                </Button>
                <Button
                    variant={filterType === 'endereco' ? 'contained' : 'outlined'}
                    onClick={() => setFilterType('endereco')}
                    color="primary"
                >
                    Endereço
                </Button>
                <Button
                    variant={filterType === 'dependente' ? 'contained' : 'outlined'}
                    onClick={() => setFilterType('dependente')}
                    color="primary"
                >
                    Dependente
                </Button>
            </ButtonGroup>}
        />
    </Paper>
}