import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import CondEducacionais from './CondEducacionais';
import DescumprimentoEducacao from './DescumprimentoEducacao';

export default function EducacaoGroup({ familia_id, filter, ...props }) {
    const [tab, setTab] = React.useState(0);

    const onTabChange = (event, newValue) => {
        setTab(newValue);
    }

    return <div>
        <Tabs
            value={tab}
            indicatorColor="secondary"
            textColor="primary"
            onChange={onTabChange}
            variant='fullWidth'
        >
            <Tab
                label="Condições Educacionais"
                style={{
                    backgroundColor: tab === 0 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 0 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={0}
            />
            <Tab
                label="Descumprimento da Educação"
                style={{
                    backgroundColor: tab === 1 ? '#357a38' : 'rgba(0, 0, 0, 0.1)',
                    color: tab === 1 ? 'white' : null,
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    marginLeft: 3,
                    marginRight: 3
                }}
                value={1}
            />
        </Tabs>
        <div role="tabpanel" hidden={tab !== 0}>
            <CondEducacionais familia_id={familia_id} filter={filter} />
        </div>
        <div role="tabpanel" hidden={tab !== 1}>
            <DescumprimentoEducacao familia_id={familia_id} filter={filter} />
        </div>
    </div>
}