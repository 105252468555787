import { Box, Button, Grid } from '@material-ui/core';
import { link } from 'links/Links';
import React, { useState } from 'react';
import DialogChangeAvatar from 'components/DialogChangeAvatar';

export default function CardResponsavel({ responsavel, reloadResponsavel, ...props }) {
    const [askPhoto, setAskPhoto] = useState(false)

    const onClose = (reload = false) => {
        setAskPhoto(false);
        if (reload) {
            reloadResponsavel()
        }
    }

    return <>
        <Grid container spacing={3}>
            <Grid item lg={4}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <img
                        src={responsavel?.pessoa?.foto ? `${link}${responsavel.pessoa.foto}` : `${link}/images/default-avatar.png`}
                        style={{ height: 100, width: '100%', objectFit: 'contain' }}
                    />
                    <Button variant="contained" size="small" color="primary" style={{ marginTop: '5px' }} onClick={() => setAskPhoto(true)}>
                        Adicionar Foto
                    </Button>
                </Box>
            </Grid>
            <Grid item lg={8}>
                {(responsavel?.pessoa?.nm_pessoa) ? <>
                    <h5 style={{ margin: '2px 0', fontSize: '16px', fontWeight: 'bold' }}>{responsavel.pessoa.nm_pessoa}</h5>
                </> : null}
                <div style={{ fontSize: '14px' }}>
                    {responsavel?.responsavel?.nr_cpf ? <><strong>CPF:</strong> {responsavel.responsavel.nr_cpf} <br /></> : null}
                    {responsavel?.responsavel?.nr_nis ? <><strong>NIS:</strong> {responsavel.responsavel.nr_nis} <br /></> : null}
                    {responsavel?.cadastro ? <><strong>Data Cadastro:</strong> {responsavel.cadastro}<br /></> : null}
                    {responsavel?.ultima_visita ? <><strong>Último Atendimento:</strong> {responsavel.ultima_visita}<br /></> : null}
                    {responsavel?.tecnico_inicial ? <><strong>Técnico de Cadastro:</strong> {responsavel.tecnico_inicial}<br /></> : null}
                    {responsavel?.ultimo_tecnico ? <><strong>Técnico do Último Atendimento:</strong> {responsavel.ultimo_tecnico}</> : null}
                </div>
            </Grid>
        </Grid>
        <DialogChangeAvatar
            open={askPhoto}
            onClose={onClose}
            link={responsavel?.pessoa?.id ? `/api/responsavel/add-foto/${responsavel?.pessoa?.id}` : ''}
        />
    </>
}