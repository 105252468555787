import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LaConFetch } from '../links/LaConFetch';
import React from 'react';

export default function AutocompleteFamilia({ value, onChange, disabled = false, ...props }) {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        let active = true;

        if (text === '') {
            return undefined;
        }

        (async () => {

            if (active) {
                setLoading(true);
                LaConFetch(`/api/familia/search`, (r) => {
                    if (r.success) {
                        setOptions(r.data)
                    }
                    setLoading(false)
                }, () => {
                    setLoading(false)
                }, {
                    search: text
                })
            }
        })();

        return () => {
            active = false;
        };
    }, [text]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return <Autocomplete
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        //getOptionSelected={(option, value) => option.id === value}
        getOptionLabel={(option) => option?.responsavel?.nm_pessoa ?? ''}
        options={options}
        loading={loading}
        //value={value}
        inputValue={text}
        disabled={disabled}
        onInputChange={(e, v) => {
            setText(v)
        }}
        onChange={(e, v) => {
            onChange(v.id)
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Família"
                fullWidth
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )}
    />
}