export const _MaterialTableLocalization = {
    body: {
        emptyDataSourceMessage: 'Nenhum registro para exibir'
    },
    toolbar: {
        searchTooltip: 'Pesquisar',
        searchPlaceholder: 'Pesquisar'
    },
    header: {
        actions: ''
    },
    pagination: {
        labelRowsSelect: 'linhas',
        labelDisplayedRows: '{to} linhas de {count}',
        firstTooltip: 'Primeira página',
        previousTooltip: 'Página anterior',
        nextTooltip: 'Próxima página',
        lastTooltip: 'Última página'
    }
}