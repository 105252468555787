import { Button, Paper, Tab, Tabs, Modal, TextField, Grid, Backdrop, CircularProgress } from '@material-ui/core';
import { LaConFileUpload } from 'links/LaConFetch';
import React, { useState } from 'react';
import swal from 'sweetalert';

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function Integracoes({...props}){
    const [tab,setTab] = useState({})
    const [modal,setModal] = useState(false)
    const [mode,setMode] = useState('ask')
    const [link,setLink] = useState('none')
    const [loading,setLoading] = useState(false)
    const [file,setFile] = useState(false)

    const services = [
        {id:1,nome:'CadÚnico',mode:'federal',link:['cadunico-familia','cadunico-pessoa','cadunico-txt'],linkName:['Importar Família','Importar Pessoa','Importar Geral']},
        {id:2,nome:'Sicon',mode:'federal',link:['sicon'],linkName:['Importar']},
        {id:3,nome:'Sibec',mode:'federal',link:['sibec'],linkName:['Importar']},
        {id:4,nome:'Sisc',mode:'federal',link:['sisc'],linkName:['Importar']},
        {id:4,nome:'XML - MDS',mode:'federal',link:['xml'],linkName:['Exportar']},
        {id:5,nome:'Ação Jovem',mode:'estadual',link:['acao-jovem'],linkName:['Importar']},
        {id:6,nome:'Renda Cidadã',mode:'estadual',link:['renda-cidada'],linkName:['Importar']},
        {id:7,nome:'Viva Leite',mode:'estadual',link:['viva-leite'],linkName:['Importar']},
        {id:7,nome:'BPC - Deficiente',mode:'estadual',link:['bpc-def'],linkName:['Importar']},
        {id:7,nome:'BPC - Idoso',mode:'estadual',link:['bpc-idoso'],linkName:['Importar']},
    ]

    const onImportService=()=>{
        setLoading(true)
        setModal(false)
        let form = []
        let files = [
            {
                name: 'anexo',
                file:file,
            }
        ]
        LaConFileUpload(`/api/importar/${link}`,files,form,(r)=>{
            if(r.success){
                swal('Informações importadas com sucesso','','success')
            }
            setLoading(false)
            onClose()
        },()=>{})
    }


    const onClose=()=>{
        setModal(false)
        setTab({})
        setLink('');
        setFile(false)
        setMode('ask')
    }

    return <div>
        <Backdrop style={{zIndex:9999}} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Paper style={{padding:20,margin:20}}>
            <div>
                <b>Integrações Federais</b>
                <Grid container spacing={2}>
                    {services.filter(item=>item.mode==='federal').map(item=><Grid key={item.id} item xs={2}>
                        <Button variant="contained" style={{width:'100%'}} onClick={()=>{
                            setTab(item)
                            setModal(true)
                        }}>
                            <div style={{fontSize:18}}>
                                {item.nome}
                            </div>
                        </Button>
                    </Grid>)}
                </Grid>
            </div>
        </Paper>
        <Paper style={{padding:20,margin:20}}>
            <div>
                <b>Integrações Estaduais</b>
                <Grid container spacing={2}>
                    {services.filter(item=>item.mode==='estadual').map(item=><Grid key={item.id} item xs={2}>
                        <Button variant="contained" style={{width:'100%'}} onClick={()=>{
                            setTab(item)
                            setModal(true)
                        }}>
                            <div style={{fontSize:18}}>
                                {item.nome}
                            </div>
                        </Button>
                    </Grid>)}
                </Grid>
            </div>
        </Paper>
        <Modal open={modal} onClose={onClose}>
            <div className='container' style={{marginTop:50}}>
                <Paper style={{padding:20}}>
                    {mode==='ask' ? <div>
                        <h4 className='text-center'><b>Escolha o que deseja fazer em {tab.nome}</b></h4>
                        <Grid container spacing={3} style={{marginTop:20}} alignItems='center' justifyContent='center'>
                            {modal && tab && tab.link && tab.link.map((item,pos)=><Grid item xs={6} className="text-center" key={item}>
                                <Button color='primary' variant='contained' onClick={()=>{setMode('import');setLink(item)}} style={{fontSize:18}}>
                                    {tab.linkName[pos]}
                                </Button>
                            </Grid>)}
                        </Grid>
                    </div>: mode==='import'?<div>
                    <h4 className='text-center'><b>Importar dados para {tab.nome}</b></h4>
                    <TextField
                        label='Arquivo' type='file' fullWidth margin='normal'
                        onChange={(e)=>{setFile(e.target.files[0])}}
                        InputLabelProps={{
                            shrink:true
                        }}
                    />
                    <Grid container>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                            <Button disabled={!file} onClick={onImportService} variant="contained" color='primary'>
                                Enviar arquivo
                            </Button>
                        </Grid>
                    </Grid>
                    </div>:null}
                </Paper>
            </div>
        </Modal>
    </div>
}