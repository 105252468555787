import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import {Button, CircularProgress, Backdrop, Modal} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {link} from '../links/Links';
import { LaConFetch } from 'links/LaConFetch';

const useStyles = makeStyles((theme) => ({
  
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    
    paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '50%'
    },    
      
  }));

export default function Perfil(){

    const classes = useStyles();
   
    // Usuario
    const id = localStorage.getItem('id');
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordNovo, setPasswordNovo] = useState("");
    const [passwordConfirma, setPasswordConfirma] = useState("");

    const [sending, setSending] = useState(false);

    const load = () => {

        setSending(true);
            
        LaConFetch(`/api/resultPerfil/${id}`, (result) => {
            if(result.dados !== null)(
                setDadosUser(result.dados)
            )
            setSending(false)
        },(result) => {
            setSending(false)
        })
    };
    
    useEffect(() => {
        
        load();
        
    }, [])

    /* SetDadosUSer */
    const setDadosUser = (dados) => {

        /* Dados */
        setName(dados.name);
        setEmail(dados.email);
    };

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

      /* Limpa os dados */
   const clear = () => {

    /* Dados */
    setPassword("");
    setPasswordNovo("");
    setPasswordConfirma("");
  };

    /* Editar Usuario */
    const editar = () => {

        if(name === "")
            swal("Nome é obrigatório!","", "error")

        else if(email === "")
            swal("E-mail é obrigatório!","", "error")

        else if(email.search('@') === -1)
            swal("E-mail inexistente!","", "error")

        else{
            setSending(true);

            const formDados = {
                name, 
                email, 
            };
            
            LaConFetch(`/api/editarPerfil/${id}`,()=>{
                setSending(false);
                localStorage.setItem('name', name);
                swal("Editado com sucesso!","", "success")
            },()=>{
                setSending(false);
            },formDados)
        }

    };

    /* Editar Senha */
    const editarSenha = () => {

        if(passwordNovo !== passwordConfirma)
            swal("Senha de confirmação está diferente!","", "error")

        else{

            setSending(true);
            const formDados = {
                password,
                passwordNovo 
            };

            
            LaConFetch(`/api/editarPerfilSenha/${id}`,(result)=>{
                setSending(false);
                if(result.success){
                    swal("Senha alterada com sucesso!","", "success");
                }else{
                    swal(result.message,"", "error");
                }
            },()=>{
                setSending(false);
            },formDados)

        }

    };

    return (

        <div className={classes.root} >

            <Backdrop style={{zIndex:99}} open={sending}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ marginLeft:'5%', marginRight:'5%'}}>

                <InputLabel style={{ paddingTop: 40, fontSize: 20 }}>Dados do Usuário</InputLabel>

                <Button onClick={handleOpen} style={{ float: 'right', backgroundColor: 'brown'  }}  variant="contained" color="primary">
                    Alterar Senha <i className="fa fa-lock" style={{fontSize:13}} />
                </Button>

                <br></br><br></br>

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '49%' }}
                    id="name"
                    label="Nome*"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <TextField
                    margin="normal"
                    style={{ width: '49%' }}
                    id="email"
                    label="E-mail*"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <br></br><br></br>

                <Button onClick={editar} style={{ float: 'right' }}  variant="contained" color="primary">
                    Editar Perfil
                </Button>

                <br></br><br></br>
                <br></br>

            </div>

            <div>
                <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                    
                    <div className={classes.paper}>

                        <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Editar Senha</InputLabel>

                        <TextField
                            margin="normal"
                            style={{ width: '100%' }}
                            id="password"
                            label="Digite a senha atual*"
                            variant="outlined"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            style={{ width: '100%' }}
                            id="passwordNovo"
                            label="Digite a nova senha*"
                            variant="outlined"
                            type="password"
                            value={passwordNovo}
                            onChange={(e) => setPasswordNovo(e.target.value)}
                        />
                        
                        <TextField
                            margin="normal"
                            style={{ width: '100%' }}
                            id="password"
                            label="Confirmar nova senha*"
                            variant="outlined"
                            type="password"
                            value={passwordConfirma}
                            onChange={(e) => setPasswordConfirma(e.target.value)}
                        />

                        <br></br><br></br>

                        <Button onClick={handleClose} style={{ float: 'right', marginLeft:15, backgroundColor:"red" }} variant="contained" color="primary">
                            Fechar
                        </Button>
                                
                        <Button onClick={editarSenha} style={{ float: 'right' }} variant="contained" color="primary">
                            Enviar
                        </Button>

                        <br></br><br></br>

                    </div>

                </Modal>
            </div>

        </div>
    );
}