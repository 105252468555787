import { _MaterialTableLocalization } from 'links/TableConstant';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardContent, Grid, TextField, Modal, MenuItem, InputLabel, CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../links/Links';
import PatchedPagination from 'components/PatchedPagination';
import { _materialTableIcons } from 'constants/MaterialConstant';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function AlmoxProdutos() {
    const classes = useStyles();

    const [selectedRow, setSelectedRow] = useState(null);
    const [sending, setSending] = useState(false);
    const [produtos, setProdutos] = useState([])

    const loadData = () => {
        LaConFetch(`/api/almoxarifado/produto`,
            result => {
                setProdutos(result.items)
                setSending(false)
            }, () => { })
    }

    useEffect(() => {
        setSending(true)
        loadData()
    }, [])

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        //clear();
        setOpen(true);
        setRegistro({
            id: '',
            nome: '',
            descricao: '',
            unidade: '',
            atual: '',
            estoque_minimo: '',
            categoria_id: '',
            obs: '',
        })
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [registro, setRegistro] = useState({
        id: '',
        nome: '',
        descricao: '',
        unidade: '',
        atual: '',
        estoque_minimo: '',
        categoria_id: '',
        obs: '',
    });
    const handleRegistro = (field, value) => {
        let hold = { ...registro }
        hold[field] = value
        setRegistro(hold)
    }

    const enviar = () => {
        setSending(true)
        let crudurl = 'new'
        if (registro.id) {
            crudurl = `edit/${registro.id}`
        }
        LaConFetch(`/api/almoxarifado/produto/${crudurl}`,
            result => {
                setSending(false)
                handleClose()
                loadData()
            }, result => setSending(false),
            registro)
    }

    return (<>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>

        <div className="justify-content-center ml-5">
            <div className="bg-primary" style={{ backgroundColor: 'rgb(37, 85, 39)', padding: '10px' }}>
                <div className="clearfix">
                    <h4 style={{ float: 'left', margin: '3px 0' }}>Cadastro de Produtos</h4>
                    <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                        Novo Produto
                    </Button>
                </div>
            </div>

            {(!open) ? (
                <div>

                    <MaterialTable
                        columns={[
                            { title: 'Cód', field: 'id', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Nome', field: 'nome', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Categoria', field: 'categoria.nome', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Unidade', field: 'unidade', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Estoque Atual', field: 'atual', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Estoque Mínimo', field: 'estoque_minimo', headerStyle: { fontWeight: 'bold' } },
                        ]}
                        data={produtos}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Editar',
                                onClick: (e, data) => {
                                    setRegistro({
                                        id: data.id,
                                        nome: data.nome,
                                        descricao: data.descricao,
                                        unidade: data.unidade,
                                        atual: data.atual,
                                        estoque_minimo: data.estoque_minimo,
                                        categoria_id: data.categoria_id,
                                        obs: data.obs,
                                    })
                                    setOpen(true)
                                }
                            },
                        ]}

                        options={{
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        localization={_MaterialTableLocalization}
                        icons={_materialTableIcons}
                        title="Produtos Cadastrados"
                    />
                </div>) : null}

            {(open) ? (<div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>

                    <div className={classes.paper} style={{ maxHeight: '90vh', overflowY: 'auto' }}>

                        <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Cadastro de Produto</InputLabel>

                        <Grid container spacing={3}>

                            <Grid xs={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="nome"
                                    label="Nome do Produto*"
                                    variant="outlined"
                                    value={registro.nome}
                                    onChange={(e) => handleRegistro('nome', e.target.value)}
                                />
                            </Grid>

                            <Grid xs={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="descricao"
                                    label="Descrição do Produto*"
                                    variant="outlined"
                                    value={registro.descricao}
                                    onChange={(e) => handleRegistro('descricao', e.target.value)}
                                />
                            </Grid>

                            <Grid xs={12} lg={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginTop: 15 }}
                                    id="categoria_id"
                                    value={registro.categoria_id}
                                    label="Categoria*"
                                    onChange={(e) => handleRegistro('categoria_id', e.target.value)}
                                    select>
                                    <MenuItem value="1">Geral</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid xs={12} lg={3}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="unidade"
                                    label="Unidade*"
                                    variant="outlined"
                                    value={registro.unidade}
                                    onChange={(e) => handleRegistro('unidade', e.target.value)}
                                />
                            </Grid>
                            <Grid xs={12} lg={3}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="estoque_minimo"
                                    label="Estoque Mínimo*"
                                    variant="outlined"
                                    value={registro.estoque_minimo}
                                    onChange={(e) => handleRegistro('estoque_minimo', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="obs"
                                    label="Observação"
                                    variant="outlined"
                                    value={registro.obs}
                                    onChange={(e) => handleRegistro('obs', e.target.value)}
                                    multiline='true'
                                    rows='4'
                                />
                            </Grid>

                        </Grid>

                        <br></br><br></br>

                        <Button onClick={handleClose} style={{ float: 'right', marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                            Fechar
                        </Button>

                        <Button onClick={enviar} style={{ float: 'right' }} variant="contained" color="primary">
                            Enviar
                        </Button>

                        <br></br><br></br>

                    </div>

                </Modal>
            </div>) : null}

        </div>

    </>)
}