import { Fab, Grid, TextField } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import React from 'react';
import NumberFormatCustom from './NumberFormatCustom';

export default function LinhaDespesa({ item, onChange, mode = 'remove', onAdd = () => { }, onRemove = () => { }, ...props }) {
    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <TextField
                margin="normal"
                fullWidth
                id="nm_despesa"
                label="Nome da despesa"
                variant="outlined"
                value={item.nm_despesa}
                onChange={(e) => onChange('nm_despesa', e.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
            <TextField
                margin="normal"
                fullWidth
                id="vl_despesa"
                label="Valor da Despesa"
                variant="outlined"
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
                value={item.vl_despesa}
                onChange={(e) => onChange('vl_despesa', e.target.value)}
            />
        </Grid>
        <Grid item xs={4}>
            {mode === 'add' ?
                <Fab style={{ marginTop: 21, marginRight: '40%' }} onClick={(e) => onAdd()} size="small" color="primary" aria-label="addFaixa">
                    <Add />
                </Fab> : <Fab style={{ marginTop: 21, marginRight: '40%' }} onClick={(e) => onRemove()} size="small" color="secundary" aria-label="closeFaixa">
                    <Close />
                </Fab>}
        </Grid>
    </Grid>
}