import CrudView from 'components/Crud/CrudView';
import moment from 'moment';
import React from 'react';

export default function Presenca({id,...props}){
    return <div>
        <CrudView
            url={`/api/scfv/presenca/${id}`} title='Presença'
            columns={[
                {
                    field:'nm_pessoa',title:'Nome',
                },{
                    field:'pivot.atendido_em',title:'Presente no dia', render:(data)=>moment(data?.pivot?.atendido_em,'YYYY-MM-DD HH:mm:ss').format('HH:mm - DD/MM/YYYY')
                }
            ]}
            canEdit={false} topBar={false}
            fields={[
                {
                    field:'presenca',label:'Nome', type:'select', grid:{xs:6},link:`/api/scfv/participacao/${id}/naopresente`, nameKey:'nm_pessoa',dataKey:'id',required:true
                },{
                    field:'atendido_em',label:'Presente no dia', type:'datetime', grid:{xs:6},default:true,value:moment().format('YYYY-MM-DD HH:mm')
                }
            ]}
            downloadLink={`/api/scfv/presenca/${id}/excel`}
        />
    </div>
}