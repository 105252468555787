import { _MaterialTableLocalization } from 'links/TableConstant';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardContent, Grid, TextField, MenuItem, Modal, CircularProgress, Backdrop, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../links/Links';
import Presenca from './Oficina/Presensa';
import Participacao from './Oficina/Participacao';
import Desligamento from './Oficina/Desligamento';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function Oficinas() {
    const classes = useStyles();

    const [selectedRow, setSelectedRow] = useState(null);
    const [sending, setSending] = useState(false);
    const [cursos, setCursos] = useState([]);
    const [open, setOpen] = useState(false);
    const [presencaModal, setPresencaModal] = useState(0)
    const [participacaoModal, setParticipacaoModal] = useState(0)
    const [desligamentoModal, setDesligamentoModal] = useState(0)

    const loadData = () => {
        LaConFetch(`/api/oficina/oficina`,
            result => {
                setCursos(result.items)
            }, () => { })
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleOpen = () => {
        setRegistro({
            id: '',
            titulo: '',
            periodo: '',
            tipo: '',
            descricao: '',
            data_inicio: '',
            data_termino: '',
            horario_inicio: '',
            horario_termino: '',
            carga_horaria: '',
            max_participantes: '',
            participantes_count: 0,
            obs: '',
            frequencia_minima: 0,
            publico_alvo: 'Todos'
        })
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [registro, setRegistro] = useState({
        anexo: '',
        tipo: '',
        observacao: ''
    });
    const handleRegistro = (field, value) => {
        let hold = { ...registro }
        hold[field] = value
        // if (field == 'tipo') {
        //     value = parseInt(value)
        //     setTipo(value)
        // }
        setRegistro(hold)
    }

    const enviar = () => {
        setSending(true)
        let crudurl = 'new'
        if (registro.id) {
            crudurl = `edit/${registro.id}`
        }
        LaConFetch(`/api/oficina/oficina/${crudurl}`,
            result => {
                setSending(false)
                handleClose()
                loadData()
            }, result => setSending(false), registro)
    }

    return (<>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>

        <div className="justify-content-center ml-5">
            <div className="bg-primary" style={{ backgroundColor: 'rgb(37, 85, 39)', padding: '10px' }}>
                <div className="clearfix">
                    <h4 style={{ float: 'left', margin: '3px 0' }}>Cadastro de Cursos e Oficinas</h4>
                    {(!open) ? (
                        <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                            Novo Curso/Oficina
                        </Button>) : (
                        <Button onClick={handleClose} style={{ float: 'right' }} variant="contained" color="warning">
                            Lista de Cursos/Oficinas
                        </Button>
                    )}
                </div>
            </div>

            {(!open) ? (
                <div>

                    <MaterialTable
                        columns={[
                            { title: 'Cód', field: 'id', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Título', field: 'titulo', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Período', field: 'periodo', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Tipo', field: 'tipo', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Data Início', field: 'data_inicio', headerStyle: { fontWeight: 'bold' }, render: rowData => moment(rowData.data_inicio, 'YYYY-MM-DD').format('DD/MM/YYYY') },
                            { title: 'Data Término', field: 'data_termino', headerStyle: { fontWeight: 'bold' }, render: rowData => moment(rowData.data_termino, 'YYYY-MM-DD').format('DD/MM/YYYY') },
                            { title: 'Carga Horária', field: 'carga_horaria', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Vagas', field: 'max_participantes', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Participantes', field: 'participantes_count', headerStyle: { fontWeight: 'bold' } },
                        ]}
                        data={cursos}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Editar',
                                onClick: (e, data) => {
                                    setOpen(true)
                                    setRegistro({
                                        id: data.id,
                                        titulo: data.titulo,
                                        periodo: data.periodo,
                                        tipo: data.tipo,
                                        data_inicio: data.data_inicio,
                                        data_termino: data.data_termino,
                                        horario_inicio: data.horario_inicio,
                                        horario_termino: data.horario_termino,
                                        carga_horaria: data.carga_horaria,
                                        max_participantes: data.max_participantes,
                                    })
                                }
                            }, {
                                icon: 'check',
                                tooltip: 'Presença',
                                onClick: (e, data) => {
                                    setPresencaModal(data.id)
                                }
                            }, {
                                icon: 'list',
                                tooltip: 'Convidados',
                                onClick: (e, data) => {
                                    setParticipacaoModal(data.id)
                                }
                            }, {
                                icon: 'remove',
                                tooltip: 'Desligamentos',
                                onClick: (e, data) => {
                                    setDesligamentoModal(data.id)
                                }
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        localization={_MaterialTableLocalization}
                        icons={_materialTableIcons}
                        title="Lista de Solicitações" />
                </div>) : null}

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth maxWidth="lg"
            >
                <DialogTitle>
                    <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Cadastro de Oficina</InputLabel>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Título do Curso/Oficina*" variant="outlined"
                                value={registro.titulo}
                                onChange={(e) => handleRegistro('titulo', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Descrição" variant="outlined"
                                value={registro.descricao}
                                onChange={(e) => handleRegistro('descricao', e.target.value)}
                                multiline rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined" fullWidth
                                value={registro.periodo}
                                label="Período*"
                                onChange={(e) => handleRegistro('periodo', e.target.value)} select
                            >
                                <MenuItem value="Único">Único</MenuItem>
                                <MenuItem value="Semanal">Semanal</MenuItem>
                                <MenuItem value="Mensal">Mensal</MenuItem>
                                <MenuItem value="Trimestral">Trimestral</MenuItem>
                                <MenuItem value="Semestral">Semestral</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth
                                value={registro.tipo}
                                label="Tipo*"
                                onChange={(e) => handleRegistro('tipo', e.target.value)} select
                            >
                                <MenuItem value="Aberto">Aberto</MenuItem>
                                <MenuItem value="Fechado">Fechado</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={8}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Data de Início*" type="date"
                                value={registro.data_inicio}
                                onChange={(e) => handleRegistro('data_inicio', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                variant="outlined" fullWidth
                                value={registro.horario_inicio}
                                label="Horário Início*"
                                onChange={(e) => handleRegistro('horario_inicio', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={8}>
                            <TextField
                                variant="outlined" fullWidth
                                label="Data de Término*" type="date"
                                value={registro.data_termino}
                                onChange={(e) => handleRegistro('data_termino', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                variant="outlined" fullWidth
                                value={registro.horario_termino}
                                label="Horário Término*"
                                onChange={(e) => handleRegistro('horario_termino', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Frequência mínima" variant="outlined"
                                value={registro.frequencia_minima} type='number'
                                onChange={(e) => handleRegistro('frequencia_minima', e.target.value)}
                                helperText="em Horas"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Carga Horária*" variant="outlined"
                                value={registro.carga_horaria}
                                onChange={(e) => handleRegistro('carga_horaria', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Público Alvo*" variant="outlined"
                                value={registro.publico_alvo}
                                onChange={(e) => handleRegistro('publico_alvo', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Máximo Participantes*" variant="outlined"
                                value={registro.max_participantes}
                                onChange={(e) => handleRegistro('max_participantes', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Observação" variant="outlined"
                                value={registro.obs}
                                onChange={(e) => handleRegistro('obs', e.target.value)}
                                multiline rows={4}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" width="100%" justifyContent="space-between">
                        <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                            Fechar
                        </Button>

                        <Button onClick={enviar} variant="contained" color="primary">
                            Enviar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Modal open={presencaModal > 0} onClose={() => setPresencaModal(0)}>
                <div className='container' style={{ marginTop: 50 }}>
                    <Presenca id={presencaModal} />
                </div>
            </Modal>

            <Modal open={participacaoModal > 0} onClose={() => setParticipacaoModal(0)}>
                <div className='container' style={{ marginTop: 50 }}>
                    <Participacao id={participacaoModal} />
                </div>
            </Modal>
            <Modal open={desligamentoModal > 0} onClose={() => setDesligamentoModal(0)}>
                <div className='container' style={{ marginTop: 50 }}>
                    <Desligamento id={desligamentoModal} />
                </div>
            </Modal>

        </div>

    </>)
}