import { Backdrop, CircularProgress } from '@material-ui/core';
import CrudView from 'components/Crud/CrudView';
import { LaConFetch } from 'links/LaConFetch';
import React, { useState } from 'react';

export default function Comunicacao({...props}){
    const [sending,setSending] = useState(false)

    const onSend=(row)=>{
        setSending(true)
        let form = {
            mensagem: row.mensagem,
            telefone: row.pessoa.telefone.nr_ddd+''+row.pessoa.telefone.nr_telefone,
            id: row.id,
        }
        LaConFetch(`/api/enviasms`,(r)=>{
            if(r.success){
                swal('SMS enviado com sucesso','','success')
            }else{
                swal('Erro ao enviar SMS','','error')
            }
            setSending(false)
        },()=>{
            swal('Erro ao enviar SMS','','error')
            setSending(false)
        },form)
    }
    const onSendAll=(row)=>{
        setSending(true)
        let form = {
            mensagem: row.mensagem,
            id: row.id,
        }
        LaConFetch(`/api/enviasms/all`,(r)=>{
            if(r.success){
                swal('SMS enviado com sucesso','','success')
            }else{
                swal('Erro ao enviar SMS','','error')
            }
            setSending(false)
        },()=>{
            swal('Erro ao enviar SMS','','error')
            setSending(false)
        },form)
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <CrudView
            columns={[
                {
                    field:'mensagem',title:'Mensagem',
                },{
                    field:'status',title:'Status',
                },
            ]}
            title='Comunicações' url='/api/comunicacao'
            fields={[
                {
                    field:'pessoa_id',label:'Usuário',type:'select',grid:{xs:12}, link: '/api/pessoa/com-telefone',nameKey:'nm_pessoa',dataKey:'id',required:true
                },{
                    field:'mensagem',label:'Mensagem',type:'text',grid:{xs:12}, input:{multiline:true,rows:4},required:true
                },{
                    field: 'status',type:'default',value:'Pendente',
                }
            ]}
            extraAction={{
                icon:"check",
                tooltip:"Enviar",
                onClick:(e,row)=>{
                    onSend(row)
                }
            }}
            secondExtraAction={{
                icon:"check",
                tooltip:"Enviar a Todos",
                onClick:(e,row)=>{
                    onSendAll(row)
                }
            }}
        />
    </div>
}