import { _MaterialTableLocalization } from 'links/TableConstant';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardContent, Grid, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { link } from '../links/Links';
import { LaConFetch } from 'links/LaConFetch';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function AlmoxEstoque() {
    const classes = useStyles();

    const [selectedRow, setSelectedRow] = useState(null);

    const [produtos, setProdutos] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [tipo, setTipo] = React.useState('');

    const [adicionar, setAdicionar] = useState({
        produto: '',
        qtd: ''
    });

    const loadData = () => {
        LaConFetch(`/api/almoxarifado/index`, (r) => {
            if (r.success) {
                setProdutos(r.items)
            }
        }, () => { })
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleAdicionar = (field, value) => {
        let hold = { ...adicionar }
        hold[field] = value
        setAdicionar(hold)
    }
    const addProdutos = () => {
        let hold = [...produtos]
        hold.push({
            produto_id: adicionar.produto.produto_id,
            produto: adicionar.produto.produto,
            qtd: adicionar.qtd,
        })
        setProdutos(hold)
        setAdicionar({
            produto: '',
            qtd: ''
        })
    }

    const handleOpen = () => {
        //clear();
        setOpen(true);
    };

    const handleClose = () => {
        //clear();
        setOpen(false);
    };

    const [registro, setRegistro] = useState({
        anexo: '',
        tipo: '',
        observacao: ''
    });
    const handleRegistro = (field, value) => {
        let hold = { ...registro }
        hold[field] = value
        if (field == 'tipo') {
            value = parseInt(value)
            setTipo(value)
        }
        setRegistro(hold)
    }

    const enviar = () => {

    }

    return (<>
        <div className="justify-content-center ml-5">
            <div className="bg-primary" style={{ backgroundColor: 'rgb(37, 85, 39)', padding: '10px' }}>
                <div className="clearfix">
                    <h4 style={{ float: 'left', margin: '3px 0' }}>Análise de Estoque</h4>
                </div>
            </div>

            {(!open) ? (
                <div>

                    <MaterialTable
                        columns={[
                            { title: 'Cód', field: 'produto_id', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Descrição', field: 'produto.nome', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Unidade', field: 'unidade', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Categoria', field: 'produto.categoria.nome', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Entradas', field: 'entrada', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Transferido', field: 'transferido', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Saídas', field: 'saida', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Estoque Atual', field: 'quantidade', headerStyle: { fontWeight: 'bold' } },
                        ]}
                        data={produtos}
                        options={{
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        localization={_MaterialTableLocalization}
                        icons={_materialTableIcons}
                        title="Estoque"
                    />
                </div>) : null}

        </div>

    </>)
}