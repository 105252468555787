import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import React from 'react';
import moment from 'moment'
import { link } from 'links/Links';

export default function Carteira({ responsavel, open, onClose, pessoa, ...props }) {
    const [loading, setLoading] = React.useState(false)

    const generatePrint = () => {
        let content = document.getElementById("carteira");
        let pri = document.getElementById("printer").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    return <Dialog open={open} onClose={() => onClose()} maxWidth="lg" >
        <DialogTitle>
            Secretária De Assistência e Desenvolvimento Social
        </DialogTitle>
        <DialogContent style={{ width: 550 }}>
            <iframe id="printer" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
            <div id="carteira">
                <div style={{
                    width: 450,
                    height: 280,
                    border: '1px solid black',
                    backgroundColor: '#bbc',
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: 5
                }}>
                    <div style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: '#fff',
                        backgroundColor: '#252525',
                        borderRadius: 5,
                    }}>
                        <img src={`${link}/images/logo_only.png`} style={{ height: 50 }} />
                        Secretária De Assistência e Desenvolvimento Social
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 10,
                    }}>
                        <div style={{
                            flexGrow: 1,
                            marginRight: 20
                        }}>
                            <ul style={{
                                listStyle: 'none',
                                padding: 0,
                                margin: 0,
                            }}>
                                <li style={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexGrow: 1,
                                }}>
                                    <strong>Nª de Registro:</strong>
                                    #<span style={{
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        marginLeft: 5,
                                        borderRadius: 5,
                                        flexGrow: 1,
                                    }}>{pessoa?.pessoa?.id}</span>
                                </li>
                                <li style={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexGrow: 1,
                                }}>
                                    <strong>Nome:</strong>
                                    <span style={{
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        marginLeft: 5,
                                        borderRadius: 5,
                                        flexGrow: 1,
                                    }}>{pessoa?.pessoa?.nm_pessoa}</span>
                                </li>
                                {responsavel ? <li style={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexGrow: 1,
                                }}
                                >
                                    <strong>Responsável:</strong>
                                    <span style={{
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        marginLeft: 5,
                                        borderRadius: 5,
                                        flexGrow: 1,
                                    }}>{responsavel.pessoa.nm_pessoa}</span>
                                </li> : <>
                                    <li style={{
                                        marginTop: 5,
                                        display: 'flex',
                                        flexGrow: 1,
                                    }}
                                    >
                                        <strong>CPF:</strong>
                                        <span style={{
                                            backgroundColor: '#fff',
                                            padding: '2px 5px',
                                            marginLeft: 5,
                                            borderRadius: 5,
                                            flexGrow: 1,
                                        }}>{pessoa?.responsavel?.nr_cpf}</span>
                                    </li>
                                    <li style={{
                                        marginTop: 5,
                                        display: 'flex',
                                        flexGrow: 1,
                                    }}
                                    >
                                        <strong>Número NIS:</strong>
                                        <span style={{
                                            backgroundColor: '#fff',
                                            padding: '2px 5px',
                                            marginLeft: 5,
                                            borderRadius: 5,
                                            flexGrow: 1,
                                        }}>{pessoa?.responsavel?.nr_nis}</span>
                                    </li>
                                </>}
                                <li style={{
                                    marginTop: 5,
                                    display: 'flex',
                                    flexGrow: 1,
                                }}
                                >
                                    <strong>Data de Nascimento:</strong>
                                    <span style={{
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        marginLeft: 5,
                                        borderRadius: 5,
                                        flexGrow: 1,
                                    }}>{moment(pessoa?.pessoa?.dt_nascimento).format('DD/MM/YYYY')}</span>
                                </li>
                            </ul>
                        </div>

                        <div style={{
                            width: 90,
                            height: 120,
                            backgroundColor: '#525357',
                            border: 'solid 1px #000',
                            display: 'inline-block',
                            objectFit: 'cover',
                            borderRadius: 5,
                        }}>
                            <img src={pessoa?.pessoa?.foto ? `${link}${pessoa.pessoa.foto}` : `${link}/images/default-avatar.png`} alt="Foto do usuário" style={{
                                width: 90,
                                height: 120,
                                backgroundColor: '#ddd',
                                display: 'inline-block',
                                objectFit: 'cover',
                                borderRadius: 5,
                            }} />
                        </div>
                    </div>

                    <div style={{
                        textAlign: 'center',
                        fontSize: 12,
                        marginTop: 10,
                    }}>
                        Esta carteira é de uso intransferível
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Box display="flex" justifyContent="center" width="100%">
                <Button variant="contained" color="primary" onClick={() => generatePrint()}>
                    Gerar Versão para Impressão
                </Button>
            </Box>
        </DialogActions>
    </Dialog>
}