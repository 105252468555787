import { Button, Modal, Paper, TextField } from '@material-ui/core';
import CrudView from 'components/Crud/CrudView';
import React, { useState } from 'react';

export default function Conselhos({...props}){
    const [openSearch,setOpenSearch] = useState(false)

    return <div>
        <CrudView
            extraButton setOpenSearch={setOpenSearch}
            columns={[
                {
                    field:'nome',title:'Nome',
                },{
                    field:'cpf',title:'CPF',
                },{
                    field:'rg',title:'RG',
                },{
                    field:'mae',title:'Mãe',
                },{
                    field:'pai',title:'Pai',
                },{
                    field:'situacao',title:'Situação',
                },
            ]}
            title='Cadastrados' url='/api/conselho' endLink={`?user_id=${localStorage.getItem('id')}`}
            fields={[
                {
                    field:'nome',label:'Nome',type:'text',grid:{xs:6}
                },{
                    field:'nascimento',label:'Data de Nascimento', type: 'date',grid:{xs:6}
                },{
                    field:'cpf',label:'CPF',type:'text',grid:{xs:6}
                },{
                    field:'rg',label:'RG',type:'text',grid:{xs:6}
                },{
                    field:'pai',label:'Pai',type:'text',grid:{xs:12}
                },{
                    field:'mae',label:'Mãe',type:'text',grid:{xs:12}
                },{
                    field:'responsavel',label:'Responsavel',type:'text',grid:{xs:12}
                },{
                    field:'escola',label:'Escola',type:'text',grid:{xs:12}
                },{
                    field:'escolaridade',label:'Escolaridade',type:'text',grid:{xs:6}
                },{
                    field:'periodo',label:'Periodo',type:'text',grid:{xs:6}
                },{
                    field:'acompanhamento',label:'Acompanhamento',type:'text',grid:{xs:12},input:{multiline:true,rows:2}
                },{
                    field:'situacao',label:'Situação',type:'text',grid:{xs:12},
                },{
                    field:'user_id',type:"default",value:localStorage.getItem('id')
                }
            ]}
        />
        <Modal open={openSearch} onClose={()=>setOpenSearch(false)}>
            <div className='container' style={{marginTop:50}}>
                <Paper style={{padding:20}}>
                    <h5>Buscar no SUAS</h5>
                    <TextField label='Atendido' fullWidth margin='normal'/>
                    <TextField label='Nome do Pai' fullWidth margin='normal'/>
                    <TextField label='Nome do Mãe' fullWidth margin='normal'/>
                    <TextField label='Endereço' fullWidth margin='normal'/>
                    <Button variant='contained' color='primary' onClick={()=>{setOpenSearch(false)}}>
                        Buscar
                    </Button>
                </Paper>
            </div>
        </Modal>
    </div>

}