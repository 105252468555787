import CrudView from 'components/Crud/CrudView';
import moment from 'moment';
import React from 'react';

export default function Desligamento({id,...props}){
    return <div>
        <CrudView
            url={`/api/scfv/desligamento/${id}`} title='Desligamento'
            columns={[
                {
                    field:'nm_pessoa',title:'Nome',
                },{
                    field: 'pivot.motivo', title: 'Motivo desligamento'
                }
            ]}
            canEdit={false} topBar={false}
            fields={[
                {
                    field:'desligamentos',label:'Nome', type:'select', grid:{xs:12},link:`/api/scfv/participante/${id}`, nameKey:'nm_pessoa',dataKey:'id',required:true
                },{
                    field:'motivo',label:'Motivo do desligamento', type:'text', grid:{xs:12},input:{multiline:true,rows:4}
                }
            ]}
        />
    </div>
}