import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Modal, Box, Dialog, DialogTitle, DialogContent, Grid, DialogActions } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';
import YesNoRadio from 'components/FormInputs/YesNoRadio';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="R$ "
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function CondTrabalho({ familia_id, filter }) {

    const classes = useStyles();

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // CondTrabalho
    const [id, setId] = useState("");
    const [st_carteira_trabalho, setSt_carteira_trabalho] = useState("");
    const [cd_ocupacao, setCd_ocupacao] = useState("");
    const [nm_ocupacao, setNm_ocupacao] = useState("");
    const [st_qualificacao_profissional, setSt_qualificacao_profissional] = useState("");
    const [ds_situacao_profissional, setDs_situacao_profissional] = useState("");
    const [vl_renda_mensal, setVl_renda_mensal] = useState("");
    const [dt_anotacao, setDt_anotacao] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");

    /* Desabilitar Qualificacao */
    const [qualificacaoOff, setQualificacaoOff] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (st_qualificacao_profissional === 'Sim') {
            setQualificacaoOff(false);
        } else {
            setQualificacaoOff(true);
        }

    }, [st_qualificacao_profissional])

    useEffect(() => {

        if (cd_ocupacao === '0') {
            setNm_ocupacao("Não Trabalha");
        } else if (cd_ocupacao === '1') {
            setNm_ocupacao("Trabalhador por conta própria (bico, autônomo)");
        } else if (cd_ocupacao === '2') {
            setNm_ocupacao("Trabalhador temporário em área rural");
        } else if (cd_ocupacao === '3') {
            setNm_ocupacao("Empregado sem carteira de trabalho assinada");
        } else if (cd_ocupacao === '4') {
            setNm_ocupacao("Empregado com carteira de trabalho assinada");
        } else if (cd_ocupacao === '5') {
            setNm_ocupacao("Trabalhador doméstico sem carteira de trabalho assinada");
        } else if (cd_ocupacao === '6') {
            setNm_ocupacao("Trabalhador doméstico com carteira de trabalho assinada");
        } else if (cd_ocupacao === '7') {
            setNm_ocupacao("Trabalhador não-remunerado");
        } else if (cd_ocupacao === '8') {
            setNm_ocupacao("Militar ou Servidor Público");
        } else if (cd_ocupacao === '9') {
            setNm_ocupacao("Empregador");
        } else if (cd_ocupacao === '10') {
            setNm_ocupacao("Estagiário");
        } else if (cd_ocupacao === '11') {
            setNm_ocupacao("Aprendiz (em condição legal)");
        } else {
            setNm_ocupacao("");
        }

    }, [cd_ocupacao])

    const [sending, setSending] = useState(false);

    /* Cadastrar CondTrabalho*/
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (cd_ocupacao === "")
            swal("Código da ocupação é obrigatório!", "", "error")

        else if (dt_anotacao === "")
            swal("Data da anotação é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                st_carteira_trabalho,
                cd_ocupacao,
                nm_ocupacao,
                st_qualificacao_profissional,
                ds_situacao_profissional,
                vl_renda_mensal,
                dt_anotacao,
                ds_observacao
            };

            setSending(true);
            LaConFetch(`/api/cadastrarCondTrabalho`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e CondTrabalho*/
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);
        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))
        LaConFetch(`/api/resultCondTrabalho/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetCondTrabalho */
    const setDadosCondTrabalho = (dados) => {

        /* Dados */
        setId(dados.id);
        setSt_carteira_trabalho(dados.st_carteira_trabalho);
        setCd_ocupacao(dados.cd_ocupacao);
        setNm_ocupacao(dados.nm_ocupacao);
        setSt_qualificacao_profissional(dados.st_qualificacao_profissional);
        setDs_situacao_profissional(dados.ds_situacao_profissional);
        setVl_renda_mensal(dados.vl_renda_mensal);
        setDt_anotacao(dados.dt_anotacao);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setSt_carteira_trabalho("");
        setCd_ocupacao("");
        setNm_ocupacao("");
        setSt_qualificacao_profissional("");
        setDs_situacao_profissional("");
        setVl_renda_mensal("");
        setDt_anotacao("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosCondTrabalho(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar CondTrabalho*/
    const editar = () => {

        const formDados = {
            st_carteira_trabalho,
            cd_ocupacao,
            nm_ocupacao,
            st_qualificacao_profissional,
            ds_situacao_profissional,
            vl_renda_mensal,
            dt_anotacao,
            ds_observacao
        };

        setSending(true);
        LaConFetch(`/api/editarCondTrabalho/${id}`,
            result => {
                load();
                setSending(false);
                swal("Enviado com sucesso!", "", "success")
                setOpen(false)
            }, () => { }, formDados)

    };

    /* Remover CondTrabalho */
    const remover = (dados) => {

        const id = dados.id;

        setSending(true);
        LaConFetch(`/api/removerCondTrabalho/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})

    };

    return <div className={classes.root}>

        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <div />
            <Button onClick={handleOpen} variant="contained" color="primary">
                Adicionar Trabalho
            </Button>
        </Box>

        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Cód. Ocupação', field: 'cd_ocupacao', headerStyle: { fontWeight: 'bold' } },
                { title: 'Nome Ocupação', field: 'nm_ocupacao', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data da Anotação', field: 'dt_anotacaoTab', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            options={{
                actionsColumnIndex: -1
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_materialTableLocalization}
            icons={_materialTableIcons}
            title="Condições de Trabalho"
        />

        <Dialog
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullWidth maxWidth="lg"
        >
            <DialogTitle>
                <InputLabel style={{ fontSize: 20 }}>Condições de Trabalho</InputLabel>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="pessoa_id"
                            value={pessoa_id}
                            disabled={editing}
                            label="Nome da pessoa*"
                            onChange={(e) => setPessoa_id(e.target.value)}
                            select
                        >
                            {dadosPessoa.map(item =>
                                <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <YesNoRadio
                            label="Possui Carteira de Trabalho?"
                            value={st_carteira_trabalho}
                            onChange={setSt_carteira_trabalho}
                            name="st_carteira_trabalho"
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="cd_ocupacao"
                            value={cd_ocupacao}
                            label="Código da Ocupação*"
                            onChange={(e) => setCd_ocupacao(e.target.value)}
                            select
                        >
                            <MenuItem value="0">0 - Não Trabalha</MenuItem>
                            <MenuItem value="1">1 - Trabalhador por conta própria (bico, autônomo)</MenuItem>
                            <MenuItem value="2">2 - Trabalhador temporário em área rural</MenuItem>
                            <MenuItem value="3">3 - Empregado sem carteira de trabalho assinada</MenuItem>
                            <MenuItem value="4">4 - Empregado com carteira de trabalho assinada</MenuItem>
                            <MenuItem value="5">5 - Trabalhador doméstico sem carteira de trabalho assinada</MenuItem>
                            <MenuItem value="6">6 - Trabalhador doméstico com carteira de trabalho assinada</MenuItem>
                            <MenuItem value="7">7 - Trabalhador não-remunerado</MenuItem>
                            <MenuItem value="8">8 - Militar ou Servidor Público</MenuItem>
                            <MenuItem value="9">9 - Empregador</MenuItem>
                            <MenuItem value="10">10 - Estagiário</MenuItem>
                            <MenuItem value="11">11 - Aprendiz (em condição legal)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            id="vl_renda_mensal"
                            label="Renda Mensal"
                            variant="outlined"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            value={vl_renda_mensal}
                            onChange={(e) => setVl_renda_mensal(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <YesNoRadio
                            label="Possui qualificação profissional?"
                            value={st_qualificacao_profissional}
                            onChange={setSt_qualificacao_profissional}
                            name="st_qualificacao_profissional"
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            disabled={qualificacaoOff}
                            id="ds_situacao_profissional"
                            label="Qual a qualificação caso possua?"
                            variant="outlined"
                            value={ds_situacao_profissional}
                            onChange={(e) => setDs_situacao_profissional(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dt_anotacao"
                            label="Data da Anotação*"
                            type="date"
                            value={dt_anotacao}
                            onChange={(e) => setDt_anotacao(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="ds_observacao"
                            label="Observação"
                            variant="outlined"
                            value={ds_observacao}
                            onChange={(e) => setDs_observacao(e.target.value)}
                            multiline='true'
                            rows='4'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                        Fechar
                    </Button>
                    <Button onClick={(editing === false) ? enviar : editar} variant="contained" color="primary">
                        Enviar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </div >

}