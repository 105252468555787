import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Modal, CardHeader, Box, DialogActions, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { LaConFetch } from 'links/LaConFetch';
import moment from 'moment';
import { link } from '../../links/Links';
import { _MaterialTableLocalization } from 'links/TableConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="R$ "
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function SolicitaBeneficio({ familia_id, filter }) {

    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);
    /* Carregar Pessoa e CondTrabalho*/
    const [beneficios, setBeneficios] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [produtosAll, setProdutosAll] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [adicionar, setAdicionar] = useState({
        produto: '',
        qtd: ''
    });
    const [registro, setRegistro] = useState({
        pessoa_id: '',
        anexo: '',
        tipo_id: '',
        obs: '',
        status_id: 1
    });
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();

    /* Cadastrar CondTrabalho*/
    const enviar = () => {
        let url = (registro.id) ? `edit/${registro.id}` : `create`
        let hold = { ...registro }
        hold.produtos = produtos
        LaConFetch(`/api/almoxarifado/beneficio/${url}`,
            result => {
                setSending(false);
                swal("Enviado com sucesso!", "", "success")
                loadBeneficios()
                setOpen(false)
            }, () => { }, hold)
    };

    const loadBeneficios = () => {
        setLoading(true)
        LaConFetch(`/api/almoxarifado/beneficio/familia/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.items !== null) {
                    setBeneficios(result.items)
                }
                setLoading(false)
            }, () => setLoading(false))
    }

    const loadAux = () => {
        setSending(true);
        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setSending(false)
            }, () => setSending(false))

        LaConFetch(`/api/almoxarifado/produto`,
            result => {
                if (result.items !== null) {
                    setProdutosAll(result.items)
                }
                //setSending(false)
            }, () => setSending(false))

        LaConFetch(`/api/almoxarifado/tipo-beneficio`,
            result => {
                if (result.items !== null) {
                    setTipos(result.items)
                }
                //setSending(false)
            }, () => setSending(false))
    }

    useEffect(() => {
        loadBeneficios();
    }, [filter])

    useEffect(() => {
        loadAux();
    }, [])

    const handleAdicionar = (field, value) => {
        let hold = { ...adicionar }
        hold[field] = value
        setAdicionar(hold)
    }

    const handleRegistro = (field, value) => {
        let hold = { ...registro }
        hold[field] = value
        setRegistro(hold)
    }

    const addProdutos = () => {
        let hold = [...produtos]
        hold.push({
            produto_id: adicionar.produto.id,
            produto: adicionar.produto.nome,
            qtd: adicionar.qtd,
        })
        setProdutos(hold)
        setAdicionar({
            produto: '',
            qtd: ''
        })
    }

    const remProduto = (produto_id) => {
        let hold = [...produtos];
        let cnt = 0;
        hold.forEach(function (item) {
            if (item.produto_id === produto_id) {
                hold.splice(cnt, 1);
            }
            cnt++
        })
        setProdutos(hold)
    }

    const handleOpen = () => {
        setProdutos([])
        setAdicionar({
            produto: '',
            qtd: ''
        })
        setRegistro({
            pessoa_id: '',
            anexo: '',
            tipo_id: '',
            obs: '',
            status_id: 1
        })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>

        <Box style={{ backgroundColor: 'rgb(37, 85, 39)' }} color="white" p={2} display="flex" justifyContent="space-between">
            <h4 style={{ margin: '3px 0' }}>Solicitação de Benefícios</h4>
            <Button onClick={handleOpen} variant="contained" color="primary">
                Nova Solicitação
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Protocolo', field: 'protocolo', headerStyle: { fontWeight: 'bold' } },
                { title: 'Tipo', field: 'tipo.nome', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data do Processo', field: 'created_at', render: rowData => moment(rowData.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm'), headerStyle: { fontWeight: 'bold' } },
                { title: 'Situação', field: 'status.nome', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={beneficios}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => {
                        setRegistro({
                            tipo_id: data.tipo_id,
                            obs: data.obs,
                            id: data.id,
                            pessoa_id: data.pessoa_id,
                        })
                        setOpen(true)
                    }
                },
            ]}
            options={{
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                })
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_MaterialTableLocalization}
            icons={_materialTableIcons}
            title="Lista de Solicitações"
        />

        <Dialog
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullWidth maxWidth="lg"
        >
            <DialogTitle>Solicitação de Benefício</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="pessoa_id"
                            value={registro.pessoa_id}
                            label="Nome da pessoa*"
                            onChange={(e) => handleRegistro('pessoa_id', e.target.value)}
                            select>
                            {dadosPessoa.map(item =>
                                <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="tipo_id"
                            value={registro.tipo_id}
                            label="Tipo*"
                            onChange={(e) => handleRegistro('tipo_id', e.target.value)}
                            select>
                            {tipos.map(item =>
                                <MenuItem value={item.id}>{item.nome}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item md={8}>

                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="produto_id"
                            value={adicionar.produto}
                            label="Produto*"
                            onChange={(e) => handleAdicionar('produto', e.target.value)}
                            select>
                            {produtosAll.map(item =>
                                <MenuItem value={item}>{item.nome}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            fullWidth
                            id="qtd"
                            label="Quantidade"
                            variant="outlined"
                            value={adicionar.qtd}
                            onChange={(e) => handleAdicionar('qtd', e.target.value)}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Button variant="contained" size="large" color="primary" onClick={() => addProdutos()}>
                            Adicionar
                        </Button>
                    </Grid>
                    <Grid item md={12}>
                        <MaterialTable
                            columns={[
                                { title: 'Produto', field: 'produto', headerStyle: { fontWeight: 'bold' } },
                                { title: 'Quantidade', field: 'qtd', headerStyle: { fontWeight: 'bold' } },
                            ]}
                            data={produtos}
                            actions={[
                                {
                                    icon: 'remove',
                                    tooltip: 'Remover',
                                    onClick: (e, data) => {
                                        remProduto(data.produto_id)
                                    }
                                },
                            ]}
                            components={{
                                Container: 'div',
                                Pagination: PatchedPagination,
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                }),
                                search: false,
                                showTitle: false,
                                toolbar: false
                            }}
                            icons={_materialTableIcons}
                            localization={_MaterialTableLocalization}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            fullWidth
                            id="obs"
                            label="Observação"
                            variant="outlined"
                            value={registro.obs}
                            onChange={(e) => handleRegistro('obs', e.target.value)}
                            multiline='true'
                            rows='4'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                        Fechar
                    </Button>
                    <Button onClick={enviar} variant="contained" color="primary">
                        Enviar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </div>

}