import React, { useState, useEffect } from 'react'
import { Backdrop, Box, Button, CircularProgress, Modal } from '@material-ui/core'
import { LaConFetch } from 'links/LaConFetch'
import { _MaterialTableLocalization } from 'links/TableConstant'
import MaterialTable from 'material-table'

import swal from 'sweetalert';
import CrudEdit from './CrudEdit'
import { link } from 'links/Links'
import { _materialTableIcons } from 'constants/MaterialConstant'
import PatchedPagination from 'components/PatchedPagination'

export default function CrudView({ withRegister = false, url, columns, downloadLink = false, title, fields = [], dynamic = false, remoteLoad = false, canEdit = true, canDelete = true, topBar = true, noPaper = false, canAdd = true, setOpenSearch = () => { }, extraButton = false, canSee = false, endLink = '', extraAction = false, secondExtraAction = false, ...props }) {
    const [data, setData] = useState([])
    const [sending, setSending] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const tableRef = React.createRef();

    const [edit, setEdit] = useState({})
    const [see, setSee] = useState(false)
    const [add, setAdd] = useState(false)

    const loadData = () => {
        setLoading(true)
        LaConFetch(`${url}${endLink}`, (r) => {
            if (r.success) {
                setData(r.items)
            }
            setLoading(false)
        }, () => { })
    }

    useEffect(() => {
        if (!dynamic) {
            loadData()
        }
    }, [url, dynamic])

    const onClose = (reload = false) => {
        setOpenModal(false)
        if (reload) {
            if (dynamic) {
                tableRef?.current?.onQueryChange()
            } else {
                loadData()
            }
        }
        setEdit({})
        setAdd(false)
    }

    const onDelete = (id) => {
        setSending(true)
        let form = {}
        LaConFetch(`${url}/delete/${id}`, (r) => {
            setSending(false)
            if (r.success) {
                swal("Removido com sucesso!", "", "success");
            } else {
                swal("Oops", r.message, "error");
            }
            if (dynamic) {
                tableRef?.current?.onQueryChange()
            } else {
                loadData()
            }
        }, () => { }, form)
    }

    const onAskDelete = (id) => {
        swal(`Tem certeza que deseja remover o ${title}?`, {
            buttons: {
                remover: "Remover",
                cancel: "Sair"
            },
        }).then((value) => {
            switch (value) {
                case "remover":
                    onDelete(id)
                    break;
            }
        })
    }

    let actions = [];

    if (canEdit) {
        actions.push({
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (e, data) => {
                setEdit(data)
                setAdd(false)
                setSee(false)
                setOpenModal(true)
            }
        })
    }
    if (canDelete) {
        actions.push({
            icon: 'delete',
            tooltip: 'Remover',
            onClick: (e, data) => {
                onAskDelete(data.id)
            }
        })
    }
    if (!topBar && canAdd) {
        actions.push({
            icon: 'add', isFreeAction: true,
            tooltip: 'Adicionar',
            onClick: (e, data) => {
                setEdit({})
                setAdd(true)
                setSee(false)
                setOpenModal(true)
            }
        })
    }
    if (canSee) {
        actions.push({
            icon: 'visibility',
            tooltip: 'Visualizar',
            onClick: (e, data) => {
                setEdit(data)
                setAdd(false)
                setSee(true)
                setOpenModal(true)
            }
        })
    }
    if (downloadLink) {
        actions.push({
            isFreeAction: true,
            icon: 'print',
            tooltip: 'Baixar',
            onClick: (e, data) => {
                fetch(`${link}${downloadLink}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                        'Accept': 'application/json'
                    },
                })
                    .then(res => res.blob())
                    .then(blob => {
                        var file = window.URL.createObjectURL(blob);
                        //window.location.assign(file);
                        window.open(file)
                    });
            }
        })
    }
    if (extraAction) {
        actions.push(extraAction)
    }
    if (secondExtraAction) {
        actions.push(secondExtraAction)
    }

    return <div>
        <Backdrop style={{ zIndex: 99 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        {
            withRegister && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >

                    <Button
                        onClick={() => {
                            setEdit({})
                            setAdd(true)
                            setOpenModal(true)
                        }}
                        color='secondary'
                        variant='contained'
                    >
                        Registrar Atendimento
                    </Button>
                </Box>
            )
        }
        {topBar ? <div className="justify-content-center ml-5">
            <div className="bg-primary" style={{ backgroundColor: 'rgb(37, 85, 39)', padding: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ margin: '3px 0' }}>{title}</h4>
                    <div>
                        {extraButton ? <Button onClick={() => { setOpenSearch(true) }} style={{ marginRight: 10 }} variant="contained" color="primary">
                            Pesquisar no SUAS
                        </Button> : null}
                        {canAdd ? <Button onClick={() => {
                            setEdit({})
                            setAdd(true)
                            setOpenModal(true)
                        }} variant="contained" color="primary">
                            Novo {title}
                        </Button> : null}
                    </div>
                </div>
            </div>
        </div> : null}
        <MaterialTable
            isLoading={loading}
            data={dynamic ? (query) =>
                new Promise((resolve, reject) => {
                    let finalUrl = `${url}?`
                    finalUrl += 'page_size=' + query.pageSize
                    finalUrl += '&page=' + (query.page + 1)
                    LaConFetch(finalUrl, (r) => {
                        if (r.success) {
                            resolve({
                                data: r.items.data,
                                page: r.items.current_page - 1,
                                totalCount: r.items.total
                            });
                        } else {
                            reject()
                        }
                    }, () => {
                        reject()
                    })
                }) : data}
            title={title} tableRef={tableRef}
            icons={_materialTableIcons}
            localization={_MaterialTableLocalization}
            columns={columns}
            options={{
                actionsColumnIndex: -1,
            }}
            components={noPaper ? {
                Container: props => <div {...props} />,
                Pagination: PatchedPagination,
            } : {
                Pagination: PatchedPagination,
            }}
            actions={actions}
        />
        <Modal open={openModal} onClose={() => onClose(false)}>
            <div className='container' style={{ marginTop: 50 }}>
                <CrudEdit
                    fields={fields} add={add} oldData={edit} endLink={endLink}
                    onClose={onClose} readOnly={see}
                    url={url} title={title}
                    modalStyle={{ maxHeight: '80vh', overflow: 'auto' }} remoteLoad={remoteLoad}
                />
            </div>
        </Modal>
    </div>
}