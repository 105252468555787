import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop, Modal, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function Acolhimento({ familia_id, filter }) {
    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");
    // Acolhimento
    const [id, setId] = useState("");
    const [dt_inicio, setDt_inicio] = useState("");
    const [dt_fim, setDt_fim] = useState("");
    const [ds_motivo, setDs_motivo] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const classes = useStyles();

    // Cadastrar Acolhimento
    const enviar = () => {
        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")
        else if (dt_inicio === "")
            swal("Data de início é obrigatório!", "", "error")
        else if (ds_motivo === "")
            swal("Motivo é obrigatório!", "", "error")
        else {

            const formDados = {
                pessoa_id,
                dt_inicio,
                dt_fim,
                ds_motivo,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarAcolhimento`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    // Editar Acolhimento
    const editar = () => {
        if (dt_inicio === "")
            swal("Data de início é obrigatório!", "", "error")
        else if (ds_motivo === "")
            swal("Motivo é obrigatório!", "", "error")
        else {
            const formDados = {
                dt_inicio,
                dt_fim,
                ds_motivo,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/editarAcolhimento/${id}`,
                result => {
                    load();
                    setSending(false);
                    swal("Editado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e Acolhimento */
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);
        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

        LaConFetch(`/api/resultAcolhimento/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosAcolhimento */
    const setDadosAcolhimento = (dados) => {

        /* Dados */
        setId(dados.id);
        setDt_inicio(dados.dt_inicio);
        setDt_fim(dados.dt_fim);
        setDs_motivo(dados.ds_motivo);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setDt_inicio("");
        setDt_fim("");
        setDs_motivo("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosAcolhimento(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Remover Acolhimento */
    const remover = (dados) => {
        const id = dados.id;
        setSending(true);
        LaConFetch(`/api/removerAcolhimento/${id}`,
            result => {
                setSending(false)
            }, () => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <div />
            <Button onClick={handleOpen} variant="contained" color="primary">
                Adicionar Acolhimento
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data Início', field: 'dt_inicioTab', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            options={{
                actionsColumnIndex: -1
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_materialTableLocalization}
            icons={_materialTableIcons}
            title="Acolhimento"
        />
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <div className={classes.paper}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Acolhimento</InputLabel>

                {(editing === false) ? (
                    <TextField
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 15 }}
                        id="pessoa_id"
                        value={pessoa_id}
                        label="Nome da pessoa*"
                        onChange={(e) => setPessoa_id(e.target.value)}
                        select>
                        {dadosPessoa.map(item =>
                            <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                        )}
                    </TextField>
                ) : (
                    <TextField
                        disabled={true}
                        margin="normal"
                        variant="outlined"
                        style={{ marginTop: 15, width: '100%' }}
                        id="nm_pessoa"
                        value={nm_pessoa}
                        label="Nome da pessoa"
                    />)}

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="dt_inicio"
                    label="Data de Início*"
                    type="date"
                    value={dt_inicio}
                    onChange={(e) => setDt_inicio(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="dt_fim"
                    label="Data de Fim"
                    type="date"
                    value={dt_fim}
                    onChange={(e) => setDt_fim(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="normal"
                    style={{ width: '100%' }}
                    id="ds_motivo"
                    label="Motivo*"
                    variant="outlined"
                    value={ds_motivo}
                    onChange={(e) => setDs_motivo(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                <TextField
                    margin="normal"
                    style={{ width: '100%' }}
                    id="ds_observacao"
                    label="Observação"
                    variant="outlined"
                    value={ds_observacao}
                    onChange={(e) => setDs_observacao(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                <br></br><br></br>

                <Button onClick={handleClose} style={{ float: 'right', marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>

                <Button onClick={(editing === false) ? enviar : editar} style={{ float: 'right' }} variant="contained" color="primary">
                    Enviar
                </Button>

                <br></br><br></br>

            </div>

        </Modal>
    </div>

}