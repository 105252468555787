import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Paper, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { LaConFetch } from 'links/LaConFetch';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import SaveIcon from '@material-ui/icons/Save';
import 'moment/locale/pt-br';
import swal from 'sweetalert';
import SelectFromAnother from './SelectFromAnother';
import { LaConFileUpload } from 'links/LaConFetch';
import MultiSelectFromAnother from './MultiSelectFromAnother';
import MapPoints from './MapPoints';
import ArrayText from './ArrayText';

export default function CrudEdit({ oldData, add = false, fields = [], title, onClose, url, readOnly = false, endLink = '', modalStyle = {}, remoteLoad = false, ...props }) {
    const [data, setData] = useState({})
    const [file, setFile] = useState({})
    const [sending, setSending] = useState(false)

    useEffect(() => {
        if (remoteLoad) {
            if (oldData?.id) {
                loadData()
            }
        } else if (oldData) {
            setData(oldData)
        }
    }, [oldData, remoteLoad])

    const loadData = () => {
        setSending(true)
        LaConFetch(`${url}/show/${oldData.id}`, (r) => {
            if (r.success) {
                let hold = { ...r.item }
                fields.forEach(item => {
                    if (item.type === 'map-point') {
                        hold.local = {
                            lat: hold.lat,
                            lng: hold.lng
                        }
                    }
                })
                setData(hold)
            }
            setSending(false)
        }, () => {
            setData(oldData)
            setSending(false)
        })
    }

    const updateData = (field, value) => {
        let hold = { ...data }
        hold[field] = value
        setData(hold)
    }
    const updateFile = (field, value) => {
        let hold = { ...file }
        hold[field] = value
        setFile(hold)
    }

    const onSave = () => {
        setSending(true)
        let form = { ...data }
        let cantGo = false;
        fields.forEach(item => {
            if (item.type === 'default' || (!form[item.field] && item.default)) {
                form[item.field] = item.value
            }
            if (item.required && !form[item.field]) {
                cantGo = true;
            }
        })
        if (cantGo) {
            swal('Algum campo obrigatório está faltando', "", "error");
            setSending(false)
            return false;
        }
        let formData = [];

        fields.forEach(item => {
            switch (item.type) {
                case 'date':
                    formData.push({
                        name: item.field,
                        value: form[item.field] ? moment(form[item.field]).format('YYYY-MM-DD') : ''
                    })
                    break;
                case 'map-point':
                    formData.push({
                        name: 'lat',
                        value: form[item.field] ? form[item.field].lat : null
                    });
                    formData.push({
                        name: 'lng',
                        value: form[item.field] ? form[item.field].lng : null
                    });
                    break;
                case 'file':
                    break;
                default:
                    formData.push({
                        name: item.field,
                        value: form[item.field] ?? '',
                    })
            }
        })

        let formFiles = Object.entries(file).map(item => {
            return {
                name: item[0],
                file: item[1]
            }
        })
        LaConFileUpload(`${url}/${add ? 'new' : `edit/${oldData.id}`}${endLink}`, formFiles, formData, (r) => {
            if (r.success) {
                swal(`${title} ${add ? 'criado' : 'salvo'} com sucesso!`, "", "success");
            } else {
                swal("Oops", r.message, "error");
            }
            setSending(false)
            onClose(true)
        }, () => {
            swal("Oops", "Houve um erro, tente novamente", "error");
            setSending(false)
        })
    }

    return <Paper style={{ padding: 20, ...modalStyle }}>
        <Backdrop style={{ zIndex: 99 }} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={2}>
            <Grid item xs={10}>
                <h5>{add ? 'Adicionar' : 'Editar'} {title}:</h5>
            </Grid>
            <Grid item xs={2}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </Grid>
        </Grid>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={1}>
                {fields.map(item => {
                    let gridProps = { ...item.grid }
                    let inputProps = { ...item.input }
                    switch (item.type) {
                        case 'date':
                            return <Grid item key={item.field} {...gridProps}>
                                <KeyboardDatePicker
                                    inputVariant="outlined" variant="dialog" disabled={readOnly}
                                    label={item.label} fullWidth margin='normal' required={item?.required}
                                    value={data[item.field] ? moment(data[item.field], 'YYYY-MM-DD').toDate() : data[item.field]}
                                    onChange={(e) => updateData(item.field, moment(e).format('YYYY-MM-DD'))}
                                    format="DD/MM/YYYY"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...inputProps}
                                />
                            </Grid>
                        case 'datetime':
                            return <Grid item key={item.field} {...gridProps}>
                                <KeyboardDateTimePicker
                                    inputVariant="outlined" variant="dialog" disabled={readOnly}
                                    ampm={false} required={item?.required}
                                    label={item.label} fullWidth margin='normal'
                                    value={data[item.field] ? moment(data[item.field], 'YYYY-MM-DD HH:mm').toDate() : data[item.field]}
                                    onChange={(e) => updateData(item.field, moment(e).format('YYYY-MM-DD HH:mm'))}
                                    format="DD/MM/YYYY HH:mm"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...inputProps}
                                />
                            </Grid>
                        case 'time':
                            return <Grid item key={item.field} {...gridProps}>
                                <KeyboardTimePicker
                                    inputVariant="outlined" variant="dialog" disabled={readOnly}
                                    ampm={false} required={item?.required}
                                    label={item.label} fullWidth margin='normal'
                                    value={data[item.field] ? moment(data[item.field], 'HH:mm:ss').toDate() : data[item.field]}
                                    onChange={(e) => updateData(item.field, moment(e).format('HH:mm:ss'))}
                                    format="HH:mm"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...inputProps}
                                />
                            </Grid>
                        case 'select':
                            return <Grid item key={item.field} {...gridProps}>
                                <SelectFromAnother
                                    link={item.link} disabled={readOnly}
                                    value={data[item.field] ?? ''} required={item?.required}
                                    onChange={(value) => updateData(item.field, value)}
                                    nameKey={item.nameKey} dataKey={item.dataKey}
                                    label={item.label}
                                    {...inputProps}
                                />
                            </Grid>
                        case 'multi-select':
                            return <Grid item key={item.field} {...gridProps}>
                                <MultiSelectFromAnother
                                    link={item.link} disabled={readOnly}
                                    value={data[item.field] ?? []} required={item?.required}
                                    onChange={(value) => updateData(item.field, value)}
                                    nameKey={item.nameKey} dataKey={item.dataKey}
                                    label={item.label}
                                    {...inputProps}
                                />
                            </Grid>
                        case 'map-point':
                            return <Grid item key={item.field} {...gridProps}>
                                <MapPoints
                                    value={data[item.field] ?? { lat: null, lng: null }}
                                    onChange={(value) => {
                                        updateData(item.field, value)
                                    }}
                                />
                            </Grid>
                        case 'default':
                            return null;
                        case 'array':
                            return <Grid item key={item.field} {...gridProps}>
                                <ArrayText
                                    label={item.label} fullWidth variant='outlined' disabled={readOnly}
                                    value={data[item.field] ?? []} margin='normal' required={item?.required}
                                    onChange={(value) => updateData(item.field, value)}
                                    {...inputProps}
                                />
                            </Grid>
                        case 'file':
                            return <Grid item key={item.field} {...gridProps}>
                                <TextField
                                    label={item.label} fullWidth variant='outlined' disabled={readOnly} margin='normal'
                                    onChange={(e) => updateFile(item.field, e.target.files[0])}
                                    type='file' required={item?.required}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...inputProps}
                                />
                            </Grid>
                        case 'text':
                        default:
                            return <Grid item key={item.field} {...gridProps}>
                                <TextField
                                    label={item.label} fullWidth variant='outlined' disabled={readOnly}
                                    value={data[item.field] ?? ''} margin='normal' required={item?.required}
                                    onChange={(e) => updateData(item.field, e.target.value)}
                                    {...inputProps}
                                />
                            </Grid>
                    }
                })}
            </Grid>
        </MuiPickersUtilsProvider>
        {readOnly ? null : <Box display='flex' justifyContent='flex-end'>
            <Button color='primary' variant='contained' onClick={onSave}>
                <SaveIcon /> Salvar
            </Button>
        </Box>}
    </Paper>
}