import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';

export default function YesNoRadio({ label, onChange, value, options = [
    { label: 'SIM', value: 'Sim' },
    { label: 'NÃO', value: 'Não' }
], name, formProps, ...props }) {
    return <FormControl component="fieldset" {...formProps}>
        <FormLabel component="legend" style={{ marginBottom: 2, paddingBottom: 3 }}>{label}</FormLabel>
        <RadioGroup row value={value} aria-label="st_documento" name={name} onChange={(e) => onChange(e.target.value)}>
            {options.map((item, index) => <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item.label}
            />)}
        </RadioGroup>
    </FormControl>
}