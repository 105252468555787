import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Modal, Box, Dialog, DialogActions, Grid, DialogTitle, DialogContent } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import PatchedPagination from 'components/PatchedPagination';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import YesNoRadio from 'components/FormInputs/YesNoRadio';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

}));

export default function Gestacao({ familia_id, filter }) {

    const classes = useStyles();

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // Gestação
    const [id, setId] = useState("");
    const [qt_meses, setQt_meses] = useState("");
    const [dt_anotacao, setDt_anotacao] = useState("");
    const [st_pre_natal, setSt_pre_natal] = useState("");
    const [st_zika, setSt_zika] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    /* Cadastrar Gestação*/
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (qt_meses === "")
            swal("Quantidade de meses é obrigatório!", "", "error")

        else if (dt_anotacao === "")
            swal("Data da anotação é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                qt_meses,
                dt_anotacao,
                st_pre_natal,
                st_zika,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarGestacao`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e Gestação*/
    const [rows, setRows] = useState([]);

    const load = () => {
        setLoading(true);
        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

        LaConFetch(`/api/resultGestacao/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))
    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosGestacao */
    const setDadosGestacao = (dados) => {

        /* Dados */
        setId(dados.id);
        setQt_meses(dados.qt_meses);
        setDt_anotacao(dados.dt_anotacao);
        setSt_pre_natal(dados.st_pre_natal);
        setSt_zika(dados.st_zika);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setQt_meses("");
        setDt_anotacao("");
        setSt_pre_natal("");
        setSt_zika("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosGestacao(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar Gestação*/
    const editar = () => {

        if (qt_meses === "")
            swal("Quantidade de meses é obrigatório!", "", "error")

        else {

            const formDados = {
                qt_meses,
                dt_anotacao,
                st_pre_natal,
                st_zika,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/editarGestacao/${id}`,
                result => {
                    load();
                    setSending(false);
                    swal("Editado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Remover Gestação */
    const remover = (dados) => {
        const id = dados.id;
        setSending(true);
        LaConFetch(`/api/removerGestacao/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})
    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>Gestação</Typography>
            <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                Adicionar Gestação
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Quantidade Meses', field: 'qt_meses', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data da Anotação', field: 'dt_anotacaoTab', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            components={{
                Pagination: PatchedPagination
            }}
            options={{
                actionsColumnIndex: -1
            }}
            localization={_materialTableLocalization}
            icons={_materialTableIcons}
            title="Gestação"
        />

        <Dialog
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullWidth maxWidth="lg"
        >
            <DialogTitle>
                <InputLabel style={{ fontSize: 20 }}>Gestação</InputLabel>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Nome da pessoa*"
                            variant="outlined" fullWidth select disabled={editing}
                            value={pessoa_id} onChange={(e) => setPessoa_id(e.target.value)}
                        >
                            {dadosPessoa.map(item =>
                                <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            id="qt_meses"
                            label="Quantidade de meses gestando*"
                            variant="outlined"
                            value={qt_meses}
                            onChange={(e) => setQt_meses(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dt_anotacao"
                            label="Data da Anotação*"
                            type="date"
                            value={dt_anotacao}
                            onChange={(e) => setDt_anotacao(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <YesNoRadio
                            label="Já inicou Pré-Natal?"
                            value={st_pre_natal}
                            onChange={setSt_pre_natal}
                            name="st_pre_natal"
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <YesNoRadio
                            label="Tem ou teve Zika durante a Gestação?"
                            value={st_zika}
                            onChange={setSt_zika}
                            name="st_zika"
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="ds_observacao"
                            label="Observação"
                            variant="outlined"
                            value={ds_observacao}
                            onChange={(e) => setDs_observacao(e.target.value)}
                            multiline='true'
                            rows='4'
                        />
                    </Grid>
                </Grid>
            </DialogContent >
            <DialogActions>
                <Box display='flex' justifyContent='space-between' width='100%'>
                    <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                        Fechar
                    </Button>

                    <Button onClick={(editing === false) ? enviar : editar} variant="contained" color="primary">
                        Enviar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog >
    </div >
}