import { Box, InputLabel, TextField } from '@material-ui/core';
import React from 'react';

export default function AreaEstudoSocial({ historico, novo, onChange, title, description = false, helper = '', ...props }) {
    return <Box mt={4}>
        <Box mb={3}>
            <InputLabel style={{ fontWeight: 'bold', fontSize: 14 }}>
                {title}
            </InputLabel>
            {description ? <InputLabel style={{ marginTop: 10, fontSize: 12 }}>
                {description}
            </InputLabel> : null}
        </Box>
        <TextField
            variant="outlined" fullWidth
            value={historico} disabled
            multiline label="Histórico"
            InputLabelProps={{ shrink: true }}
        />
        <TextField
            variant="outlined" fullWidth placeholder='Inserir novo...'
            value={novo} onChange={(e) => onChange(e.target.value)}
            multiline rows={4}
            helperText={helper}
        />
    </Box>
}