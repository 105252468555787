import React, { useState } from 'react';
import { HashRouter, Route, Switch } from "react-router-dom";
import Login from "views/Login.jsx";
import AdminLayout from "layouts/Admin.jsx";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import VerifyLife from 'layouts/VerifyLife';
import SelfProvider from 'provider/SelfContext';

const sadsTheme = createTheme({
    palette: {
        primary: {
            main: '#357a38'
        },
    },
    typography: {
        fontSize: 20,
    },

});

export default function App() {

    return (<ThemeProvider theme={sadsTheme}>
        <HashRouter>
            <SelfProvider>
                <Switch>
                    <Route path="/admin" render={props => <AdminLayout {...props} />} />
                    <Route render={props => <Login {...props} />} />
                </Switch>
            </SelfProvider>
        </HashRouter>
    </ThemeProvider>)

}