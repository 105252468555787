import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, Box, DialogActions, Dialog, DialogContent, Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import MoveDependente from '../../components/MoveDependente';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';
import YesNoRadio from 'components/FormInputs/YesNoRadio';
import DialogChangeAvatar from 'components/DialogChangeAvatar';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function Dependente({ familia_id }) {
    const [id, setId] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");
    const [ds_sexo, setDs_sexo] = useState("");
    const [paif_paefi, setPaif_paefi] = useState(0);
    const [dt_nascimento, setDt_nascimento] = useState("");
    const [cd_parentesco, setCd_parentesco] = useState("");
    const [ds_parentesco, setDs_parentesco] = useState("");
    const [ds_parentesco_outro, setDs_parentesco_outro] = useState("");
    const [st_deficiente, setSt_deficiente] = useState("");
    const [st_documento, setSt_documento] = useState("");
    const [ds_documentacao_civil, setDs_documentacao_civil] = useState("");
    const [documentacaoOff, setDocumentacaoOff] = useState(true);
    const [askMoveDependente, setAskMoveDependente] = useState({ open: false, id: 0, nm_pessoa: '' });
    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [outroGrauOff, setOutroGrauOff] = useState(true);
    const [rows, setRows] = useState([]);
    const [askAvatar, setAskAvatar] = useState({ open: false, id: 0 });
    const [sending, setSending] = useState(false);
    const classes = useStyles();

    useEffect(() => {

        if (st_documento === 'Sim') {
            setDocumentacaoOff(false);
        } else {
            setDocumentacaoOff(true);
        }

    }, [st_documento])

    /* Set CD_ATENDIMENTO e DS_ATENDIMENTO */
    const setParentesco = (codigo) => {

        setCd_parentesco(codigo);

        if (codigo === '2') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Cônjuge/companheiro');
        }
        else if (codigo === '3') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('FIlho(a)');
        }
        else if (codigo === '4') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Neto(a)');
        }
        else if (codigo === '5') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Bisneto(a)');
        }
        else if (codigo === '6') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Pai/Mãe');
        }
        else if (codigo === '7') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Sogro(a)');
        }
        else if (codigo === '8') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Irmão/irmã');
        }

        else if (codigo === '9') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Genro/Nora');
        }

        else if (codigo === '10') {
            setDs_parentesco_outro(null);
            setOutroGrauOff(true);
            setDs_parentesco('Não Parente');
        }

        else if (codigo === '11') {
            setOutroGrauOff(false);
        }

    }

    const setOutroGrau = (texto) => {

        setDs_parentesco_outro(texto);
        setDs_parentesco(texto);
    }

    /* Cadastrar Dependente*/
    const enviar = () => {

        if (nm_pessoa === "")
            swal("Nome é obrigatório!", "", "error")

        else if (ds_sexo === "")
            swal("Sexo é obrigatório!", "", "error")

        else if (dt_nascimento === "")
            swal("Data de Nascimento é obrigatório!", "", "error")

        else if (cd_parentesco === "")
            swal("Grau de parentesco é obrigatório!", "", "error")

        else {

            const formDados = {
                paif_paefi,
                familia_id,
                nm_pessoa,
                ds_sexo,
                dt_nascimento,
                cd_parentesco,
                ds_parentesco,
                st_deficiente,
                st_documento,
                ds_documentacao_civil,
            };

            setSending(true);
            LaConFetch(`/api/cadastrarDependente`,
                result => {
                    load();
                    setSending(false);
                    swal("Dependente enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Editar Dependente*/
    const editar = () => {

        if (nm_pessoa === "")
            swal("Nome é obrigatório!", "", "error")

        else if (ds_sexo === "")
            swal("Sexo é obrigatório!", "", "error")

        else if (dt_nascimento === "")
            swal("Data de Nascimento é obrigatório!", "", "error")

        else if (cd_parentesco === "")
            swal("Grau de parentesco é obrigatório!", "", "error")

        else {

            const formDados = {
                paif_paefi,
                nm_pessoa,
                ds_sexo,
                dt_nascimento,
                cd_parentesco,
                ds_parentesco,
                st_deficiente,
                st_documento,
                ds_documentacao_civil
            };

            setSending(true);
            LaConFetch(`/api/editarDependente/${id}`,
                result => {
                    load();
                    setSending(false);
                    swal("Dependente editado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    const load = () => {
        setSending(true);
        LaConFetch(`/api/resultDependente/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setSending(false)
            }, result => setSending(false))

    };

    useEffect(() => {
        load();
    }, [])

    /* SetDados */
    const setDados = (dados) => {
        setPaif_paefi(dados?.insercao?.tipo_insercao_id ?? null);
        /* Dependente */
        setId(dados.id);
        setNm_pessoa(dados.nm_pessoa);
        setDs_sexo(dados.ds_sexo);
        setDt_nascimento(dados.dt_nascimento);
        setCd_parentesco(dados.cd_parentesco);
        setDs_parentesco(dados.ds_parentesco);
        if (dados.cd_parentesco === '11') {
            setDs_parentesco_outro(dados.ds_parentesco);
        }
        setSt_deficiente(dados.st_deficiente);
        setSt_documento(dados.setSt_documento);
        if (st_documento === 'Sim') {
            setDocumentacaoOff(false);
        }
        setDs_documentacao_civil(dados.ds_documentacao_civil);

    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDados(dados);
        setEditing(true)
        setOpen(true);
    };

    const modalAdd = () => {
        setNm_pessoa("");
        setDs_sexo("");
        setPaif_paefi(0);
        setDt_nascimento("");
        setCd_parentesco("");
        setDs_parentesco("");
        setDs_parentesco_outro("");
        setSt_deficiente("");
        setSt_documento("");
        setDs_documentacao_civil("");
        setDocumentacaoOff(true);
        setOpen(true);
    };

    const onCloseAskAvatar = (reload = false) => {
        setAskAvatar({ open: false, id: 0 });
        if (reload) {
            load();
        }
    }

    return <div className={classes.root}>
        <Box display="flex" justifyContent="flex-end" my={2} p={1}>
            <Button onClick={modalAdd} variant="contained" color="primary">
                Adicionar Dependente
            </Button>
        </Box>
        <MaterialTable
            isLoading={sending}
            columns={[
                { title: '#', field: 'imageUrl', render: rowData => <img src={rowData?.foto ? `${link}${rowData.foto}` : `${link}/images/default-avatar.png`} style={{ width: 40, borderRadius: '50%' }} /> },
                { title: 'Nome', field: 'nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Sexo', field: 'ds_sexo', headerStyle: { fontWeight: 'bold' } },
                { title: 'Parentesco', field: 'ds_parentesco', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (_, data) => modalEdit(data)
                },
                {
                    icon: 'account_box',
                    tooltip: 'Imagem',
                    onClick: (_, data) => setAskAvatar({ open: true, id: data.id })
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (_, data) => {
                        setAskMoveDependente({ open: true, id: data.id, nm_pessoa: data.nm_pessoa });
                    }
                }
            ]}
            components={{
                Pagination: PatchedPagination,
            }}
            options={{
                actionsColumnIndex: -1
            }}
            icons={_materialTableIcons}
            localization={_materialTableLocalization}
            title="Dependentes"
        />
        <Dialog
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullWidth maxWidth="lg"
        >
            <DialogContent>
                <Box mb={3}>
                    <InputLabel style={{ fontSize: 20 }}>Dados Pessoais</InputLabel>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            id="nm_pessoa"
                            label="Nome*"
                            variant="outlined"
                            value={nm_pessoa}
                            onChange={(e) => setNm_pessoa(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="paif_paefi"
                            label="PAIF/PAEFI"
                            variant="outlined"
                            value={paif_paefi}
                            onChange={(e) => setPaif_paefi(e.target.value)}
                            select
                        >
                            <MenuItem value={0}>Não está inscrito</MenuItem>
                            <MenuItem value={1}>PAIF</MenuItem>
                            <MenuItem value={2}>PAEFI</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <YesNoRadio
                            label="Sexo*"
                            value={ds_sexo}
                            onChange={setDs_sexo}
                            formProps={{ fullWidth: true }}
                            options={[
                                { label: 'Feminino', value: 'Feminino' },
                                { label: 'Masculino', value: 'Masculino' }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="dt_nascimento"
                            label="Data de Nascimento*"
                            type="date"
                            value={dt_nascimento}
                            onChange={(e) => setDt_nascimento(e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="cd_parentesco"
                            value={cd_parentesco}
                            label="Grau de Parentesco com Responsável*"
                            onChange={(e) => setParentesco(e.target.value)}
                            select
                        >
                            <MenuItem value="2">Cônjuge/companheiro</MenuItem>
                            <MenuItem value="3">FIlho(a)</MenuItem>
                            <MenuItem value="4">Neto(a)</MenuItem>
                            <MenuItem value="5">Bisneto(a)</MenuItem>
                            <MenuItem value="6">Pai/Mãe</MenuItem>
                            <MenuItem value="7">Sogro(a)</MenuItem>
                            <MenuItem value="8">Irmão/irmã</MenuItem>
                            <MenuItem value="9">Genro/Nora</MenuItem>
                            <MenuItem value="10">Não Parente</MenuItem>
                            <MenuItem value="11">Outro</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            disabled={outroGrauOff}
                            value={ds_parentesco_outro}
                            fullWidth
                            id="ds_parentesco_outro"
                            label="Caso outro, qual?"
                            variant="outlined"
                            onChange={(e) => setOutroGrau(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box mx={3}>
                    <InputLabel style={{ fontSize: 20 }}>Dados Gerais</InputLabel>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <YesNoRadio
                            label="Possui alguma deficiência?"
                            value={st_deficiente}
                            onChange={setSt_deficiente}
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <YesNoRadio
                            label="Precisa providenciar algum documento?"
                            value={st_documento}
                            onChange={setSt_documento}
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={documentacaoOff}
                            fullWidth
                            id="ds_documentacao_civil"
                            label="Caso Sim, quais?"
                            variant="outlined"
                            value={ds_documentacao_civil}
                            onChange={(e) => setDs_documentacao_civil(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                    <Button onClick={handleClose} style={{ marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                        Fechar
                    </Button>
                    <Button onClick={(editing === false) ? enviar : editar} variant="contained" color="primary">
                        Cadastrar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
        <DialogChangeAvatar
            open={askAvatar.open}
            onClose={onCloseAskAvatar}
            link={`/api/dependente/add-foto/${askAvatar.id}`}
        />
        <MoveDependente
            open={askMoveDependente.open}
            id={askMoveDependente.id}
            nm_pessoa={askMoveDependente.nm_pessoa}
            onClose={(reload) => {
                if (reload) {
                    load();
                }
                setAskMoveDependente({ open: false, id: 0, nm_pessoa: '' })
            }}
        />
    </div>
}