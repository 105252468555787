/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useContext } from "react";
import { Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { useHistory, withRouter } from "react-router";
import { ExitToApp } from "@material-ui/icons";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { SelfContext } from "provider/SelfContext";

function AdminNavbarLinks({...props}){
    const notification = (
      <div>
        <i className="fa fa-bell" />
        <b className="caret" />
        {false ? <span className="notification">4</span>:null}
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );

    const message = (
      <div>
        <i className="fa fa-comments" />
        <b className="caret" />
        {false ? <span className="notification">2</span>:null}
        <p className="hidden-lg hidden-md">Mensagem</p>
      </div>
    );
    
    const history = useHistory()

    const {updateToken} = useContext(SelfContext)

    const getName = () =>{
      if(localStorage.getItem('name').search(" ") === -1)
        return localStorage.getItem('name');
      else
        return localStorage.getItem('name').substring(0,localStorage.getItem('name').search(" "));
    }

    const setSair = () =>{
      updateToken('')
      history.push("/");
    };

    const setPerfil = () =>{
      history.push("/admin/perfil") ;
    };
    
  return (
    <div>
      <Nav pullRight style={{marginRight:0.5}}>
  
        <NavDropdown
          title={getName()}
          id="basic-nav-dropdown"  
        >
          <MenuItem onClick={setPerfil}><AccountBoxIcon style={{height:13}}/> Perfil</MenuItem>
          <MenuItem onClick={setSair}><ExitToApp style={{height:13}}/> Sair</MenuItem>
        </NavDropdown>
      </Nav>

      <Nav pullRight>
        <NavDropdown
          eventKey={2}
          title={message}
          noCaret
          id="basic-nav-dropdown"
        >
          <MenuItem eventKey={2.1}>Não há mensagens novas</MenuItem>
        </NavDropdown>
        <NavDropdown
          eventKey={2}
          title={notification}
          noCaret
          id="basic-nav-dropdown"
        >
          <MenuItem eventKey={2.1}>Não há notificações novas</MenuItem>
        </NavDropdown>
      </Nav>
    </div>
  );
}

export default withRouter(AdminNavbarLinks);
