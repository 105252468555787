import { Box, Button, ButtonGroup, Paper } from '@material-ui/core';
import PatchedPagination from 'components/PatchedPagination';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import { LaConFetch } from 'links/LaConFetch';
import MaterialTable from 'material-table';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function PaifPaefi({ ...props }) {
    /* Filters */
    const [filterType, setFilterType] = useState('nome');
    const [filterPaifPaefi, setFilterPaifPaefi] = useState('paif');
    const [searchType, setSearchType] = useState('responsavel');
    const [selectedRow, setSelectedRow] = useState(null);

    const tableRef = useRef(null);

    // Trigger table reload on state change
    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }, [filterPaifPaefi]);

    const history = useHistory()

    const selectRow = (data) => {
        history.push(`/admin/prontuario/${data.familia_id}`)
    }

    let actions = [
        {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (e, data) => { selectRow(data) }
        },
    ]
    let _type = localStorage.getItem('type');
    if (_type == 'REC') {
        actions = []
    }
    return <Paper>
        <MaterialTable
            tableRef={tableRef}
            columns={[
                { title: 'Cód. Família', field: 'familia_id', headerStyle: { fontWeight: 'bold' } },
                { title: 'Responsável', field: 'nomeResponsavel', headerStyle: { fontWeight: 'bold' } },
                { title: 'Apelido', field: 'apelido', headerStyle: { fontWeight: 'bold' } },
                { title: 'CPF', field: 'cpf', headerStyle: { fontWeight: 'bold' } },
                { title: 'RG', field: 'rg', headerStyle: { fontWeight: 'bold' } },
                { title: 'NIS', field: 'nis', headerStyle: { fontWeight: 'bold' } },
                { title: 'Endereço', field: 'endereco', headerStyle: { fontWeight: 'bold' } },
                { title: 'Último atendimento', field: 'ultimoAtendimento', headerStyle: { fontWeight: 'bold' } },
                { title: 'Qtd. Dependentes', field: 'n_dependentes', headerStyle: { fontWeight: 'bold' } },
                { title: 'Dependentes', field: 'dependentes', headerStyle: { fontWeight: 'bold' } },
            ]}
            data={query =>
                new Promise((resolve, reject) => {
                    let url = `/api/resultBusca?`
                    url += 'page_size=' + query.pageSize
                    url += '&page=' + (query.page + 1)
                    url += '&searchType=' + searchType
                    url += '&filterType=' + filterType
                    url += '&filterPaifPaefi=' + filterPaifPaefi
                    if (query.search) {
                        url += '&search=' + query.search
                    }
                    LaConFetch(url, (r) => {
                        if (r.success) {
                            resolve({
                                data: r.dados,
                                page: r.page - 1,
                                totalCount: r.total
                            });
                        } else {
                            reject()
                        }
                    }, () => {
                        reject()
                    })
                })}
            actions={actions}
            onRowClick={(event, rowData) => {
                selectRow(rowData)
            }}
            components={{
                Container: props => <div {...props} />,
                Pagination: PatchedPagination,
            }}
            options={{
                actionsColumnIndex: -1,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                })
            }}
            icons={_materialTableIcons}
            localization={_materialTableLocalization}
            title={<Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2rem'
            }}>
                <ButtonGroup>
                    <Button
                        variant={filterType === 'nome' ? 'contained' : 'outlined'}
                        onClick={() => setFilterType('nome')}
                        color="primary"
                    >
                        Nome
                    </Button>
                    <Button
                        variant={filterType === 'cpf' ? 'contained' : 'outlined'}
                        onClick={() => setFilterType('cpf')}
                        color="primary"
                    >
                        CPF
                    </Button>
                    <Button
                        variant={filterType === 'nis' ? 'contained' : 'outlined'}
                        onClick={() => setFilterType('nis')}
                        color="primary"
                    >
                        NIS
                    </Button>
                    <Button
                        variant={filterType === 'endereco' ? 'contained' : 'outlined'}
                        onClick={() => setFilterType('endereco')}
                        color="primary"
                    >
                        Endereço
                    </Button>
                    <Button
                        variant={filterType === 'dependente' ? 'contained' : 'outlined'}
                        onClick={() => setFilterType('dependente')}
                        color="primary"
                    >
                        Dependente
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        variant={filterPaifPaefi === 'paif' ? 'contained' : 'outlined'}
                        onClick={() => setFilterPaifPaefi('paif')}
                        color="primary"
                    >
                        PAIF
                    </Button>
                    <Button
                        variant={filterPaifPaefi === 'paefi' ? 'contained' : 'outlined'}
                        onClick={() => setFilterPaifPaefi('paefi')}
                        color="primary"
                    >
                        PAEFI
                    </Button>
                </ButtonGroup>
            </Box>}
        />
    </Paper>

}