import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, CircularProgress, Backdrop, Box } from '@material-ui/core';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import CrudView from 'components/Crud/CrudView';
import moment from 'moment';
import { tipoAcessoAtendimentos } from 'helpers/Fields';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

}));

export default function Atendimento({ familia_id }) {

    const classes = useStyles();

    /* Detalhes do Atendimento */
    const [cd_acesso_atendimento, setCd_acesso_atendimento] = useState("");
    const [ds_acesso_atendimento, setDs_acesso_atendimento] = useState("");
    const [ds_motivacao_atendimento, setDs_motivacao_atendimento] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");

    /* Registro do Atendimento */
    const [dt_atendimento, setDt_atendimento] = useState("");
    const [ds_atendimento, setDs_atendimento] = useState("");
    const [cd_atendimento, setCd_atendimento] = useState("");
    const [nm_tecnico, setNm_tecnico] = useState("");

    /* Valor mostrado em outro */
    const [ds_acesso_atendimento_outro, setDs_acesso_atendimento_outro] = useState("");
    const [ds_atendimento_outro, setDs_atendimento_outro] = useState("");

    /* Desabilitar Outro */
    const [outroAcessoAtendimentoOff, setOutroAcessoAtendimentoOff] = useState(true);
    const [outroAtendimentoOff, setOutroAtendimentoOff] = useState(true);

    /* Carregar Atendimento */
    const load = () => {
        setSending(true);
        LaConFetch(`/api/resultAtendimento/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDados(result.dados)
                }
                setSending(false)
            }, result => setSending(false))
    };

    useEffect(() => {
        load()
    }, [])

    const setDados = (dados) => {
        setCd_acesso_atendimento(dados.cd_acesso_atendimento);
        setDs_acesso_atendimento(dados.ds_acesso_atendimento);
        setDs_motivacao_atendimento(dados.ds_motivacao_atendimento);
        setDs_observacao(dados.ds_observacao);
        setDt_atendimento(dados.dt_atendimento)
        setCd_atendimento(dados.cd_atendimento);
        setNm_tecnico(dados.nm_tecnico);
        setAtendimento(dados.cd_atendimento);
        if (dados.cd_acesso_atendimento === '11') {
            setOutroAcessoAtendimento(dados.ds_acesso_atendimento);
        }
        if (dados.cd_atendimento === '8') {
            setOutroAtendimento(dados.ds_atendimento);
        }
    }

    /* Set CD_ACESSO_ATENDIMENTO e DS_ACESSO_ATENDIMENTO */
    const setAcessoAtendimento = (codigo) => {
        let selected = {};
        tipoAcessoAtendimentos.forEach(item => {
            if (item.id === codigo) {
                selected = item;
            }
        })
        setCd_acesso_atendimento(codigo);
        setOutroAcessoAtendimentoOff(!selected.other);
        setDs_acesso_atendimento(selected.title);
        setDs_acesso_atendimento_outro("");
    }

    const setOutroAcessoAtendimento = (texto) => {

        setDs_acesso_atendimento_outro(texto);
        setDs_acesso_atendimento(texto);
    }

    /* Set CD_ATENDIMENTO e DS_ATENDIMENTO */
    const setAtendimento = (codigo) => {

        setCd_atendimento(codigo);

        if (codigo === '1') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Atendimento socioassistencial individualizado');
        }
        else if (codigo === '2') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Atendimento em atividade coletiva de caráter continuado');
        }
        else if (codigo === '3') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Participação em atividade coletiva de caráter não continuado');
        }
        else if (codigo === '4') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Cadastramento / Atualização Cadastral');
        }
        else if (codigo === '5') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Acompanhamento de MSE');
        }
        else if (codigo === '6') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Solicitação / Concessão de Benefício Eventual');
        }
        else if (codigo === '7') {
            setDs_atendimento_outro(null);
            setOutroAtendimentoOff(true);
            setDs_atendimento('Visita Domiciliar');
        }

        else if (codigo === '8') {
            setOutroAtendimentoOff(false);
        }

    }

    const setOutroAtendimento = (texto) => {

        setDs_atendimento_outro(texto);
        setDs_atendimento(texto);
    }

    const [sending, setSending] = useState(false);

    /* Editar Atendimento */
    const editar = () => {

        if (dt_atendimento === "")
            swal("Data de atendimento é obrigatório!", "", "error")

        else if (nm_tecnico === "")
            swal("Nome do técnico é obrigatório!", "", "error")

        else {

            const formDados = {
                cd_acesso_atendimento,
                ds_acesso_atendimento,
                ds_motivacao_atendimento,
                ds_observacao,
                dt_atendimento,
                ds_atendimento,
                cd_atendimento,
                nm_tecnico,
            };

            setSending(true);

            LaConFetch(`/api/editarAtendimento/${familia_id}`,
                result => {
                    load();
                    setSending(false);
                    swal("Atualizado com sucesso!", "", "success")
                }, () => { }, formDados)

        }
    };

    return (<div className={classes.root}>
        <Backdrop style={{ zIndex: 99 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <div style={{ marginBottom: 10 }}>
            <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Registro de Atendimentos</InputLabel>
            <CrudView
                withRegister={true} canAdd={false}
                topBar={false} url={`/api/registro-atendimento`} title='Atendimento' noPaper
                canEdit={false} canDelete={false} canSee={true} endLink={`?familia_id=${familia_id}`}
                columns={[
                    { field: 'id', title: '#' },
                    { field: 'dt_atendimento', title: 'Data do Atendimento', render: (data) => moment(data.dt_atendimento, 'YYYY-MM-DD').format('DD/MM/YYYY') },
                    {
                        field: 'atendimento.nome', title: 'Atendimento', render: (data) => {
                            if (data.atendimento.outro) {
                                return data.ds_atendimento
                            } else {
                                return data.atendimento.nome
                            }
                        }
                    },
                    { field: 'nm_tecnico', title: 'Técnico' },
                ]}
                fields={[
                    {
                        field: 'dt_atendimento', label: 'Data do Atendimento', type: 'date', grid: { xs: 6 }
                    }, {
                        field: 'nm_tecnico', label: 'Técnico', type: 'text', grid: { xs: 6 }, default: localStorage.getItem('name')
                    }, {
                        field: 'atendimento_id', label: 'Atendimento', type: 'select', grid: { xs: 6 }, link: '/api/code/atendimento', nameKey: 'nome', dataKey: 'id'
                    }, {
                        field: 'ds_atendimento', label: 'Caso outro qual?', type: 'text', grid: { xs: 6 }
                    }, {
                        field: 'membros', label: 'Membros da Fámilia', type: 'multi-select', grid: { xs: 12 }, link: `/api/membros/familia/${familia_id}`, nameKey: 'nm_pessoa', dataKey: 'id'
                    }, {
                        field: 'creas', label: 'Unidades', type: 'multi-select', grid: { xs: 12 }, link: '/api/creas', nameKey: 'nome', dataKey: 'id'
                    }, {
                        field: 'familia_id', value: familia_id, type: 'default'
                    }
                ]}
            />
        </div>
        <div>
            <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Detalhes do Primeiro Atendimento</InputLabel>
            <TextField
                label="De que forma a família (ou membro da família) acessou a Unidade para o primeiro atendimento?"
                variant="outlined" fullWidth select margin="normal"
                value={cd_acesso_atendimento} onChange={(e) => setAcessoAtendimento(e.target.value)}
            >
                {tipoAcessoAtendimentos.map(item => <MenuItem value={item.id}>{item.title}</MenuItem>)}
            </TextField>
            <TextField
                label="Caso outro, descreva a situação"
                disabled={outroAcessoAtendimentoOff}
                margin="normal" fullWidth variant="outlined"
                value={ds_acesso_atendimento_outro} onChange={(e) => setOutroAcessoAtendimento(e.target.value)}
            />
            <TextField
                label="Quais as razões, demandas ou necessidades que motivaram este primeiro atendimento?"
                margin="normal" fullWidth variant="outlined"
                value={ds_motivacao_atendimento} onChange={(e) => setDs_motivacao_atendimento(e.target.value)}
                multiline rows={4}
            />
            <TextField
                label="Observação"
                margin="normal" variant="outlined" fullWidth
                value={ds_observacao} onChange={(e) => setDs_observacao(e.target.value)}
                multiline rows={4}
            />
        </div>
        <div style={{ marginBottom: 10, display: 'flex', justifyContent: "end" }}>
            <Button onClick={editar} variant="contained" color="primary">
                Editar
            </Button>
        </div>
    </div>)
}