import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop } from '@material-ui/core';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import ModaAnexo from 'components/Crud/ModaAnexo';
import AreaEstudoSocial from 'components/FormInputs/AreaEstudoSocial';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    input: {
        '&::placeholder': {
            fontStyle: 'italic',
        },
    },
}));

export default function EstudoSocial({ familia_id }) {

    const classes = useStyles();

    const [ds_demanda_familiar, setDs_demanda_familiar] = useState("");
    const [ds_potenciais, setDs_potenciais] = useState("");
    const [ds_vulnerabilidades, setDs_vulnerabilidades] = useState("");
    const [ds_vulnerabilidades_reside, setDs_vulnerabilidades_reside] = useState("");
    const [ds_concessao_beneficio, setDs_concessao_beneficio] = useState("");
    const [ds_paif, setDs_paif] = useState("");
    const [ds_paefi, setDs_paefi] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");

    const [historico, setHistorico] = useState({})

    const [sending, setSending] = useState(false);

    /* Carregar Estudo Social */
    const load = () => {
        setSending(true);
        LaConFetch(`/api/resultEstudoSocial/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setHistorico(result.dados)
                }
                setSending(false)
            }, result => setSending(false))
    };

    useEffect(() => {
        load();
    }, [])

    /* Cadastrar EstudoSocial*/
    const enviar = () => {

        const formDados = {
            familia_id,
            ds_demanda_familiar,
            ds_potenciais,
            ds_vulnerabilidades,
            ds_vulnerabilidades_reside,
            ds_concessao_beneficio,
            ds_paif,
            ds_paefi,
            ds_observacao
        };

        setSending(true);
        LaConFetch(`/api/cadastrarEstudoSocial`, result => {
            load();
            setDs_demanda_familiar('')
            setDs_potenciais('')
            setDs_vulnerabilidades('')
            setDs_vulnerabilidades_reside('')
            setDs_concessao_beneficio('')
            setDs_paif('')
            setDs_paefi('')
            setDs_observacao('')
            setSending(false);
            swal("Enviado com sucesso!", "", "success")
        }, () => { }, formDados)

    };

    return (<div className={classes.root}>
        <Backdrop style={{ zIndex: 99 }} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>

        <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Estudo Social</InputLabel>
        <AreaEstudoSocial
            historico={historico.ds_demanda_familiar}
            novo={ds_demanda_familiar} onChange={setDs_demanda_familiar}
            title='Demanda Familiar'
        />

        <AreaEstudoSocial
            historico={historico.ds_potenciais}
            novo={ds_potenciais} onChange={setDs_potenciais}
            title='Identificações Potenciais da Família quanto:'
            description={<>
                - Considerar:<br></br>
                Relações significativas (vizinhanças, família
                ampliada, amigos, organizações religiosas, associações
                comunitárias, grupos culturais, outros);<br></br>
                Envolvimento de membros da família em ações em que objetivam a superação
                de vulnerabilidade; <br></br>
                Resgate ou reconhecimento de potenciais
                adquiridos (habilidades manuais, experiências profissionais,
                capacidades desenvolvidas, dentre outros); <br></br>
                Relação da família
                com a rede de serviços existentes no território (saúde,
                educação, assistência social, cultura, esporte, lazer,
                outros) e Nível de participação em atividades ofertadas.
            </>}
        />

        <AreaEstudoSocial
            historico={historico.ds_vulnerabilidades}
            novo={ds_vulnerabilidades} onChange={setDs_vulnerabilidades}
            title='Identificações das vulnerabilidades da Família quanto:'
            description={<>
                - Considerar:<br></br>
                Condições socioenômicas;<br></br>
                Histórico de perdas ou interrupção de relações;<br></br>
                Histórico de violação de direitos;<br></br>
                Acesso ( ou necessidade de) ao Sistema de Garantia de Direitos.
                Se já acessa, quais medidas/acompanhamentos realizados; se não,
                indicar quais os encaminhamentos realizados;
            </>}
        />

        <AreaEstudoSocial
            historico={historico.ds_vulnerabilidades_reside}
            novo={ds_vulnerabilidades_reside} onChange={setDs_vulnerabilidades_reside}
            title='Identificações das vulnerabilidades/potencialidades do território onde a família reside:'
            description={<>
                - Considerar:<br></br>
                Condições socioenômicas;<br></br>
                Características e especialidades do território que influenciam /e/ou determinam
                as situações de vulnerabilidade vivenciadas pela família;<br></br>
                Características e especialidades do território que podem
                minimizar as situações de vulnerabilidade vivenciadas pelas famílias;
            </>}
        />

        <AreaEstudoSocial
            historico={historico.ds_concessao_beneficio}
            novo={ds_concessao_beneficio} onChange={setDs_concessao_beneficio}
            title='Indicações da Família para:'
            description='- Concessão de Benefício Eventual:'
        />

        <AreaEstudoSocial
            historico={historico.ds_paif}
            novo={ds_paif} onChange={setDs_paif}
            title="- Inserção no PAIF com açoes de:"
        />

        <AreaEstudoSocial
            historico={historico.ds_paefi}
            novo={ds_paefi} onChange={setDs_paefi}
            title="- Inserção no PAEFI com açoes de:"
        />
        <br></br><br></br>
        <ModaAnexo url={'estudo-social'} endLink={`?familia_id=${familia_id}`} />
        <br></br><br></br>
        <AreaEstudoSocial
            historico={historico.ds_observacao}
            novo={ds_observacao} onChange={setDs_observacao}
            title='Observações:'
        />
        <br></br><br></br>
        <Button onClick={enviar} style={{ float: 'right' }} variant="contained" color="primary">
            Enviar
        </Button>
        <br></br><br></br>
    </div>
    )
}