import React, { useState , useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';
import ModaAnexo from 'components/Crud/ModaAnexo';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  
}));
   
export default function Convivencia({familia_id}) {

  const classes = useStyles();
  
  // Fim QT_ANOS
  const [qt_anos_estado, setQt_anos_estado] = useState("");
  const [qt_anos_municipio, setQt_anos_municipio] = useState("");
  const [qt_anos_bairro, setQt_anos_bairro] = useState("");
  // Habilita e Desabilita campo no check
  const [anos_estado, setAnos_estado] = useState("");
  const [anos_municipio, setAnos_municipio] = useState("");
  const [anos_bairro, setAnos_bairro] = useState("");
  // Check ao carregar
  const [estadoChecked, setEstadoChecked] = useState(false);
  const [municipioChecked, setMunicipioChecked] = useState(false);
  const [bairroChecked, setBairroChecked] = useState(false);
  // Fim QT_ANOS
  const [st_ameacas, setSt_ameacas] = useState("");
  const [st_parentes_apoio, setSt_parentes_apoio] = useState("");
  const [st_vizinhos_apoio, setSt_vizinhos_apoio] = useState("");
  const [st_religiosos_apoio, setSt_religiosos_apoio] = useState("");
  const [st_movimentos_apoio, setSt_movimentos_apoio] = useState("");
  const [st_crianca_lazer, setSt_crianca_lazer] = useState("");
  const [st_idoso_lazer, setSt_idoso_lazer] = useState("");
  const [st_dependentes_sozinhos, setSt_dependentes_sozinhos] = useState("");
  const [st_relacoes_conjugais, setSt_relacoes_conjugais] = useState("");
  const [st_relacoes_pais, setSt_relacoes_pais] = useState("");
  const [st_relacoes_irmaos, setSt_relacoes_irmaos] = useState("");
  const [st_relacoes_individuos, setSt_relacoes_individuos] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  const checkEstado = (item) => {

    if (qt_anos_estado === 'Sempre morou'){
      setQt_anos_estado("");
    }
    else{setQt_anos_estado("Sempre morou");}

  }

  const checkMunicipio = (item) => {

    if (qt_anos_municipio === 'Sempre morou'){
      setQt_anos_municipio("");
    }
    else{setQt_anos_municipio("Sempre morou");}

  }

  const checkBairro = (item) => {

    if (qt_anos_bairro === 'Sempre morou'){
      setQt_anos_bairro("");
    }
    else{setQt_anos_bairro("Sempre morou");}

  }

  /* Desabilitar Campos */
  const [estadoOff, setEstadoOff] = useState(false);
  const [municipioOff, setMunicipioOff] = useState(false);
  const [bairroOff, setBairroOff] = useState(false);

  useEffect(() => {

    load();
   
  }, [])

  useEffect(() => {

    if(qt_anos_estado !== 'Sempre morou'){
      setEstadoChecked(false);
      setAnos_estado(qt_anos_estado);
      setEstadoOff(false);
    } else{
      setEstadoChecked(true);
      setAnos_estado("");
      setEstadoOff(true);
    }

  }, [qt_anos_estado])

  useEffect(() => {

    if(qt_anos_municipio !== 'Sempre morou'){
      setMunicipioChecked(false);
      setAnos_municipio(qt_anos_municipio);
      setMunicipioOff(false);
    } else{
      setMunicipioChecked(true);
      setAnos_municipio("");
      setMunicipioOff(true);
    }

  }, [qt_anos_municipio])

  useEffect(() => {

    if(qt_anos_bairro !== 'Sempre morou'){
      setBairroChecked(false);
      setAnos_bairro(qt_anos_bairro);
      setBairroOff(false);
    } else{
      setBairroChecked(true);
      setAnos_bairro("");
      setBairroOff(true);
    }

  }, [qt_anos_bairro])

  /* Carregar Convivencia */
  const load = () => {

    // setSending(true);

    // fetch(`${link}/api/resultConvivencia/${familia_id}`,{
    //     headers:{
    //         Accept:'application/json'
    //     }
    //     })
    //     .then(res => res.json())
    //     .then(result => {if(result.dados !== null)(setDados(result.dados))})
    //     .then(result => setSending(false))

    setSending(true);
    LaConFetch(`/api/resultConvivencia/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDados(result.dados)
        }
        setSending(false)
    },result => setSending(false))
        
    };

  /* SetDados */
  const setDados = (dados) => {

    /* Convivencia */
    setQt_anos_estado(dados.qt_anos_estado);
    setQt_anos_municipio(dados.qt_anos_municipio);
    setQt_anos_bairro(dados.qt_anos_bairro);
    setSt_ameacas(dados.st_ameacas);
    setSt_parentes_apoio(dados.st_parentes_apoio);
    setSt_vizinhos_apoio(dados.st_vizinhos_apoio);
    setSt_religiosos_apoio(dados.st_religiosos_apoio);
    setSt_movimentos_apoio(dados.st_movimentos_apoio);
    setSt_crianca_lazer(dados.st_crianca_lazer);
    setSt_idoso_lazer(dados.st_idoso_lazer);
    setSt_dependentes_sozinhos(dados.st_dependentes_sozinhos);
    setSt_relacoes_conjugais(dados.st_relacoes_conjugais);
    setSt_relacoes_pais(dados.st_relacoes_pais);
    setSt_relacoes_irmaos(dados.st_relacoes_irmaos);
    setSt_relacoes_individuos(dados.st_relacoes_individuos);
    setDs_observacao(dados.ds_observacao);

  };

  const [sending, setSending] = useState(false);

  /* Cadastrar Convivencia*/
  const enviar = () => {

    const formDados = {
      familia_id,
      qt_anos_estado, 
      qt_anos_municipio,
      qt_anos_bairro, 
      st_ameacas,
      st_parentes_apoio,
      st_vizinhos_apoio,
      st_religiosos_apoio,
      st_movimentos_apoio,
      st_crianca_lazer,
      st_idoso_lazer,
      st_dependentes_sozinhos,
      st_relacoes_conjugais,
      st_relacoes_pais,
      st_relacoes_irmaos,
      st_relacoes_individuos,
      ds_observacao};

    // setSending(true);

    // fetch(`${link}/api/cadastrarConvivencia`,{
    //     method:'POST',
    //     body:JSON.stringify(formDados),
    //     headers:{
    //         "Content-Type":'application/json',
    //         Accept:'application/json'
    //     }
    // })
    // .then(res => res.json())
    // .then(result => {
    //     setSending(false)
    // })
    // .then(swal("Salvo com sucesso!","", "success"));

    setSending(true);
    LaConFetch(`/api/cadastrarConvivencia`,
        result => {
          load();
          setSending(false);
          swal("Enviado com sucesso!","", "success")
        },()=>{},formDados)

  };

  return (
    
    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Convivência</InputLabel>
      
      <TextField
        disabled={estadoOff}
        margin="normal"
        style={{ marginRight: '2%', width: '33%' }}  
        id="qt_anos_estado"
        label="Quantidade de anos morando no mesmo estado"
        variant="outlined"
        value={anos_estado} 
        onChange={(e) => setQt_anos_estado(e.target.value)}
      />

      <FormControlLabel
        style={{ marginTop: 20, width: '63%' }}
        control={<Checkbox checked={estadoChecked} value={"Sempre morou"} onChange={(e) => checkEstado(e.target.value)} name="check_estado"/>}
        label="A família sempre morou no estado"
      />

      <TextField  
        disabled={municipioOff}
        margin="normal"
        style={{  marginRight: '2%', width: '33%' }}      
        id="qt_anos_municipio"
        label="Quantidade de anos morando no mesmo município"
        variant="outlined"
        value={anos_municipio} 
        onChange={(e) => setQt_anos_municipio(e.target.value)}
      />

      <FormControlLabel
        style={{ marginTop: 20, width: '63%' }}
        control={<Checkbox checked={municipioChecked} value={"Sempre morou"} onChange={(e) => checkMunicipio(e.target.value)} name="check_municipio"/>}
        label="A família sempre morou no município"
      />

      <TextField  
        disabled={bairroOff}
        margin="normal"
        style={{ marginRight: '2%', width: '33%' }}      
        id="qt_anos_bairro"
        label="Quantidade de anos morando no mesmo bairro"
        variant="outlined"
        value={anos_bairro} 
        onChange={(e) => setQt_anos_bairro(e.target.value)}
      />

      <FormControlLabel
        style={{ marginTop: 20, width: '63%' }}
        control={<Checkbox checked={bairroChecked} value={"Sempre morou"} onChange={(e) => checkBairro(e.target.value)} name="check_bairro"/>}
        label="A família sempre morou no bairro"
      />

      <FormControl style={{ marginTop: 15, width: '100%' }} component="fieldset">
        <FormLabel component="legend">A família, ou algum de seus membros, é vítima de ameaças ou de discriminação na comunidade onde reside?</FormLabel>
        <RadioGroup row value={st_ameacas} aria-label="st_ameacas" name="st_ameacas" onChange={(e) => setSt_ameacas(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15, width: '100%' }}  component="fieldset">
        <FormLabel component="legend">A família possui parentes que residam próximo ao seu local de moradia e que constituam rede de apoio e solidariedade?</FormLabel>
        <RadioGroup row value={st_parentes_apoio} aria-label="st_parentes_apoio" name="st_parentes_apoio" onChange={(e) => setSt_parentes_apoio(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15, width: '100%' }}  component="fieldset">
        <FormLabel component="legend">A família possui vizinhos que constituam rede de apoio e solidariedade?</FormLabel>
        <RadioGroup row value={st_vizinhos_apoio} aria-label="st_vizinhos_apoio" name="st_vizinhos_apoio" onChange={(e) => setSt_vizinhos_apoio(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15,  width: '100%' }}  component="fieldset">
        <FormLabel component="legend">A família, ou algum de seus membros, participa de grupos religiosos, comunitários ou outros grupos/instituições que constituam rede de apoio e solidariedade?</FormLabel>
        <RadioGroup row value={st_religiosos_apoio} aria-label="st_religiosos_apoio" name="st_religiosos_apoio" onChange={(e) => setSt_religiosos_apoio(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15, width: '100%' }}  component="fieldset">
        <FormLabel component="legend">A família, ou algum de seus membros, participa de movimentos sociais, sindicatos, organizações comunitárias, Conselhos ou quaisquer outras ações ou instituições voltadas para organização política e defesa de interesses coletivos?</FormLabel>
        <RadioGroup row value={st_movimentos_apoio} aria-label="st_movimentos_apoio" name="st_movimentos_apoio" onChange={(e) => setSt_movimentos_apoio(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15, width: '100%' }}  component="fieldset">
        <FormLabel component="legend">Existe alguma criança ou adolescente do grupo familiar que não tem acesso a atividades de lazer, recreação e convívio social?</FormLabel>
        <RadioGroup row value={st_crianca_lazer} aria-label="st_crianca_lazer" name="st_crianca_lazer" onChange={(e) => setSt_crianca_lazer(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15,  width: '100%' }}  component="fieldset">
        <FormLabel component="legend">Existe algum idoso do grupo familiar que não tem acesso a atividades de lazer, recreação e convívio social?</FormLabel>
        <RadioGroup row value={st_idoso_lazer} aria-label="st_idoso_lazer" name="st_idoso_lazer" onChange={(e) => setSt_idoso_lazer(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15,  width: '100%' }}  component="fieldset">
        <FormLabel component="legend">Há pessoas dependentes (crianças, idosos dependentes ou pessoas com deficiência) que permanecem períodos do dia em casa sem a companhia de um adulto?</FormLabel>
        <RadioGroup row value={st_dependentes_sozinhos} aria-label="st_dependentes_sozinhos" name="st_dependentes_sozinhos" onChange={(e) => setSt_dependentes_sozinhos(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <InputLabel style={{ marginTop: 20, fontSize: 20  }}>Relações de Convivência Intrafamiliares</InputLabel>

      <FormControl style={{ marginTop: 15,  width: '100%' }}  component="fieldset">
        <FormLabel component="legend">Percepção/Avaliação do técnico sobre as relações conjugais na família, se for o caso</FormLabel>
        <RadioGroup row value={st_relacoes_conjugais} aria-label="st_relacoes_conjugais" name="st_relacoes_conjugais" onChange={(e) => setSt_relacoes_conjugais(e.target.value)}>
          <FormControlLabel value="Conflituoso, com violência" control={<Radio color="primary"/>} label="Conflituoso, com violência" />
          <FormControlLabel value="Conflituoso, sem violência" control={<Radio color="primary"/>} label="Conflituoso, sem violência" />   
          <FormControlLabel value="Sem conflitos relevantes" control={<Radio color="primary"/>} label="Sem conflitos relevantes" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15,  width: '100%' }}  component="fieldset">
        <FormLabel component="legend">Percepção/Avaliação do técnico sobre as relações entre pais/responsáveis e os filhos inclusive entre o padrasto ou madrasta com o(s) enteados(as), se for o caso</FormLabel>
        <RadioGroup row value={st_relacoes_pais} aria-label="st_relacoes_pais" name="st_relacoes_pais" onChange={(e) => setSt_relacoes_pais(e.target.value)}>
          <FormControlLabel value="Conflituoso, com violência" control={<Radio color="primary"/>} label="Conflituoso, com violência" />
          <FormControlLabel value="Conflituoso, sem violência" control={<Radio color="primary"/>} label="Conflituoso, sem violência" />   
          <FormControlLabel value="Sem conflitos relevantes" control={<Radio color="primary"/>} label="Sem conflitos relevantes" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15,  width: '100%' }}  component="fieldset">
        <FormLabel component="legend">Percepção/Avaliação do técnico sobre as relações entre os irmãos, se for o caso</FormLabel>
        <RadioGroup row value={st_relacoes_irmaos} aria-label="st_relacoes_irmaos" name="st_relacoes_irmaos" onChange={(e) => setSt_relacoes_irmaos(e.target.value)}>
          <FormControlLabel value="Conflituoso, com violência" control={<Radio color="primary"/>} label="Conflituoso, com violência" />
          <FormControlLabel value="Conflituoso, sem violência" control={<Radio color="primary"/>} label="Conflituoso, sem violência" />   
          <FormControlLabel value="Sem conflitos relevantes" control={<Radio color="primary"/>} label="Sem conflitos relevantes" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15,  width: '100%' }}  component="fieldset">
        <FormLabel component="legend">Indique se há relações conflituosas envolvendo outros indivíduos que residam no domicílio</FormLabel>
        <RadioGroup row value={st_relacoes_individuos} aria-label="st_relacoes_individuos" name="st_relacoes_individuos" onChange={(e) => setSt_relacoes_individuos(e.target.value)}>
          <FormControlLabel value="Conflituoso, com violência" control={<Radio color="primary"/>} label="Conflituoso, com violência" />
          <FormControlLabel value="Conflituoso, sem violência" control={<Radio color="primary"/>} label="Conflituoso, sem violência" />   
          <FormControlLabel value="Sem conflitos relevantes" control={<Radio color="primary"/>} label="Sem conflitos relevantes" />   
        </RadioGroup>
      </FormControl>

      <ModaAnexo url={'convivencia'} endLink={`?familia_id=${familia_id}`}/>

      <TextField   
        margin="normal"
        style={{ width: '100%' }}     
        id="ds_observacao"
        label="Observação"
        variant="outlined"
        value={ds_observacao} 
        onChange={(e) => setDs_observacao(e.target.value)}
        multiline='true'
        rows='4'
      />

      <br></br><br></br>
      
      <Button onClick={enviar} style={{ float: 'right' }}  variant="contained" color="primary">
        Enviar
      </Button>

      <br></br><br></br>

    </div>
)}