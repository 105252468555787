import CrudView from 'components/Crud/CrudView';
import moment from 'moment';
import React from 'react';

export default function Participacao({id,...props}){
    return <div>
        <CrudView
            url={`/api/oficina/participante/${id}`} title='Convidados'
            columns={[
                {
                    field:'nm_pessoa',title:'Nome',
                },
            ]}
            canEdit={false} topBar={false} canAdd={false} canDelete={false}
        />
    </div>
}