import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/pt-br';
import { Backdrop, Button, CircularProgress, Grid, Modal } from '@material-ui/core';
import NovaAgenda from './NovaAgenda';
import { LaConFetch } from 'links/LaConFetch';
import EditAgenda from './EditAgenda';

const localizer = momentLocalizer(moment)

export default function Agenda({familia_id,...props}){
    const [events,setEvents] = useState([{
        title: 'Evento',
        start: moment().toDate(),
        end: moment().add(10,'minute').toDate(),
      }])
    const [addSlot,setAddSlot] = useState(false)
    const [toAdd,setToAdd] = useState({})
    const [showSlot,setShowSlot] = useState(false)
    const [toShow,setToShow] = useState({})
    const [loading,setLoading] = useState(false)

    const loadData=()=>{
        setLoading(true)
        LaConFetch(`/api/agenda/familia/${familia_id}`,(r)=>{
            if(r.success){
                let hold = r.items.map(item=>{
                    let finalItem = {
                        'title': item.titulo,
                        'start': moment(item.start,'YYYY-MM-DD HH:mm').toDate(),
                        'end': moment(item.end,'YYYY-MM-DD HH:mm').toDate(),
                        'rest': item,
                    }
                    return finalItem
                })
                setEvents(hold)
            }
            setLoading(false)
        },()=>{})
    }

    useEffect(()=>{
        loadData()
    },[familia_id])

    const handleColors=(slot)=>{
        
    }
    const handleEvent=(slot)=>{
        setToShow(slot.rest)
        setShowSlot(true)
    }
    const handleSlot=(slot)=>{
        setToAdd(slot)
        setAddSlot(true)
    }
    const onCloseModal=(reload=false)=>{
        setShowSlot(false)
        setAddSlot(false)
        setToShow({})
        setToAdd({})
        if(reload){
            loadData()
        }
    }
    return <div style={{padding:20}}>
        <Backdrop style={{zIndex:99}} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
                <div style={{width:'100%',textAlign:'right'}}>
                    <Button color='primary' variant="contained" onClick={()=>setAddSlot(true)}>
                        Adicionar Agendamento
                    </Button>
                </div>
            </Grid>
        </Grid>
        <div style={{height:'69vh',marginTop:20}}>
            <Calendar
                eventPropGetter={handleColors}
                events={events}
                views={{month:true,week:true,day:true}}
                localizer={localizer}
                defaultView='day'
                culture='pt-br'
                step={5}
                min={new Date(2000, 1, 1, 6)}
                messages={{
                    next: 'Próximo',
                    previous: 'Anterior',
                    today: 'Hoje',
                    month: 'Mês',
                    day: 'Dia',
                    week: 'Semana',
                    showMore: (number)=>`(${number}) Exibir mais...`,
                }}
                defaultDate={new Date()}
                onSelectEvent={event => handleEvent(event)}
                onSelectSlot={(slotInfo) => handleSlot(slotInfo)} selectable
            />
        </div>
        <Modal open={addSlot} onClose={()=>onCloseModal(false)}>
            <div className='container' style={{marginTop:50}}>
                <NovaAgenda slot={toAdd} familia_id={familia_id} onClose={onCloseModal}/>
            </div>
        </Modal>
        <Modal open={showSlot} onClose={()=>onCloseModal(false)}>
            <div className='container' style={{marginTop:50}}>
                <EditAgenda slot={toShow} onClose={onCloseModal}/>
            </div>
        </Modal>
    </div>
}