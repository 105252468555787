import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EventIcon from '@material-ui/icons/Event';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ThumbUp from '@material-ui/icons/ThumbUp';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import NoteIcon from '@material-ui/icons/Note';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HistoryIcon from '@material-ui/icons/History';
import PrintIcon from '@material-ui/icons/Print';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SolicitaBeneficio from './ModAtendimento/SolicitaBeneficio';
import Encaminhamento from './ModAtendimento/Encaminhamento';
import Atendimento from './TabProntuario/Atendimento';
import Responsavel from './TabProntuario/Responsavel';
import Dependente from './TabProntuario/Dependente';
import CondHabitacionais from './TabProntuario/CondHabitacionais';
import CondEducacionais from './TabProntuario/CondEducacionais';
import CondTrabalho from './TabProntuario/CondTrabalho';
import Deficiencia from './TabProntuario/Deficiencia';
import Cuidados from './TabProntuario/Cuidados';
import Rendimentos from './TabProntuario/Rendimentos';
import Beneficios from './TabProntuario/Beneficios';
import Convivencia from './TabProntuario/Convivencia';
import Participacao from './TabProntuario/Participacao';
import Violencia from './TabProntuario/Violencia';
import MedidaSocioEduc from './TabProntuario/MedidaSocioEduc';
import Acolhimento from './TabProntuario/Acolhimento';
import EstudoSocial from './TabProntuario/EstudoSocial';
import Gestacao from './TabProntuario/Gestacao';
import DescumprimentoEducacao from './TabProntuario/DescumprimentoEducacao';
import DescumprimentoSaude from './TabProntuario/DescumprimentoSaude';
import CreasAcompanhamento from './TabProntuario/CreasAcompanhamento';
import CreasViolencia from './TabProntuario/CreasViolencia';
import Familia from './TabProntuario/Familia';
import { TextField, ButtonGroup, MenuItem, Badge } from '@material-ui/core';
import { LaConFetch } from 'links/LaConFetch';
import Agenda from './Agenda/Agenda';
import Historico from './Historico';
import Carteira from './Carteira';
import { _MaterialTableLocalization } from 'links/TableConstant';
import ParticipacaoScfv from './TabProntuario/ParticipacaoScfv';
import ModaAnexo from 'components/Crud/ModaAnexo';
import CardResponsavel from './TabProntuario/CardResponsavel';
import FormularioExtra from './TabProntuario/FormularioExtra';
import PatchedPagination from 'components/PatchedPagination';
import { _materialTableIcons } from 'constants/MaterialConstant';
import { useParams } from 'react-router-dom';
import CardDependente from './TabProntuario/CardDependente';
import ViolenciaGroup from './TabProntuario/ViolenciaGroup';
import ParticipacaoGroup from './TabProntuario/ParticipacaoGroup';
import SaudeGroup from './TabProntuario/SaudeGroup';
import EducacaoGroup from './TabProntuario/EducacaoGroup';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index, current) {
    return {
        nomeAtivo: index === current,
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
    },

    Tabs: {
        backgroundColor: 'green',
        color: 'white',
    },
}));

/* TAG CRIADA */
function TabFixed({ nome = "", nomeAtivo = false, separated = false, filtered = false, icon = null, ...props }) {
    return <Tooltip title={<div style={{ fontSize: 12, margin: 5 }}>
        {nome} {filtered ? '(Filtrado)' : ''}
    </div>}>
        <Tab
            label={nomeAtivo ? nome : ''}
            style={{ minWidth: nomeAtivo ? 150 : 0, borderRight: separated ? 'solid' : 'none', borderRightWidth: 2, borderRightColor: 'white' }}
            icon={<Badge color="secondary" variant="dot" invisible={!filtered}>{icon}</Badge>}
            {...props}
        />
    </Tooltip>
};

export default function Prontuario() {
    /* Oculta as Tabs */
    const [off, setOff] = useState(true);
    const [numtab, setNumTab] = useState(1);
    /* ID da família */
    const [familia_id, setFamilia_id] = useState(null);
    const [responsavel, setResponsavel] = useState(null);
    const [dependentes, setDependentes] = useState([]);
    const [value, setValue] = useState(0);
    /* Filters */
    const [filterType, setFilterType] = useState('nome');
    const [searchType, setSearchType] = useState('responsavel');
    const [selectedRow, setSelectedRow] = useState(null);
    const [carteira, setCarteira] = useState(false)
    const [sending, setSending] = useState(false);
    const [pessoa, setPessoa] = useState("all")
    const classes = useStyles();
    const { id } = useParams()
    let _type = localStorage.getItem('type');

    useEffect(() => {
        if (id) {
            setPessoa('all')
            iniciaProntuario(id)
            loadResponsavel(id)
            loadDependentes(id)
            setValue(1)
        }
    }, [id])

    const changeMain = (num = null) => {
        if (num && numtab != num) {
            setNumTab(num)
        }
    }

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    const iniciaProntuario = (familiaId) => {
        setFamilia_id(familiaId)
        setPessoa('all')
        loadResponsavel(familiaId)
        loadDependentes(familiaId)
        setNumTab(1)
        setOff(false)
    }

    const selectRow = (data) => {
        if (_type == 'REC') return
        iniciaProntuario(data.familia_id);
        setSelectedRow(data.tableData.id);
        setValue(1)
    }

    const loadDependentes = (familiaId) => {
        setSending(true);
        LaConFetch(`/api/resultDependente/${familiaId}`,
            result => {
                if (result.dados !== null) {
                    setDependentes(result.dados)
                }
                setSending(false)
            }, () => setSending(false))
    }

    const loadResponsavel = (familiaId) => {
        setSending(true);
        LaConFetch(`/api/resultResponsavel/${familiaId}`,
            result => {
                if (result.dados !== null) {
                    setResponsavel(result.dados[0])
                    setSending(false);
                }
            }, () => {
            })
    }

    let actions = [
        {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (e, data) => { selectRow(data) }
        },
    ]
    if (_type == 'REC') {
        actions = []
    }

    return <div className={classes.root}>
        {(!off) ? <Card style={{ margin: '7px', padding: '10px 12px' }}>
            <Grid container>
                <Grid item lg={4}>
                    {pessoa === 'all' || pessoa === 'responsavel' ? <CardResponsavel
                        responsavel={responsavel}
                        reloadResponsavel={loadResponsavel}
                    /> : <CardDependente
                        dependente={dependentes.find(d => d.id == pessoa)}
                        reloadDependete={loadDependentes}
                        responsavel={responsavel}
                    />}
                </Grid>
                <Grid item lg={8}>
                    <Grid container>
                        <Grid item xs={12} lg={3}>
                            <TextField
                                select
                                label="Filtrar Por"
                                fullWidth
                                value={pessoa}
                                variant='outlined'
                                onChange={(e) => setPessoa(e.target.value)}
                            >
                                <MenuItem value="all">Toda a Família</MenuItem>
                                {responsavel ? <MenuItem value="responsavel">{responsavel?.pessoa?.nm_pessoa} (Responsável)</MenuItem> : null}
                                {dependentes.map((d, i) => <MenuItem key={i} value={d.id}>{d.nm_pessoa}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button variant={(numtab == 1) ? "contained" : "outlined"} onClick={() => changeMain(1)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<ReceiptIcon />}>
                                Prontuário
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button variant={(numtab == 2) ? "contained" : "outlined"} onClick={() => changeMain(2)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<FavoriteIcon />}>
                                <Badge color="secondary" variant="dot" invisible={pessoa == 'all'}>Benefícios</Badge>
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button variant={(numtab == 3) ? "contained" : "outlined"} onClick={() => changeMain(3)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<ArrowForwardIcon />}>
                                <Badge color="secondary" variant="dot" invisible={pessoa == 'all'}>Encaminhamentos</Badge>
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button variant={(numtab == 4) ? "contained" : "outlined"} onClick={() => changeMain(4)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<EventIcon />}>
                                Agendamentos
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button variant={(numtab == 5) ? "contained" : "outlined"} onClick={() => changeMain(5)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<HistoryIcon />}>
                                Histórico
                            </Button>
                        </Grid>
                        {/* <Grid item xs={12} lg={3}>
                            <Button variant={(numtab == 6) ? "contained" : "outlined"} onClick={() => changeMain(6)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<PrintIcon />}>
                                Imprimir
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} lg={3}>
                            <Button variant={(numtab == 7) ? "contained" : "outlined"} onClick={() => setCarteira(true)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<RecentActorsIcon />}>
                                <Badge color="secondary" variant="dot" invisible={pessoa == 'all'}>Carteirinha</Badge>
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <ModaAnexo
                                bigButton url="familia-anexo" endLink={`?familia_id=${familia_id}`}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card> : null}
        {numtab == 1 ? <Card style={{ margin: '7px' }}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="on"
                    aria-label="scrollable prevent tabs example"
                    className={classes.Tabs}
                >
                    <TabFixed nome="Busca" icon={<SearchIcon />} aria-label="SearchIcon" {...a11yProps(0, value)} />
                    {off ? null : <TabFixed nome="Atendimento" icon={<ContactPhoneIcon />} aria-label="contactPhoneIcon" {...a11yProps(1, value)} />}
                    {off ? null : <TabFixed nome="Família" icon={<SupervisedUserCircleIcon />} aria-label="supervisedUserCircle" {...a11yProps(2, value)} />}
                    {off ? null : <TabFixed nome="Responsável" icon={<PersonIcon />} aria-label="person" {...a11yProps(3, value)} />}
                    {off ? null : <TabFixed nome="Dependentes" icon={<GroupIcon />} aria-label="group" {...a11yProps(4, value)} />}
                    {off ? null : <TabFixed nome="Condições Habitacionais" icon={<HomeIcon />} aria-label="home" {...a11yProps(5, value)} />}
                    {off ? null : <TabFixed nome="Condições Educacionais" icon={<SchoolIcon />} filtered={pessoa != 'all'} aria-label="school" {...a11yProps(6, value)} />}
                    {off ? null : <TabFixed nome="Condições Trabalho" icon={<WorkIcon />} filtered={pessoa != 'all'} aria-label="work" {...a11yProps(7, value)} />}
                    {off ? null : <TabFixed nome="Condições Saúde" icon={<LocalHospitalIcon />} filtered={pessoa != 'all'} aria-label="localHospital" {...a11yProps(8, value)} />}
                    {off ? null : <TabFixed nome="Rendimentos Família" icon={<MonetizationOnIcon />} aria-label="monetizationOn" {...a11yProps(9, value)} />}
                    {off ? null : <TabFixed nome="Benefícios Eventuais" icon={<EventIcon />} filtered={pessoa != 'all'} aria-label="event" {...a11yProps(10, value)} />}
                    {off ? null : <TabFixed nome="Convivência" icon={<GroupWorkIcon />} aria-label="groupWork" {...a11yProps(11, value)} />}
                    {off ? null : <TabFixed nome="Participação" icon={<ThumbUp />} filtered={pessoa != 'all'} aria-label="down" {...a11yProps(12, value)} />}
                    {off ? null : <TabFixed nome="Violência" icon={<MoodBadIcon />} filtered={pessoa != 'all'} aria-label="moodBad" {...a11yProps(13, value)} />}
                    {off ? null : <TabFixed nome="Medida Socioeducativa" icon={<LocalLibraryIcon />} filtered={pessoa != 'all'} aria-label="localLibrary" {...a11yProps(14, value)} />}
                    {off ? null : <TabFixed nome="Acolhimento" icon={<FavoriteIcon />} filtered={pessoa != 'all'} aria-label="favorite" {...a11yProps(15, value)} />}
                    {off ? null : <TabFixed nome="Estudo Social" icon={<AssessmentIcon />} aria-label="assessment" {...a11yProps(16, value)} />}
                    {off ? null : <TabFixed nome="Formulário Extra" icon={<NoteIcon />} aria-label="extra" {...a11yProps(17, value)} />}
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <div className="justify-content-center ml-5">
                    <MaterialTable
                        columns={[
                            { title: 'Cód. Família', field: 'familia_id', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Responsável', field: 'nomeResponsavel', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Apelido', field: 'apelido', headerStyle: { fontWeight: 'bold' } },
                            { title: 'CPF', field: 'cpf', headerStyle: { fontWeight: 'bold' } },
                            { title: 'RG', field: 'rg', headerStyle: { fontWeight: 'bold' } },
                            { title: 'NIS', field: 'nis', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Endereço', field: 'endereco', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Último atendimento', field: 'ultimoAtendimento', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Qtd. Dependentes', field: 'n_dependentes', headerStyle: { fontWeight: 'bold' } },
                            { title: 'Dependentes', field: 'dependentes', headerStyle: { fontWeight: 'bold' } },
                        ]}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let url = `/api/resultBusca?`
                                url += 'page_size=' + query.pageSize
                                url += '&page=' + (query.page + 1)
                                url += '&searchType=' + searchType
                                url += '&filterType=' + filterType
                                if (query.search) {
                                    url += '&search=' + query.search
                                }
                                LaConFetch(url, (r) => {
                                    if (r.success) {
                                        resolve({
                                            data: r.dados,
                                            page: r.page - 1,
                                            totalCount: r.total
                                        });
                                    } else {
                                        reject()
                                    }
                                }, () => {
                                    reject()
                                })
                            })} actions={actions}
                        onRowClick={(event, rowData) => {
                            selectRow(rowData)
                        }}
                        components={{
                            Container: props => <div {...props} />,
                            Pagination: PatchedPagination,
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                            })
                        }}
                        icons={_materialTableIcons}
                        localization={_MaterialTableLocalization}
                        title={<ButtonGroup>
                            <Button
                                variant={filterType === 'nome' ? 'contained' : 'outlined'}
                                onClick={() => setFilterType('nome')}
                                color="primary"
                            >
                                Nome
                            </Button>
                            <Button
                                variant={filterType === 'cpf' ? 'contained' : 'outlined'}
                                onClick={() => setFilterType('cpf')}
                                color="primary"
                            >
                                CPF
                            </Button>
                            <Button
                                variant={filterType === 'nis' ? 'contained' : 'outlined'}
                                onClick={() => setFilterType('nis')}
                                color="primary"
                            >
                                NIS
                            </Button>
                            <Button
                                variant={filterType === 'endereco' ? 'contained' : 'outlined'}
                                onClick={() => setFilterType('endereco')}
                                color="primary"
                            >
                                Endereço
                            </Button>
                            <Button
                                variant={filterType === 'dependente' ? 'contained' : 'outlined'}
                                onClick={() => setFilterType('dependente')}
                                color="primary"
                            >
                                Dependente
                            </Button>
                        </ButtonGroup>}
                    />
                </div>

            </TabPanel>

            <TabPanel value={value} index={1}>
                <Atendimento familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Familia familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={3}>
                <Responsavel familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={4}>
                <Dependente familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={5}>
                <CondHabitacionais familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={6}>
                <EducacaoGroup familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={7}>
                <CondTrabalho familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={8}>
                <SaudeGroup familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={9}>
                <Rendimentos familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={10}>
                <Beneficios familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={11}>
                <Convivencia familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={12}>
                <ParticipacaoGroup familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={13}>
                <ViolenciaGroup familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={14}>
                <MedidaSocioEduc familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={15}>
                <Acolhimento familia_id={familia_id} filter={pessoa} />
            </TabPanel>

            <TabPanel value={value} index={16}>
                <EstudoSocial familia_id={familia_id} />
            </TabPanel>

            <TabPanel value={value} index={17}>
                <FormularioExtra familiaId={familia_id} />
            </TabPanel>
        </Card> : null}

        {numtab == 2 ? <Card style={{ margin: '7px' }}>
            <SolicitaBeneficio familia_id={familia_id} filter={pessoa} />
        </Card> : null}

        {numtab == 3 ? <Card style={{ margin: '7px' }}>
            <Encaminhamento familia_id={familia_id} filter={pessoa} />
        </Card> : null}
        {numtab == 4 ? <Card style={{ margin: '7px' }}>
            <Agenda familia_id={familia_id} />
        </Card> : null}
        {numtab == 5 ? <Card style={{ margin: '7px' }}>
            <Historico familia_id={familia_id} />
        </Card> : null}
        <Carteira
            responsavel={pessoa != 'all' && pessoa != 'responsavel' ? responsavel : null}
            pessoa={pessoa != 'all' && pessoa != 'responsavel' ? { pessoa: dependentes.find(d => d.id == pessoa) } : responsavel}
            open={carteira}
            onClose={() => setCarteira(false)}
        />
    </div >
}
