import { Button, Dialog, DialogContent, Modal, Paper } from '@material-ui/core';
import { Attachment } from '@material-ui/icons';
import { link } from 'links/Links';
import moment from 'moment';
import React, { useState } from 'react';
import CrudView from './CrudView';

export default function ModaAnexo({ url, endLink = '', bigButton = false, ...props }) {
    const [modal, setModal] = useState(false)

    return <div>
        {bigButton ? <Button variant={"outlined"} onClick={() => setModal(true)} color="primary" style={{ width: '100%', padding: '20px 10px' }} startIcon={<Attachment />}>
            Anexos
        </Button> : <Button variant="contained" color="primary" onClick={() => setModal(true)}>
            Ver Anexos
        </Button>}
        <Dialog open={modal} onClose={() => setModal(false)} maxWidth="lg" fullWidth>
            <DialogContent>
                <CrudView
                    url={`/api/${url}/anexo`} endLink={endLink} title='Anexos'
                    canEdit={false} canDelete={false} topBar={false} noPaper={true}
                    columns={[
                        { field: 'id', name: 'Arquivo #' },
                        { field: 'nome', name: 'Nome' },
                        { field: 'path', name: 'Arquivo', render: (data) => <a href={`${link}/storage/${data.path.substring(7)}`} target='_blank'>Clique Aqui</a> },
                        { field: 'created_at', name: 'Enviado em', render: (data) => moment(data.created_at, 'YYYY-MM-DDTHH:mm:ss').format("HH:mm DD/MM/YYYY") },
                    ]}
                    fields={[
                        {
                            field: 'nome', label: 'Nome', type: 'text', grid: { xs: 12 },
                        }, {
                            field: 'anexo', label: 'Anexo', type: 'file', grid: { xs: 12 },
                        }, {
                            field: 'obs', label: 'Observação', type: 'text', grid: { xs: 12 }, input: { multiline: true, rows: 4 }
                        }
                    ]}
                />
            </DialogContent>
        </Dialog>
    </div>
}