import React, { useState, useEffect } from 'react'
import { Paper, TextField, Grid, Button, IconButton, Backdrop, CircularProgress } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'
import 'moment/locale/pt-br';
import CloseIcon from '@material-ui/icons/Close';
import { LaConFetch } from '../../links/LaConFetch';
import swal from 'sweetalert';
import SaveIcon from '@material-ui/icons/Save';
import SelectFromAnother from 'components/Crud/SelectFromAnother';

export default function NovaAgenda({slot,onClose=()=>{},familia_id=0,url="/api/agenda/new",pessoa={},...props}){
    const [titulo,setTitulo] = useState('')
    const [descricao,setDescricao] = useState('')
    const [start,setStart] = useState(new Date())
    const [end,setEnd] = useState(moment().add(10,'min').toDate())
    const [tipo_id,setTipoId] = useState('')
    const [pessoa_id,setPessoaId] = useState('')

    const [sending,setSending] = useState(false)

    useEffect(()=>{
        if(slot){
            setStart(slot.start)
            setEnd(slot.end)
        }
    },[slot])

    const onSubmit=()=>{
        setSending(true)
        let form = {
            titulo, obs: descricao, start:moment(start).format('YYYY-MM-DD HH:mm'), end:moment(end).format('YYYY-MM-DD HH:mm'), familia_id, pessoa_id,tipo_id
        }
        if(pessoa){
            form.pessoa_id = pessoa.id
        }
        LaConFetch(`${url}`,(r)=>{
            if(r.success){
                swal("Criado com sucesso!","", "success");
                onClose(true)
            }
            setSending(false)
        },()=>{},form)
    }

    return <div>
        <Backdrop style={{zIndex:99}} open={sending}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Paper style={{padding:20}}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <h5>Adicionar novo agendamento:</h5>
                </Grid>
                <Grid item xs={2}>
                    <div style={{width:'100%',textAlign:'right'}}>
                        <IconButton onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {pessoa ? <TextField
                            disabled value={pessoa?.nm_pessoa ?? ''} label='Pessoa'
                            fullWidth margin="normal" variant="outlined"
                        />: familia_id ? <SelectFromAnother
                            link={`/api/resultPessoa/${familia_id}`} value={pessoa_id} onChange={setPessoaId} label="Pessoa"
                        />:null}
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDateTimePicker
                            inputVariant="outlined" variant="dialog"
                            ampm={false}
                            label="Começo"
                            value={start}
                            onChange={setStart}
                            fullWidth margin='normal'
                            format="DD/MM/yyyy HH:mm"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDateTimePicker
                            inputVariant="outlined" variant="dialog"
                            ampm={false}
                            label="Término"
                            value={end}
                            onChange={setEnd}
                            fullWidth margin='normal'
                            format="DD/MM/yyyy HH:mm"
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
            <TextField
                fullWidth margin='normal' variant='outlined'
                label='Título' required
                value={titulo} onChange={(e)=>setTitulo(e.target.value)}
            />
            <TextField
                fullWidth margin='normal' variant='outlined'
                label='Observação' multiline rows={4}
                value={descricao} onChange={(e)=>setDescricao(e.target.value)}
            />
            <SelectFromAnother
                link={'/api/agenda/tipo'} value={tipo_id} onChange={setTipoId} label="Tipo de agendamento"
            />
            <Grid container>
                <Grid item xs={11}></Grid>
                <Grid item xs={1}>
                    <Button color='primary' variant='contained' onClick={onSubmit}>
                        <SaveIcon/> Salvar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </div>
}