import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop, Modal, Box } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function MedidaSocioEduc({ familia_id, filter }) {

    const classes = useStyles();

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // MedidaSocioEduc
    const [id, setId] = useState("");
    const [cd_medida, setCd_medida] = useState("");
    const [nm_medida, setNm_medida] = useState("");
    const [nr_processo, setNr_processo] = useState("");
    const [dt_inicio, setDt_inicio] = useState("");
    const [dt_fim, setDt_fim] = useState("");
    const [st_acompanhado_creas, setSt_acompanhado_creas] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (cd_medida === '1') {
            setNm_medida("Liberdade Assistida (LA)");
        } else if (cd_medida === '2') {
            setNm_medida("Prestação de Serviços à Comunidade (PSC)");
        } else if (cd_medida === '3') {
            setNm_medida("Advertência");
        } else if (cd_medida === '4') {
            setNm_medida("Obrigação de Reparar o Dano");
        } else if (cd_medida === '5') {
            setNm_medida("Semi-Liberdade");
        } else if (cd_medida === '6') {
            setNm_medida("Internação");
        } else {
            setNm_medida("");
        }

    }, [cd_medida])

    const [sending, setSending] = useState(false);

    // Cadastrar MedidaSocioEduc
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (cd_medida === "")
            swal("Código da medida é obrigatório!", "", "error")

        else if (dt_inicio === "")
            swal("Data de início é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                cd_medida,
                nm_medida,
                nr_processo,
                dt_inicio,
                dt_fim,
                st_acompanhado_creas,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarMedidaSocioEduc`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e MedidaSocioEduc*/
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);

        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, result => setLoading(false))

        LaConFetch(`/api/resultMedidaSocioEduc/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, result => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosAcolhimento */
    const setDadosMedida = (dados) => {

        /* Dados */
        setId(dados.id);
        setCd_medida(dados.cd_medida)
        setNm_medida(dados.nm_medida)
        setNr_processo(dados.nr_processo)
        setDt_inicio(dados.dt_inicio);
        setDt_fim(dados.dt_fim);
        setSt_acompanhado_creas(dados.st_acompanhado_creas)
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setCd_medida("");
        setNm_medida("")
        setNr_processo("");
        setDt_inicio("");
        setDt_fim("");
        setSt_acompanhado_creas("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosMedida(dados);
        setEditing(true)
        setOpen(true);
    };

    // Editar MedidaSocioEduc
    const editar = () => {

        const formDados = {
            cd_medida,
            nm_medida,
            nr_processo,
            dt_inicio,
            dt_fim,
            st_acompanhado_creas,
            ds_observacao
        };

        setSending(true);

        LaConFetch(`/api/editarMedidaSocioEduc/${id}`,
            result => {
                if (result.success) {
                    load();
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                } else {
                    swal("Oops", result.message, "error");
                }
                setSending(false);
            }, () => {
                setSending(false);
            }, formDados)

    };

    /* Remover MedidaSocioEduc */
    const remover = (dados) => {
        const id = dados.id;
        setSending(true);
        LaConFetch(`/api/removerMedidaSocioEduc/${id}`,
            result => {
                setSending(false)
            }, () => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <div />
            <Button onClick={handleOpen} variant="contained" color="primary">
                Adicionar Medida
            </Button>
        </Box>

        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Cód. Medida', field: 'cd_medida', headerStyle: { fontWeight: 'bold' } },
                { title: 'Nome Medida', field: 'nm_medida', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data Início', field: 'dt_inicioTab', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            options={{
                actionsColumnIndex: -1
            }}
            components={{
                Pagination: PatchedPagination,
            }}
            localization={_materialTableLocalization}
            icons={_materialTableIcons}
            title="Medida Socioeducativa"
        />

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <div className={classes.paper}>

                <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Medida Socioeducativa</InputLabel>

                {(editing === false) ? (
                    <TextField
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: 15 }}
                        id="pessoa_id"
                        value={pessoa_id}
                        label="Nome da pessoa*"
                        onChange={(e) => setPessoa_id(e.target.value)}
                        select>
                        {dadosPessoa.map(item =>
                            <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                        )}
                    </TextField>
                ) : (
                    <TextField
                        disabled={true}
                        margin="normal"
                        variant="outlined"
                        style={{ marginTop: 15, width: '100%' }}
                        id="nm_pessoa"
                        value={nm_pessoa}
                        label="Nome da pessoa"
                    />)}

                <TextField
                    variant="outlined"
                    style={{ marginTop: 15, marginRight: '2%', width: '32%' }}
                    id="cd_medida"
                    value={cd_medida}
                    label="Código da medida*"
                    onChange={(e) => setCd_medida(e.target.value)}
                    select>
                    <MenuItem value="1">1 - Liberdade Assistida (LA)</MenuItem>
                    <MenuItem value="2">2 - Prestação de Serviços à Comunidade (PSC)</MenuItem>
                    <MenuItem value="3">3 - Advertência</MenuItem>
                    <MenuItem value="4">4 - Obrigação de Reparar o Dano</MenuItem>
                    <MenuItem value="5">5 - Semi-Liberdade</MenuItem>
                    <MenuItem value="6">6 - Internação</MenuItem>
                </TextField>

                <TextField
                    margin="normal"
                    style={{ marginRight: '2%', width: '15%' }}
                    id="nr_processo"
                    label="Número do processo"
                    variant="outlined"
                    value={nr_processo}
                    onChange={(e) => setNr_processo(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="dt_inicio"
                    label="Data de Início*"
                    type="date"
                    value={dt_inicio}
                    onChange={(e) => setDt_inicio(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    style={{ marginRight: '2%', width: '16%' }}
                    id="dt_fim"
                    label="Data de Fim"
                    type="date"
                    value={dt_fim}
                    onChange={(e) => setDt_fim(e.target.value)}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <FormControl style={{ marginTop: 15, width: '18%' }} component="fieldset">
                    <FormLabel component="legend">Acompanhado pelo CREAS?</FormLabel>
                    <RadioGroup row value={st_acompanhado_creas} aria-label="st_acompanhado_creas" name="st_acompanhado_creas" onChange={(e) => setSt_acompanhado_creas(e.target.value)}>
                        <FormControlLabel value="Sim" control={<Radio color="primary" />} label="SIM" />
                        <FormControlLabel value="Não" control={<Radio color="primary" />} label="NÂO" />
                    </RadioGroup>
                </FormControl>

                <TextField
                    margin="normal"
                    style={{ width: '100%' }}
                    id="ds_observacao"
                    label="Observação"
                    variant="outlined"
                    value={ds_observacao}
                    onChange={(e) => setDs_observacao(e.target.value)}
                    multiline='true'
                    rows='4'
                />

                <br></br><br></br>

                <Button onClick={handleClose} style={{ float: 'right', marginLeft: 15, backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>

                <Button onClick={(editing === false) ? enviar : editar} style={{ float: 'right' }} variant="contained" color="primary">
                    Enviar
                </Button>

                <br></br><br></br>

            </div>

        </Modal>
    </div>
}