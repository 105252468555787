import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { LaConFetch } from '../links/LaConFetch';
import React, { useState } from 'react';
import swal from 'sweetalert';
import AutocompleteFamilia from './AutocompleteFamilia';

export default function MoveDependente({ open, onClose, id, nome, ...props }) {
    const [mode, setMode] = useState('');
    const [novaFamilia, setNovaFamilia] = useState(0);
    const [sending, setSending] = useState(false);

    const onRemove = (data = {}) => {
        setSending(true);
        LaConFetch(`/api/removerDependente/${id}`, (r) => {
            if (r.success) {
                onClose(true)
            } else {
                swal('Erro', r.message, 'error')
            }
            setSending(false)
        }, () => {
            setSending(false)
        }, {
            ...data,
            mode: mode
        })
    }

    const onSubmit = () => {
        switch (mode) {
            case 'remove':
                onRemove();
                break;
            case 'nova':
                onRemove();
            case 'move':
                onRemove({
                    familia_id: novaFamilia
                });
            default:
        }
    }

    return <>
        <Backdrop open={sending} style={{ zIndex: 9999 }}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>
                Desvincular Dependente
            </DialogTitle>
            <DialogContent>
                <Typography>Tem certeza que deseja desvincular o dependente {nome} desta família?</Typography>
                <FormControlLabel
                    control={<Radio checked={mode === 'remove'} onChange={(e) => setMode(e.target.checked ? 'remove' : '')} />}
                    label="Sim, desvincular e deletar seus dados"
                />
                <FormControlLabel
                    control={<Radio checked={mode === 'nova'} onChange={(e) => setMode(e.target.checked ? 'nova' : '')} />}
                    label="Sim, desvincular e criar uma nova família com este dependente como responsável"
                />
                <FormControlLabel
                    control={<Radio checked={mode === 'move'} onChange={(e) => setMode(e.target.checked ? 'move' : '')} />}
                    label="Sim, mover para outra família"
                />
                <AutocompleteFamilia
                    value={novaFamilia}
                    disabled={mode !== 'move'}
                    onChange={(v) => setNovaFamilia(v)}
                />
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button onClick={() => onClose()}>
                        Cancelar
                    </Button>
                    <Button onClick={onSubmit} disabled={mode === '' || sending} color="secondary">
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </>
}