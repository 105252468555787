import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Modal, Box, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import PatchedPagination from 'components/PatchedPagination';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import YesNoRadio from 'components/FormInputs/YesNoRadio';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

}));

export default function DescumprimentoSaude({ familia_id, filter }) {

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // Descumprimento
    const [id, setId] = useState("");
    const [dt_ocorrencia, setDt_ocorrencia] = useState("");
    const [cd_efeito, setCd_efeito] = useState("");
    const [nm_efeito, setNm_efeito] = useState("");
    const [st_suspensao, setSt_suspensao] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const classes = useStyles();

    useEffect(() => {

        if (cd_efeito === '1') {
            setNm_efeito("Advertência");
        } else if (cd_efeito === '2') {
            setNm_efeito("Bloqueio");
        } else if (cd_efeito === '3') {
            setNm_efeito("Suspensão");
        } else if (cd_efeito === '4') {
            setNm_efeito("Cancelamento");
        } else {
            setNm_efeito("");
        }

    }, [cd_efeito])

    /* Cadastrar DescumprimentoSaude*/
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")
        else if (dt_ocorrencia === "")
            swal("Data da ocorrência é obrigatório!", "", "error")
        else if (cd_efeito === "")
            swal("Código do efeito é obrigatório!", "", "error")
        else {
            const formDados = {
                pessoa_id,
                dt_ocorrencia,
                cd_efeito,
                nm_efeito,
                st_suspensao,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarDescumprimentoSaude`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e Descumprimento*/
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);

        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

        LaConFetch(`/api/resultDescumprimentoSaude/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, () => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosDescumprimento */
    const setDadosDescumprimento = (dados) => {

        /* Dados */
        setId(dados.id);
        setDt_ocorrencia(dados.dt_ocorrencia);
        setCd_efeito(dados.cd_efeito)
        setNm_efeito(dados.nm_efeito)
        setSt_suspensao(dados.st_suspensao);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setDt_ocorrencia("");
        setCd_efeito("")
        setNm_efeito("")
        setSt_suspensao("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };
    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosDescumprimento(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar DescumprimentoSaude*/
    const editar = () => {

        const formDados = {
            pessoa_id,
            dt_ocorrencia,
            cd_efeito,
            nm_efeito,
            st_suspensao,
            ds_observacao
        };

        setSending(true);

        LaConFetch(`/api/editarDescumprimentoSaude/${id}`,
            result => {
                load();
                setSending(false);
                swal("Editado com sucesso!", "", "success")
                setOpen(false)
            }, () => { }, formDados)

    };

    /* Remover Descumprimento */
    const remover = (dados) => {

        const id = dados.id;

        setSending(true);

        LaConFetch(`/api/removerDescumprimentoSaude/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>Descumprimento Saúde</Typography>
            <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                Adicionar Descumprimento
            </Button>
        </Box>
        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data Ocorrência', field: 'dt_ocorrenciaTab', headerStyle: { fontWeight: 'bold' } },
                { title: 'Cód. Efeito', field: 'cd_efeito', headerStyle: { fontWeight: 'bold' } },
                { title: 'Nome Efeito', field: 'nm_efeito', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            components={{
                Pagination: PatchedPagination
            }}
            options={{
                actionsColumnIndex: -1
            }}
            localization={_materialTableLocalization}
            icons={_materialTableIcons}
            title="Descumprimento Saúde"
        />
        <Dialog
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullWidth maxWidth="lg"
        >
            <DialogTitle>
                <InputLabel style={{ fontSize: 20 }}>Descumprimento</InputLabel>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="pessoa_id"
                            value={pessoa_id}
                            disabled={editing}
                            label="Nome da pessoa*"
                            onChange={(e) => setPessoa_id(e.target.value)}
                            select
                        >
                            {dadosPessoa.map(item =>
                                <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            id="dt_ocorrencia"
                            label="Data da ocorrência*"
                            type="date"
                            variant="outlined"
                            value={dt_ocorrencia}
                            onChange={(e) => setDt_ocorrencia(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="cd_efeito"
                            value={cd_efeito}
                            label="Código do efeito*"
                            onChange={(e) => setCd_efeito(e.target.value)}
                            select
                        >
                            <MenuItem value="1">1 - Advertência</MenuItem>
                            <MenuItem value="2">2 - Bloqueio</MenuItem>
                            <MenuItem value="3">3 - Suspensão</MenuItem>
                            <MenuItem value="4">4 - Cancelamento</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <YesNoRadio
                            label="Solicitada suspensão do efeito?"
                            value={st_suspensao}
                            onChange={setSt_suspensao}
                            name="st_suspensao"
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="ds_observacao"
                            label="Observação"
                            variant="outlined"
                            value={ds_observacao}
                            onChange={(e) => setDs_observacao(e.target.value)}
                            multiline='true'
                            rows='4'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box display='flex' justifyContent='space-between' width='100%'>
                    <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                        Fechar
                    </Button>
                    <Button onClick={(editing === false) ? enviar : editar} variant="contained" color="primary">
                        Enviar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </div >

}