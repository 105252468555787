import { Fab, Grid, MenuItem, TextField } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import React from 'react';
import NumberFormatCustom from './NumberFormatCustom';

export default function LinhaProgramaSocial({ pessoas = [], item, onChange, onAdd = () => { }, onRemove = () => { }, mode = 'remove', ...props }) {
    return <Grid container spacing={2} alignItems='center'>
        <Grid item md={3}>
            <TextField
                variant="outlined"
                hidden='true'
                fullWidth
                id="cd_programa_social"
                label="Programas Sociais*"
                value={item.cd_programa_social}
                onChange={(e) => onChange('cd_programa_social', e.target.value)}
                select
            >
                <MenuItem value="1">Bolsa Família</MenuItem>
                <MenuItem value="2">BPC</MenuItem>
                <MenuItem value="3">Programa de Erradicação do Trabalho Infantil (PETI)</MenuItem>
                <MenuItem value="4">Renda Cidadã</MenuItem>
                <MenuItem value="5">Renda Cidadã/Idoso</MenuItem>
                <MenuItem value="6">Ação Jovem</MenuItem>
                <MenuItem value="7">Frente de Trabalho</MenuItem>
                <MenuItem value="8">Viva Leite</MenuItem>
                <MenuItem value="9">Outro</MenuItem>
            </TextField>
        </Grid>
        <Grid item md={3}>
            <TextField
                fullWidth
                id="vl_programa_social"
                label="Valor Recebido*"
                variant="outlined"
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
                value={item.vl_programa_social}
                onChange={(e) => onChange('vl_programa_social', e.target.value)}
            />
        </Grid>
        <Grid item md={3}>
            <TextField
                variant="outlined"
                fullWidth
                id="pessoa_id"
                value={item.pessoa_id}
                label="Beneficiado*"
                onChange={(e) => onChange('pessoa_id', e.target.value)}
                select>
                {pessoas.map(item =>
                    <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                )}
            </TextField>
        </Grid>
        <Grid item md={3}>
            {mode === 'add' ?
                <Fab onClick={(e) => onAdd()} size="small" color="primary" aria-label="addFaixa">
                    <Add />
                </Fab> : <Fab onClick={(e) => onRemove()} size="small" color="secundary" aria-label="closeFaixa">
                    <Close />
                </Fab>}
        </Grid>
    </Grid>
}