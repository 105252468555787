import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, CircularProgress, Backdrop } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';

const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

}));

export default function Familia({ familia_id }) {

  useEffect(() => {
    load();
  }, [])

  const classes = useStyles();

  /* Especifidades Sociais */
  const [cd_especifidades_sociais, setCd_especifidades_sociais] = useState("");
  const [ds_especifidades_sociais, setDs_especifidades_sociais] = useState("");
  const [ds_povo, setDs_povo] = useState();

  /* Valor mostrado em outro */
  const [ds_especifidades_outro, setDs_especifidades_outro] = useState("");

  /* Desabilitar outro */
  const [outroEspecifidadesOff, setOutroEspecifidadesOff] = useState(true);

  /* Desabilitar Povo Indigena */
  const [povoOff, setPovoOff] = useState(true);

  /* Carregar Especifidades Sociais */
  const load = () => {
    setSending(true);
    LaConFetch(`/api/resultFamilia/${familia_id}`,
      result => {
        if (result.dados !== null) {
          setDados(result.dados[0])
        }
        setSending(false)
      }, result => setSending(false))
  };

  const setDados = (dados) => {

    setEspecifidadesSociais(dados.especifidades?.cd_especifidades_sociais);
    setDs_especifidades_sociais(dados.especifidades?.ds_especifidades_sociais);
    setDs_povo(dados.especifidades?.ds_povo);
    if (dados.especifidades?.cd_especifidades_sociais === '7') {
      setDs_especifidades_outro(dados.especifidades?.ds_especifidades_sociais);
    }

    let holding = dados.perfil.map((item, pos) => {

      return {
        nm_faixa_etaria: item.nm_faixa_etaria,
        qt_pessoas: item.qt_pessoas,
        botao: pos === dados.perfil.length - 1
      }
    })

    setPerfilEtario(holding)

  }

  /* Set CD_ESPECIFIDADES e DS_ESPECIFIDADES */
  const setEspecifidadesSociais = (codigo) => {

    setCd_especifidades_sociais(codigo);

    if (codigo === '1') {
      setDs_especifidades_outro(null);
      setDs_povo(null);
      setOutroEspecifidadesOff(true);
      setPovoOff(true);
      setDs_especifidades_sociais('Família/pessoa em situação de rua');
    }
    else if (codigo === '2') {
      setDs_especifidades_outro(null);
      setDs_povo(null);
      setOutroEspecifidadesOff(true);
      setPovoOff(true);
      setDs_especifidades_sociais('Família quilombola');
    }
    else if (codigo === '3') {
      setDs_especifidades_outro(null);
      setDs_povo(null);
      setOutroEspecifidadesOff(true);
      setPovoOff(true);
      setDs_especifidades_sociais('Família ribeirinha');
    }
    else if (codigo === '4') {
      setDs_especifidades_outro(null);
      setDs_povo(null);
      setOutroEspecifidadesOff(true);
      setPovoOff(true);
      setDs_especifidades_sociais('Família cigana');
    }
    else if (codigo === '5') {
      setDs_especifidades_outro(null);
      setOutroEspecifidadesOff(true);
      setPovoOff(false);
      setDs_especifidades_sociais('Família indígena residente em aldeia/reserva');
    }
    else if (codigo === '6') {
      setDs_especifidades_outro(null);
      setOutroEspecifidadesOff(true);
      setPovoOff(false);
      setDs_especifidades_sociais('Família indígena não residente em aldeia/reserva');
    }

    else if (codigo === '7') {
      setDs_povo(null);
      setOutroEspecifidadesOff(false);
      setPovoOff(true);
    }

  }

  const setEspecifidadesSociaisOutro = (texto) => {

    setDs_especifidades_outro(texto);
    setDs_especifidades_sociais(texto);
  }

  /* Perfil Etario */
  const [perfilEtario, setPerfilEtario] = useState([{ nm_faixa_etaria: "", qt_pessoas: "" }]);

  /* Add Faixa */
  const addFaixa = (pos) => {
    let add = [...perfilEtario];
    add.push({ nm_faixa_etaria: "", qt_pessoas: "" });
    setPerfilEtario(add);
  }

  /* Remove Faixa */
  const closeFaixa = (pos) => {
    let faixa = [...perfilEtario];
    faixa.splice(pos, 1);
    setPerfilEtario(faixa);
  }

  /* Set Faixa Etaria */
  const setFaixa = (valor, pos) => {
    let add = [...perfilEtario];
    add[pos].nm_faixa_etaria = valor;
    setPerfilEtario(add);
  }

  /* Set Quantidade de Pessoas */
  const setQtPessoas = (valor, pos) => {
    let add = [...perfilEtario];
    add[pos].qt_pessoas = valor;
    setPerfilEtario(add);
  }

  const [sending, setSending] = useState(false);

  /* Editar Familia*/
  const editar = () => {

    const formDados = {
      cd_especifidades_sociais,
      ds_especifidades_sociais,
      ds_povo,
    };

    setSending(true);
    LaConFetch(`/api/editarFamilia/${familia_id}`,
      result => {
        load();
        setSending(false);
        swal("Enviado com sucesso!", "", "success")
      }, () => { }, formDados)

  };

  return (

    <div className={classes.root}>

      <Backdrop style={{ zIndex: 99 }} open={sending}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Especifidades Sociais</InputLabel>

      <TextField
        variant="outlined"
        style={{ marginTop: 15, marginRight: '2%', width: '40%' }}
        id="cd_especifidades_sociais"
        value={cd_especifidades_sociais}
        label="Código da especifidade social"
        onChange={(e) => setEspecifidadesSociais(e.target.value)}
        select>
        <MenuItem value="1">1 - Família/pessoa em situação de rua</MenuItem>
        <MenuItem value="2">2 - Família quilombola</MenuItem>
        <MenuItem value="3">3 - Família ribeirinha</MenuItem>
        <MenuItem value="4">4 - Família cigana</MenuItem>
        <MenuItem value="5">5 - Família indígena residente em aldeia/reserva</MenuItem>
        <MenuItem value="6">6 - Família indígena não residente em aldeia/reserva</MenuItem>
        <MenuItem value="7">7 - Outro</MenuItem>
      </TextField>

      {/* {cd_especifidades_sociais == '7'? */}
      <TextField
        disabled={outroEspecifidadesOff}
        margin="normal"
        style={{ width: '58%' }}
        value={ds_especifidades_outro}
        id="ds_especifidades_outro"
        label="Caso outro, qual?"
        variant="outlined"
        onChange={(e) => setEspecifidadesSociaisOutro(e.target.value)}
      />
      {/* :null} */}

      <TextField
        disabled={povoOff}
        margin="normal"
        style={{ width: '100%' }}
        id="ds_povo"
        label="Povo/Etnia (família indígena)"
        variant="outlined"
        value={ds_povo}
        onChange={(e) => setDs_povo(e.target.value)}
      />

      {/* --------------------------- Perfil Etario --------------------------- */}

      <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Perfil Etário</InputLabel>

      {perfilEtario.map((item, pos) =>

        <div>
          <TextField
            disabled={true}
            variant="outlined"
            style={{ marginTop: 15, marginRight: '2%', width: '23%' }}
            id="nm_faixa_etaria"
            value={item.nm_faixa_etaria}
            label="Faixa Etária"
            onChange={(e) => setFaixa(e.target.value, pos)}
            select>
            <MenuItem value="Pessoas de 0 a 6 anos">Pessoas de 0 a 6 anos</MenuItem>
            <MenuItem value="Pessoas de 7 a 14 anos">Pessoas de 7 a 14 anos</MenuItem>
            <MenuItem value="Pessoas de 15 a 17 anos">Pessoas de 15 a 17 anos</MenuItem>
            <MenuItem value="Pessoas de 18 a 29 anos">Pessoas de 18 a 29 anos</MenuItem>
            <MenuItem value="Pessoas de 30 a 59 anos">Pessoas de 30 a 59 anos</MenuItem>
            <MenuItem value="Pessoas de 60 a 64 anos">Pessoas de 60 a 64 anos</MenuItem>
            <MenuItem value="Pessoas de 65 a 69 anos">Pessoas de 65 a 69 anos</MenuItem>
            <MenuItem value="Pessoas com 70 anos ou mais">Pessoas com 70 anos ou mais</MenuItem>
          </TextField>

          <TextField
            disabled={true}
            margin="normal"
            style={{ width: '16%', marginRight: '2%' }}
            id="qt_pessoas"
            label="Quantidade de Pessoas"
            variant="outlined"
            value={item.qt_pessoas}
            onChange={(e) => setQtPessoas(e.target.value, pos)}
          />

        </div>)
      }

      <br></br><br></br>

      <Button onClick={editar} style={{ float: 'right' }} variant="contained" color="primary">
        Editar
      </Button>

      <br></br><br></br>

    </div>
  )
}