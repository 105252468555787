import { Paper } from '@material-ui/core';
import React from 'react';
import imgagemEscolhida from '../assets/img/CrasMonteAlegre.jpg'
import { link } from '../links/Links';
import AlmoxarifadoDash from './Dashboard/AlmoxarifadoDash';
import DiretorDash from './Dashboard/DiretorDash';
import PerLocal from './Dashboard/PerLocal';
import TecnicoDash from './Dashboard/TecnicoDash';

export default function Home() {

    return <div>
        <Paper style={{ padding: 10, margin: 5 }}>
            <PerLocal />
        </Paper>
    </div>
    const type = localStorage.getItem('type')
    switch (type) {
        case 'ALM':
        case 'ALA':
            return <div>
                <Paper style={{ padding: 10, margin: 5 }}>
                    <AlmoxarifadoDash />
                </Paper>
            </div>
        case 'GER':
        case 'DIR':
            return <div>
                <Paper style={{ padding: 10, margin: 5 }}>
                    <DiretorDash />
                </Paper>
            </div>
        case 'TEC':
        case 'REC':
            return <div>
                <Paper style={{ padding: 10, margin: 5 }}>
                    <TecnicoDash />
                </Paper>
            </div>
        case 'FIN':
            return <div>
                <Paper style={{ padding: 10, margin: 5 }}>
                    <FinanceiroDash />
                </Paper>
            </div>
        case 'CON':
        default:
            return (<div>

            </div>);
    }

}