import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop, Modal, Grid, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import LinhaProgramaSocial from 'components/LinhaProgramaSocial';
import LinhaDespesa from 'components/LinhaDespesa';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="R$ "
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function Rendimentos({ familia_id }) {

    const classes = useStyles();

    // Programa Social
    const [novoPrograma, setNovoPrograma] = useState({ cd_programa_social: "", vl_programa_social: "", pessoa_id: null, botao: true });
    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);
    const [open, setOpen] = React.useState(false);
    const [novaDespesa, setNovaDespesa] = React.useState({ nm_despesa: "", vl_despesa: "", botao: true });
    const [despesa, setDespesa] = useState([]);
    const [programa, setProgramaObj] = useState([]);

    /* Carregar Pessoa e Programa Social*/

    const loadPrograma = () => {

        setSending(true);

        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setSending(false)
            }, result => setSending(false))

        LaConFetch(`/api/resultProgramaSocial/${familia_id}`,
            result => {
                if (result.dados.length > 0) {
                    setProgramaObj(result.dados)
                }
                setSending(false)
            }, result => setSending(false))

    };

    useEffect(() => {

        loadPrograma();

    }, [])

    /* Despesa */

    /* Add Despesa */
    const addDespesa = () => {
        let add = [...despesa];
        add.push(novaDespesa);
        setDespesa(add);
        setNovaDespesa({ nm_despesa: "", vl_despesa: "", botao: true });
    }

    /* Remove Despesa */
    const closeDespesa = (pos) => {
        let desp = [...despesa];
        desp.splice(pos, 1);
        setDespesa(desp);
    }

    /* Cadastrar Despesa */
    const enviarDespesa = () => {

        let continua = false;

        for (let item of despesa) {

            if (item.nm_despesa === "" && item.vl_despesa !== "") {
                continua = false;
                swal("Nome da despesa é obrigatório para o envio!", "", "error");
                break;
            }

            else if (item.vl_despesa === "" && item.nm_despesa !== "") {
                continua = false;
                swal("Valor da despesa é obrigatório para o envio!", "", "error");
                break;
            }

            else {
                continua = true;
            }
        };

        if (continua === true) {

            setSending(true);

            LaConFetch(`/api/cadastrarDespesa`,
                result => {
                    loadRendimentos();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, { despesa, familia_id })

        }
    };

    /* Add Programa */
    const addPrograma = (pos) => {
        let add = [...programa];
        add.push(novoPrograma);
        setProgramaObj(add);
        setNovoPrograma({ cd_programa_social: "", vl_programa_social: "", pessoa_id: null, botao: true });
    }

    /* Remove Programa */
    const closePrograma = (pos) => {
        let desp = [...programa];
        desp.splice(pos, 1);
        setProgramaObj(desp);
    }

    const onChangePrograma = (field, value, pos) => {
        let hold = [...programa]
        hold[pos][field] = value
        setProgramaObj(hold)
    }

    const onChangeDespesa = (field, value, pos) => {
        let hold = [...despesa]
        hold[pos][field] = value
        setDespesa(hold)
    }

    const enviarPrograma = () => {

        let continua = true;

        for (let item of programa) {
            if (item.cd_programa_social === "") {
                continua = false;
                swal("Programa Social não selecionado!", "", "error");
                break;
            } else if (item.vl_programa_social === "") {
                continua = false;
                swal("Valor de programa social inválido!", "", "error");
                break;
            } else if (item.pessoa_id === "") {
                continua = false;
                swal("Programa social sem beneficiado!", "", "error");
                break;
            } else {
                continua = true;
            }
        };
        if (continua === false) {
            return;
        }

        setSending(true);

        LaConFetch(`/api/cadastrarProgramaSocial`,
            result => {
                loadPrograma();
                setSending(false);
                swal("Enviado com sucesso!", "", "success")
                setOpen(false)
            }, () => { }, { programa, familia_id })

    };

    /* Carregar Despesa */
    const loadDespesa = () => {

        setSending(true);

        LaConFetch(`/api/resultDespesa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosDespesa(result.dados)
                }
                setSending(false)
            }, result => setSending(false))

    };

    useEffect(() => {

        loadDespesa();

    }, [])

    /* SetDados Despesa */
    const setDadosPrograma = (dados) => {

        /* Despesa */
        let holdPrograma = dados.map((item, pos) => {

            return {

                cd_programa_social: item.cd_programa_social,
                vl_programa_social: item.vl_programa_social,
                pessoa_id: item.pessoa_id,
                botao: pos === dados.length - 1

            }
        })
        setProgramaObj(holdPrograma)

    };

    /* Rendimentos */
    const [vl_renda_total_sem_ps, setVl_renda_total_sem_ps] = useState("");
    const [vl_renda_per_capita_sem_ps, setVl_renda_per_capita_sem_ps] = useState("");
    const [vl_total_beneficios, setVl_total_beneficios] = useState("");
    const [vl_total_despesas, setVl_total_despesas] = useState("");
    const [vl_renda_total_com_ps, setVl_renda_total_com_ps] = useState("");
    const [vl_renda_per_capita_com_ps, setVl_renda_per_capita_com_ps] = useState("");

    const [sending, setSending] = useState(false);

    /* Carregar Rendimentos */
    const loadRendimentos = () => {

        setSending(true);

        LaConFetch(`/api/resultRendimentos/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDados(result.dados)
                }
                setSending(false)
            }, result => setSending(false))

    };

    useEffect(() => {

        loadRendimentos();

    }, [])

    /* SetDados */
    const setDados = (dados) => {

        /* Rendimentos */
        setVl_renda_total_sem_ps(dados.vl_renda_total_sem_ps);
        setVl_renda_per_capita_sem_ps(dados.vl_renda_per_capita_sem_ps);
        setVl_total_beneficios(dados.vl_total_beneficios);
        setVl_total_despesas(dados.vl_total_despesas);
        setVl_renda_total_com_ps(dados.vl_renda_total_com_ps);
        setVl_renda_per_capita_com_ps(dados.vl_renda_per_capita_com_ps);

    };

    /* SetDados Despesa */
    const setDadosDespesa = (dados) => {

        /* Despesa */
        let holdingDespesa = dados.map((item, pos) => {
            return {
                nm_despesa: item.nm_despesa,
                vl_despesa: item.vl_despesa,
                botao: pos === dados.length - 1
            }
        })

        setDespesa(holdingDespesa)

    };

    return (

        <div className={classes.root}>

            <Backdrop style={{ zIndex: 99 }} open={sending}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Programa Social */}

            <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Programas Sociais e Outros Benefícios</InputLabel>
            <Grid container spacing={2}>
                <Grid item md={4}>Programa/Projeto</Grid>
                <Grid item md={4}>Valor</Grid>
                <Grid item md={4}>Beneficiário</Grid>
            </Grid>
            {programa.map((item, pos) => <LinhaProgramaSocial
                item={item}
                onChange={(field, value) => onChangePrograma(field, value, pos)}
                mode='remove'
                onRemove={() => closePrograma(pos)}
                key={pos}
                pessoas={dadosPessoa}
            />)}
            <LinhaProgramaSocial
                item={novoPrograma}
                onChange={(field, value) => setNovoPrograma({ ...novoPrograma, [field]: value })}
                onAdd={() => addPrograma()}
                mode='add'
                pessoas={dadosPessoa}
            />
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button onClick={enviarPrograma} variant="contained" color="primary">
                    Salvar Programas
                </Button>
            </Box>

            {/* Despesa */}
            <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Despesas</InputLabel>

            {despesa.map((item, pos) => <LinhaDespesa
                item={item}
                onChange={(field, value) => onChangeDespesa(field, value, pos)}
                mode='remove'
                onRemove={() => closeDespesa(pos)}
                key={pos}
            />)}
            <LinhaDespesa
                item={novaDespesa}
                onChange={(field, value) => setNovaDespesa({ ...novaDespesa, [field]: value })}
                onAdd={() => addDespesa()}
                mode='add'
            />

            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button onClick={enviarDespesa} style={{ float: 'right' }} variant="contained" color="primary">
                    Salvar Despesas
                </Button>
            </Box>

            <Accordion expanded="true">
                <AccordionDetails>
                    <Typography style={{ float: 'right', width: '100%' }}>

                        {/* Rendimentos */}
                        <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Renda Familiar</InputLabel>

                        <TextField
                            margin="normal"
                            style={{ marginRight: '2%', width: '30%' }}
                            id="vl_renda_total_sem_ps"
                            label="Valor da renda total sem programa social"
                            variant="outlined"
                            disabled={true}
                            value={vl_renda_total_sem_ps}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            onChange={(e) => setVl_renda_total_sem_ps(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            style={{ marginRight: '2%', width: '30%' }}
                            id="vl_renda_per_capita_sem_ps"
                            label="Valor da renda per capita sem programa social"
                            variant="outlined"
                            disabled={true}
                            value={vl_renda_per_capita_sem_ps}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            onChange={(e) => setVl_renda_per_capita_sem_ps(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            style={{ width: '20%' }}
                            id="vl_total_beneficios"
                            label="Valor Total Benefícios"
                            variant="outlined"
                            disabled={true}
                            value={vl_total_beneficios}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            onChange={(e) => setVl_total_beneficios(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            style={{ marginRight: '2%', width: '20%' }}
                            id="vl_total_despesas"
                            label="Valor Total Despesas"
                            variant="outlined"
                            disabled={true}
                            value={vl_total_despesas}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            onChange={(e) => setVl_total_despesas(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            style={{ marginRight: '2%', width: '30%' }}
                            id="vl_renda_total_com_ps"
                            label="Valor da renda total com programa social"
                            variant="outlined"
                            disabled={true}
                            value={vl_renda_total_com_ps}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            onChange={(e) => setVl_renda_total_com_ps(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            style={{ width: '30%' }}
                            id="vl_renda_per_capita_com_ps"
                            label="Valor da renda per capita com programa social"
                            variant="outlined"
                            disabled={true}
                            value={vl_renda_per_capita_com_ps}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            onChange={(e) => setVl_renda_per_capita_com_ps(e.target.value)}
                        />

                    </Typography>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}