import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, CircularProgress, Backdrop, Box, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

}));

export default function CreasAcompanhamento({ familia_id, filter }) {

    const classes = useStyles();

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // CreasAcompanhamento
    const [id, setId] = useState("");
    const [id_creas, setId_creas] = useState("");
    const [dt_inicio, setDt_inicio] = useState("");
    const [dt_fim, setDt_fim] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    /* Cadastrar CreasAcompanhamento */
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (dt_inicio === "")
            swal("Data de início é obrigatório!", "", "error")

        else if (id_creas === "")
            swal("Identificação do CREAS é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                id_creas,
                dt_inicio,
                dt_fim,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/cadastrarCreasAcompanhamento`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e CreasAcompanhamento */
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);

        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, result => setLoading(false))

        LaConFetch(`/api/resultCreasAcompanhamento/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, result => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosCreasAcompanhamento */
    const setDadosCreasAcompanhamento = (dados) => {

        /* Dados */
        setId(dados.id);
        setId_creas(dados.id_creas);
        setDt_inicio(dados.dt_inicio);
        setDt_fim(dados.dt_fim);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setId_creas("");
        setDt_inicio("");
        setDt_fim("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosCreasAcompanhamento(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar CreasAcompanhamento */
    const editar = () => {

        if (id_creas === "")
            swal("Identificação do CREAS é obrigatório!", "", "error")

        else {

            const formDados = {
                id_creas,
                dt_inicio,
                dt_fim,
                ds_observacao
            };

            setSending(true);

            LaConFetch(`/api/editarCreasAcompanhamento/${id}`,
                result => {
                    load();
                    setSending(false);
                    swal("Editado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Remover CreasAcompanhamento */
    const remover = (dados) => {
        const id = dados.id;
        setSending(true);
        LaConFetch(`/api/removerCreasAcompanhamento/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>QUADRO 2 - EXCLUSIVO PARA UTILIZAÇÃO PELO CREAS
                - Caso a família esteja, ou já tenha estado, sob acompanhamento de um CREAS, registre o
                período em que isso ocorreu e o CREAS realizou o acompanhamento</Typography>
            <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                Adicionar CREAS Acompanhamento
            </Button>
        </Box>

        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Data Início', field: 'dt_inicioTab', headerStyle: { fontWeight: 'bold' } },
                { title: 'ID CREAS', field: 'id_creas', headerStyle: { fontWeight: 'bold' } }
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            components={{
                Pagination: PatchedPagination,
            }}
            options={{
                actionsColumnIndex: -1
            }}
            localization={_materialTableLocalization} icons={_materialTableIcons}
            title="CREAS Acompanhamento"
        />

        <Dialog
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="dialog-title">CREAS Acompanhamento</DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            disabled={editing}
                            id="pessoa_id"
                            value={pessoa_id}
                            label="Nome da pessoa*"
                            onChange={(e) => setPessoa_id(e.target.value)}
                            select
                        >
                            {dadosPessoa.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.nm_pessoa}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="dt_inicio"
                            label="Data de Início*"
                            type="date"
                            value={dt_inicio}
                            onChange={(e) => setDt_inicio(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="dt_fim"
                            label="Data de Fim"
                            type="date"
                            value={dt_fim}
                            onChange={(e) => setDt_fim(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            margin="normal"
                            fullWidth
                            id="id_creas"
                            label="Identificação do CREAS*"
                            variant="outlined"
                            value={id_creas}
                            onChange={(e) => setId_creas(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            margin="normal"
                            fullWidth
                            id="ds_observacao"
                            label="Observação"
                            variant="outlined"
                            value={ds_observacao}
                            onChange={(e) => setDs_observacao(e.target.value)}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>


            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                    Fechar
                </Button>
                <Button onClick={(editing === false) ? enviar : editar} variant="contained" color="primary">
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>

    </div>
}