import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Button, CircularProgress, Backdrop, Modal, Box, Dialog, DialogTitle, DialogContent, Grid, DialogActions } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import MaterialTable from 'material-table'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LaConFetch } from 'links/LaConFetch';
import { link } from '../../links/Links';
import { _materialTableLocalization } from 'constants/MaterialConstant';
import { _materialTableIcons } from 'constants/MaterialConstant';
import PatchedPagination from 'components/PatchedPagination';
import YesNoRadio from 'components/FormInputs/YesNoRadio';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%'
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

}));

export default function Deficiencia({ familia_id, filter }) {

    const classes = useStyles();

    // Pessoa
    const [pessoa_id, setPessoa_id] = useState("");
    const [nm_pessoa, setNm_pessoa] = useState("");

    // Deficiencia
    const [id, setId] = useState("");
    const [cd_deficiencia, setCd_deficiencia] = useState("");
    const [nm_deficiencia, setNm_deficiencia] = useState("");
    const [st_cuidados, setSt_cuidados] = useState("");
    const [nm_responsavel, setNm_responsavel] = useState("");
    const [ds_observacao, setDs_observacao] = useState("");
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);

    useEffect(() => {

        if (cd_deficiencia === '1') {
            setNm_deficiencia("Cegueira");
        } else if (cd_deficiencia === '2') {
            setNm_deficiencia("Baixa Visão");
        } else if (cd_deficiencia === '3') {
            setNm_deficiencia("Surdez severa/profunda");
        } else if (cd_deficiencia === '4') {
            setNm_deficiencia("Surdez leve/moderada");
        } else if (cd_deficiencia === '5') {
            setNm_deficiencia("Deficiência física");
        } else if (cd_deficiencia === '6') {
            setNm_deficiencia("Deficiência mental ou intelectual");
        } else if (cd_deficiencia === '7') {
            setNm_deficiencia("Síndrome de Down");
        } else if (cd_deficiencia === '8') {
            setNm_deficiencia("Transtorno/doença mental");
        } else if (cd_deficiencia === '9') {
            setNm_deficiencia("Microcefalia");
        } else {
            setNm_deficiencia("");
        }

    }, [cd_deficiencia])

    /* Cadastrar Deficiencia */
    const enviar = () => {

        if (pessoa_id === "")
            swal("Pessoa é obrigatório!", "", "error")

        else if (cd_deficiencia === "")
            swal("Tipo de deficiência é obrigatório!", "", "error")

        else {

            const formDados = {
                pessoa_id,
                cd_deficiencia,
                nm_deficiencia,
                st_cuidados,
                nm_responsavel,
                ds_observacao
            };

            setSending(true);
            LaConFetch(`/api/cadastrarDeficiencia`,
                result => {
                    load();
                    setSending(false);
                    swal("Enviado com sucesso!", "", "success")
                    setOpen(false)
                }, () => { }, formDados)

        }
    };

    /* Carregar Pessoa e Deficiencia*/
    const [rows, setRows] = useState([]);

    const load = () => {

        setLoading(true);

        LaConFetch(`/api/resultPessoa/${familia_id}`,
            result => {
                if (result.dados !== null) {
                    setDadosPessoa(result.dados)
                }
                setLoading(false)
            }, result => setLoading(false))

        LaConFetch(`/api/resultDeficiencia/${familia_id}${filter ? `?filter=${filter}` : ''}`,
            result => {
                if (result.dados !== null) {
                    setRows(result.dados)
                }
                setLoading(false)
            }, result => setLoading(false))

    };

    useEffect(() => {
        load();
    }, [filter])

    const [dadosPessoa, setDadosPessoa] = useState([{ pessoa_id: "", nm_pessoa: "" }]);

    /* SetDadosDeficiencia */
    const setDadosDeficiencia = (dados) => {

        /* Dados */
        setId(dados.id);
        setCd_deficiencia(dados.cd_deficiencia);
        setNm_deficiencia(dados.nm_deficiencia);
        setSt_cuidados(dados.st_cuidados);
        setNm_responsavel(dados.nm_responsavel);
        setDs_observacao(dados.ds_observacao);
        setNm_pessoa(dados.nm_pessoa);
        setPessoa_id(dados.pessoa_id);
    };

    /* Limpa os dados */
    const clear = () => {

        /* Dados */
        setId("");
        setCd_deficiencia("");
        setNm_deficiencia("");
        setSt_cuidados("");
        setNm_responsavel("");
        setDs_observacao("");
        setNm_pessoa("");
        setPessoa_id("");
    };

    const [open, setOpen] = React.useState(false);

    const [editing, setEditing] = React.useState(false);

    const handleOpen = () => {
        clear();
        setEditing(false)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalEdit = (dados) => {
        setDadosDeficiencia(dados);
        setEditing(true)
        setOpen(true);
    };

    /* Editar Deficiencia */
    const editar = () => {

        const formDados = {
            cd_deficiencia,
            nm_deficiencia,
            st_cuidados,
            nm_responsavel,
            ds_observacao
        };

        setSending(true);

        LaConFetch(`/api/editarDeficiencia/${id}`,
            result => {
                load();
                setSending(false);
                swal("Editado com sucesso!", "", "success")
                setOpen(false)
            }, () => { }, formDados)

    };

    /* Remover Deficiencia */
    const remover = (dados) => {

        const id = dados.id;

        setSending(true);

        LaConFetch(`/api/removerDeficiencia/${id}`,
            result => {
                setSending(false)
            }, result => setSending(false), {})

    };

    return <div className={classes.root}>
        <Backdrop style={{ zIndex: 9999 }} open={sending}>
            <Box color="white">
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>

        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} p={1}>
            <Typography className={classes.heading}>Deficiência</Typography>
            <Button onClick={handleOpen} style={{ float: 'right' }} variant="contained" color="primary">
                Adicionar Deficiência
            </Button>
        </Box>

        <MaterialTable
            isLoading={loading}
            columns={[
                { title: 'Nome', field: 'pessoa.nm_pessoa', headerStyle: { fontWeight: 'bold' } },
                { title: 'Cód. Deficiência', field: 'cd_deficiencia', headerStyle: { fontWeight: 'bold' } },
                { title: 'Nome Deficiência', field: 'nm_deficiencia', headerStyle: { fontWeight: 'bold' } },
            ]}
            data={rows}
            actions={[
                {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (e, data) => modalEdit(data)
                },
                {
                    icon: 'delete',
                    tooltip: 'Remover',
                    onClick: (e, data) => swal("Tem certeza que deseja remover o registro?", {
                        buttons: {
                            remover: "Remover",
                            cancel: "Sair"
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                case "remover":
                                    remover(data);
                                    swal("Removido com sucesso!", "", "success");
                                    load();
                                    break;
                            }
                        })
                }
            ]}
            components={{
                Pagination: PatchedPagination
            }}
            options={{
                actionsColumnIndex: -1
            }}
            localization={_materialTableLocalization}
            icons={_materialTableIcons}
            title="Deficiência"
        />

        <Dialog
            open={open}
            onClose={handleClose}
            closeAfterTransition
            fullWidth maxWidth="lg"
        >
            <DialogTitle>
                <InputLabel style={{ fontSize: 20 }}>Deficiência</InputLabel>
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="pessoa_id"
                            value={pessoa_id}
                            disabled={editing}
                            label="Nome da pessoa*"
                            onChange={(e) => setPessoa_id(e.target.value)}
                            select>
                            {dadosPessoa.map(item =>
                                <MenuItem value={item.id}>{item.nm_pessoa}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="cd_deficiencia"
                            value={cd_deficiencia}
                            label="Qual o tipo de deficiência?*"
                            onChange={(e) => setCd_deficiencia(e.target.value)}
                            select
                        >
                            <MenuItem value="1">1 - Cegueira</MenuItem>
                            <MenuItem value="2">2 - Baixa Visão</MenuItem>
                            <MenuItem value="3">3 - Surdez severa/profunda</MenuItem>
                            <MenuItem value="4">4 - Surdez leve/moderada</MenuItem>
                            <MenuItem value="5">5 - Deficiência física</MenuItem>
                            <MenuItem value="6">6 - Deficiência mental ou intelectual</MenuItem>
                            <MenuItem value="7">7 - Síndrome de Down</MenuItem>
                            <MenuItem value="8">8 - Transtorno/doença mental</MenuItem>
                            <MenuItem value="9">9 - Microcefalia</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <YesNoRadio
                            label="Necessita de cuidador?"
                            value={st_cuidados}
                            onChange={(value) => setSt_cuidados(value)}
                            name="st_cuidados"
                            formProps={{ fullWidth: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="nm_responsavel"
                            label="Nome do responsável em cuidar"
                            variant="outlined"
                            value={nm_responsavel}
                            onChange={(e) => setNm_responsavel(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="ds_observacao"
                            label="Observação"
                            variant="outlined"
                            value={ds_observacao}
                            onChange={(e) => setDs_observacao(e.target.value)}
                            multiline='true'
                            rows='4'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Button onClick={handleClose} style={{ backgroundColor: "red" }} variant="contained" color="primary">
                        Fechar
                    </Button>
                    <Button onClick={(editing === false) ? enviar : editar} variant="contained" color="primary">
                        Enviar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog >
    </div >
}