import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {Button, CircularProgress, Backdrop} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import { LaConFetch } from 'links/LaConFetch';
import {link} from '../../links/Links';
import ModaAnexo from 'components/Crud/ModaAnexo';

const useStyles = makeStyles((theme) => ({
  
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

}));
   
export default function CondHabitacionais({familia_id}) {

  const classes = useStyles();
  
  const [tp_residencia, setTp_residencia] = useState("");
  const [cd_material_parede, setCd_material_parede] = useState("");
  const [tp_material_parede, setTp_material_parede] = useState("");
  const [st_acesso_energia, setSt_acesso_energia] = useState("");
  const [st_agua_canalizada, setSt_agua_canalizada] = useState("");
  const [cd_escoamento_sanitario, setCd_escoamento_sanitario] = useState("");
  const [tp_escoamento_sanitario, setTp_escoamento_sanitario] = useState("");
  const [st_coleta_lixo, setSt_coleta_lixo] = useState("");
  const [qt_comodos, setQt_comodos] = useState("");
  const [qt_dormitorios, setQt_dormitorios] = useState("");
  const [nr_razao_pessoas_dorm, setNr_razao_pessoas_dorm] = useState("");
  const [st_acessibilidade, setSt_acessibilidade] = useState("");
  const [st_area_risco, setSt_area_risco] = useState("");
  const [st_dificil_acesso, setSt_dificil_acesso] = useState("");
  const [st_area_violencia, setSt_area_violencia] = useState("");
  const [ds_observacao, setDs_observacao] = useState("");

  /* Valor mostrado em outro material */
  const [ds_material_outro, setDs_material_outro] = useState("");

  /* Desabilitar Material */
  const [outroMaterialOff, setOutroMaterialOff] = useState(true);

  /* Valor mostrado em outro escoamento */
  const [ds_escoamento_outro, setDs_escoamento_outro] = useState("");

  /* Desabilitar Escoamento */
  const [outroEscoamentoOff, setOutroEscoamentoOff] = useState(true);

  /* Set Material */
  const setMaterial = (codigo) =>{

    setCd_material_parede(codigo);

    if (codigo === '1'){  
      setDs_material_outro(null);
      setOutroMaterialOff(true);
      setTp_material_parede('Alvenaria');
    }
    else if (codigo === '2'){
      setDs_material_outro(null);
      setOutroMaterialOff(true);
      setTp_material_parede('Madeira Aparelhada');
    }
    else if (codigo === '3'){
      setDs_material_outro(null);
      setOutroMaterialOff(true);
      setTp_material_parede('Madeira Aproveitada');
    }
    else if (codigo === '4'){
      setDs_material_outro(null);
      setOutroMaterialOff(true);
      setTp_material_parede('Taipa');
    }

    else if(codigo === '5'){
      setOutroMaterialOff(false);
    }
    
  }
  
  const setMaterialOutro = (texto) =>{
  
    setDs_material_outro(texto);
    setTp_material_parede(texto);
  }

  /* Set Escoamento */
  const setEscoamento = (codigo) =>{

    setCd_escoamento_sanitario(codigo);
    
    if (codigo === '1'){  
      setDs_escoamento_outro(null);
      setOutroEscoamentoOff(true);
      setTp_escoamento_sanitario('Rede geral de distribuição');
    }
    else if (codigo === '2'){
      setDs_escoamento_outro(null);
      setOutroEscoamentoOff(true);
      setTp_escoamento_sanitario('Poço ou nascente');
    }
    else if (codigo === '3'){
      setDs_escoamento_outro(null);
      setOutroEscoamentoOff(true);
      setTp_escoamento_sanitario('Cisterna');
    }
    else if (codigo === '4'){
      setDs_escoamento_outro(null);
      setOutroEscoamentoOff(true);
      setTp_escoamento_sanitario('Carro Pipa');
    }

    else if(codigo === '5'){
      setOutroEscoamentoOff(false);
    }
    
  }
  
  const setEscoamentoOutro = (texto) =>{
  
    setDs_escoamento_outro(texto);
    setTp_escoamento_sanitario(texto);
  }

  /* Carregar CondHabitacionais */
  const load = () => {

    setSending(true);

    // fetch(`${link}/api/resultCondHabitacionais/${familia_id}`,{
    //     headers:{
    //         Accept:'application/json'
    //     }
    //     })
    //     .then(res => res.json())
    //     .then(result => {if(result.dados !== null)(setDados(result.dados))})
    //     .then(result => setSending(false))


    setSending(true);
    LaConFetch(`/api/resultCondHabitacionais/${familia_id}`,
      result => {
        if(result.dados !== null){
          setDados(result.dados)
        }
        setSending(false)
    },result => setSending(false))
        
    };
  
  useEffect(() => {
    
    load();

  }, [])

  /* SetDados */
  const setDados = (dados) => {

    /* CondHabitacionais */
    setTp_residencia(dados.tp_residencia);
    setCd_material_parede(dados.cd_material_parede);
    setTp_material_parede(dados.tp_material_parede);
    setSt_acesso_energia(dados.st_acesso_energia);
    setSt_agua_canalizada(dados.st_agua_canalizada);
    setCd_escoamento_sanitario(dados.cd_escoamento_sanitario);
    setTp_escoamento_sanitario(dados.tp_escoamento_sanitario);
    setSt_coleta_lixo(dados.st_coleta_lixo);
    setQt_comodos(dados.qt_comodos);
    setQt_dormitorios(dados.qt_dormitorios);
    setNr_razao_pessoas_dorm(dados.nr_razao_pessoas_dorm);
    setSt_acessibilidade(dados.st_acessibilidade);
    setSt_area_risco(dados.st_area_risco);
    setSt_dificil_acesso(dados.st_dificil_acesso);
    setSt_area_violencia(dados.st_area_violencia);
    setDs_observacao(dados.ds_observacao);

    if (dados.cd_material_parede === '5'){
      setOutroMaterialOff(false);
    }
    setDs_material_outro(dados.tp_material_parede);

    if (dados.cd_escoamento_sanitario === '5'){
      setOutroEscoamentoOff(false);
    }
    setDs_escoamento_outro(dados.tp_escoamento_sanitario);

  };

  const [sending, setSending] = useState(false);

  /* Cadastrar CondHabitacionais*/
  const enviar = () => {

    if(tp_residencia === "")
      swal("Tipo de residência é obrigatório!","", "error")

    else{

      const formDados = {
        familia_id,
        tp_residencia, 
        cd_material_parede,
        tp_material_parede,
        st_acesso_energia, 
        st_agua_canalizada,
        cd_escoamento_sanitario,
        tp_escoamento_sanitario,
        st_coleta_lixo,
        qt_comodos,
        qt_dormitorios,
        nr_razao_pessoas_dorm,
        st_acessibilidade,
        st_dificil_acesso,
        st_area_violencia,
        ds_observacao};

      // setSending(true);

      // fetch(`${link}/api/cadastrarCondHabitacionais`,{
      //     method:'POST',
      //     body:JSON.stringify(formDados),
      //     headers:{
      //         "Content-Type":'application/json',
      //         Accept:'application/json'
      //     }
      // })
      // .then(res => res.json())
      // .then(result => {
      //     setSending(false)
      // })
      // .then(swal("Salvo com sucesso!","", "success"));

      setSending(true);
      LaConFetch(`/api/cadastrarCondHabitacionais`,
        result => {
          load();
          setSending(false);
          swal("Enviado com sucesso!","", "success")
        },()=>{},formDados)

    }
  };

  return (

    <div className={classes.root}>

      <Backdrop style={{zIndex:99}} open={sending}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <InputLabel style={{ marginTop: 20, fontSize: 20 }}>Condições Habitacionais</InputLabel>

      <TextField
        variant="outlined"
        style={{ marginTop: 15, marginRight: '2%', width: '22%' }} 
        id="tp_residencia" 
        value={tp_residencia}
        label="Tipo de Residência*"  
        onChange={(e) => setTp_residencia(e.target.value)}
        select>
        <MenuItem value="Própria">Própria</MenuItem>
        <MenuItem value="Alugada">Alugada</MenuItem>
        <MenuItem value="Cedida">Cedida</MenuItem>
        <MenuItem value="Ocupada">Ocupada</MenuItem>     
      </TextField>

      <TextField
        variant="outlined"
        style={{ marginTop: 15, marginRight: '2%', width: '24%' }} 
        id="tp_material_parede" 
        value={cd_material_parede}
        label="Tipo do Material da Parede"  
        onChange={(e) => setMaterial(e.target.value)}
        select>
        <MenuItem value="1">Alvenaria</MenuItem>
        <MenuItem value="2">Madeira Aparelhada</MenuItem>
        <MenuItem value="3">Madeira Aproveitada</MenuItem>
        <MenuItem value="4">Taipa</MenuItem> 
        <MenuItem value="5">Outro</MenuItem>        
      </TextField>

      {/* {tp_material_parede == '5'? */}
        <TextField
          disabled={outroMaterialOff}
          margin="normal"
          value={ds_material_outro}
          style={{ width: '50%' }}     
          id="tp_material_parede"
          label="Caso outro, qual?"
          variant="outlined"
          onChange={(e) => setMaterialOutro(e.target.value)}
        />
      {/* :null} */}

      <TextField
        variant="outlined"
        style={{ marginTop: 15, marginRight: '2%', width: '22%' }} 
        id="cd_escoamento_sanitario" 
        value={cd_escoamento_sanitario}
        label="Tipo de escoamento sanitário"  
        onChange={(e) => setEscoamento(e.target.value)}
        select>
        <MenuItem value="1">Rede geral de distribuição</MenuItem>
        <MenuItem value="2">Poço ou nascente</MenuItem>
        <MenuItem value="3">Cisterna</MenuItem>
        <MenuItem value="4">Carro Pipa</MenuItem> 
        <MenuItem value="5">Outro</MenuItem>        
      </TextField>

      {/* {tp_escoamento_sanitario == '5'? */}
        <TextField
          disabled={outroEscoamentoOff}
          margin="normal"
          style={{ marginRight: '2%', width: '35%' }}     
          id="ds_escoamento_outro"
          value={ds_escoamento_outro}
          label="Caso outro, qual?"
          variant="outlined"
          onChange={(e) => setEscoamentoOutro(e.target.value)}
        />
      {/* :null} */}

      <FormControl style={{ marginTop: 15, marginRight: '2%', width: '16%' }}  component="fieldset">
        <FormLabel component="legend">Possui Acesso à Energia?</FormLabel>
        <RadioGroup row value={st_acesso_energia} aria-label="st_acesso_energia" name="st_acesso_energia" onChange={(e) => setSt_acesso_energia(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15, width: '21%' }}  component="fieldset">
        <FormLabel component="legend">Possui Acesso à Água Canalizada?</FormLabel>
        <RadioGroup row value={st_agua_canalizada} aria-label="st_agua_canalizada" name="st_agua_canalizada" onChange={(e) => setSt_agua_canalizada(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>     

      <FormControl style={{ marginTop: 15, marginRight: '2%', width: '31%' }}  component="fieldset">
        <FormLabel component="legend">Possui coleta de lixo?</FormLabel>
        <RadioGroup row value={st_coleta_lixo} aria-label="st_coleta_lixo" name="st_coleta_lixo" onChange={(e) => setSt_coleta_lixo(e.target.value)}>
          <FormControlLabel value="Sim-Direta" control={<Radio color="primary"/>} label="SIM (direta)" />
          <FormControlLabel value="Sim-Indireta" control={<Radio color="primary"/>} label="SIM (indireta)" />
          <FormControlLabel value="Não" control={<Radio />} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15, width: '20%', marginRight: '2%' }}  component="fieldset">
        <FormLabel component="legend">Vive em lugar de difícil acesso?</FormLabel>
        <RadioGroup row value={st_dificil_acesso} aria-label="st_dificil_acesso" name="st_dificil_acesso" onChange={(e) => setSt_dificil_acesso(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <FormControl style={{ marginTop: 15,  marginRight: '2%', width: '16%' }}  component="fieldset">
        <FormLabel component="legend">Vive em área de risco?</FormLabel>
        <RadioGroup row value={st_area_risco} aria-label="st_area_risco" name="st_area_risco" onChange={(e) => setSt_area_risco(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>     

      <FormControl style={{ marginRight: '2%', marginTop: 15, width: '16%' }}  component="fieldset">
        <FormLabel component="legend">Vive em região violenta?</FormLabel>
        <RadioGroup row value={st_area_violencia} aria-label="st_area_violencia" name="st_area_violencia" onChange={(e) => setSt_area_violencia(e.target.value)}>
          <FormControlLabel value="Sim" control={<Radio color="primary"/>} label="SIM" />
          <FormControlLabel value="Não" control={<Radio color="primary"/>} label="NÂO" />   
        </RadioGroup>
      </FormControl>

      <TextField  
        margin="normal"
        style={{ marginRight: '2%', width: '18%' }}      
        id="qt_comodos"
        label="Quantidade de Cômodos"
        variant="outlined"
        value={qt_comodos} 
        onChange={(e) => setQt_comodos(e.target.value)}
      />

      <TextField  
        margin="normal"
        style={{ marginRight: '2%', width: '18%' }}      
        id="qt_dormitorios"
        label="Quantidade de dormitórios"
        variant="outlined"
        value={qt_dormitorios} 
        onChange={(e) => setQt_dormitorios(e.target.value)}
      />

      <TextField   
        margin="normal"
        style={{ width: '33%' }}     
        id="nr_razao_pessoas_dorm"
        label="Quantidade de pessoas dividido pelo nº de dormitórios"
        variant="outlined"
        value={nr_razao_pessoas_dorm} 
        onChange={(e) => setNr_razao_pessoas_dorm(e.target.value)}
      />

      <TextField
        variant="outlined"
        style={{ marginTop: 15, width: '55%' }} 
        id="st_acessibilidade" 
        value={st_acessibilidade}
        label="O domicílio possue acessibilidade para pessoas com dificuldade de locomoção?"  
        onChange={(e) => setSt_acessibilidade(e.target.value)}
        select>
        <MenuItem value="1">Sim, tanto nos espaços internos como na comunicação com a rua.</MenuItem>
        <MenuItem value="2">Sim, apenas nos espaços internos, mais possui "barreiras" na comunicação com a rua.</MenuItem>
        <MenuItem value="3">Não possui condições de acessibilidade</MenuItem>     
      </TextField>

      <ModaAnexo url={'cond-habitacional'} endLink={`?familia_id=${familia_id}`}/>

      <TextField   
        margin="normal"
        style={{ width: '100%' }}     
        id="ds_observacao"
        label="Observação"
        variant="outlined"
        value={ds_observacao} 
        onChange={(e) => setDs_observacao(e.target.value)}
        multiline='true'
        rows='4'
      />

      <br></br><br></br>
      
      <Button onClick={enviar} style={{ float: 'right' }}  variant="contained" color="primary">
        Enviar
      </Button>

      <br></br><br></br>

    </div>
)}